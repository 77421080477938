import { useState } from "react";

export const SidenavSelectionItem = ({title, children}) => {
    const [show, setShow] = useState(false)
    return (
        <div className="d-flex flex-column">
            <li className='d-flex sidenav-item ps-4 pe-4' onClick={() => {
            setShow(!show);
        }}>
            <div className='pointer w-100' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>{title}</div>
                {!show && <div className='fa fa-angle-down'></div>}
                {show && <div className='fa fa-angle-up'></div>}
            </div>
        </li>
        { show && children}
        </div>
    )
}