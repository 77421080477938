export const Loading = (props: {message?: string}) => {
    return <div 
        style={{
            position: 'fixed',
            top: '50%',
            
            width: '100%',
            height: '100%'}}>
            <div className="d-flex justify-content-center align-items-center flex-column">
                <div className="loader"></div>
                { props.message && <div className="mt-2 bold font14" style={{color: 'gray'}}>{props.message}</div>}
            </div>
        </div>
}