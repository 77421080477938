import { useSelector } from "react-redux";
import { OrderModel } from "../../../../server/models/order";
import { useAction } from "../../../../utils/hooks/action";
import { RootState } from "../../../../store/rootState";
import { OrdersActions } from "../module/actions";
import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const Order = (props: {order: OrderModel}) => {
    // Navigation
    const location = useLocation();
    const navigate = useNavigate();
    // States
    const { paymentLink, redirect, loadingPaymentLink } = useSelector((state: RootState) => state.orders);
    // Actions
    const paymentLinkAction = useAction(OrdersActions.paymentLink.request);
    const redirectAction = useAction(OrdersActions.redirect);
    const cancelOrderAction = useAction(OrdersActions.cancel.request);
    const { t } = useTranslation();

    useEffect(() => {
        if(redirect && paymentLink) {
            redirectAction(false)
            window.location.replace(paymentLink);
        }
    }, [redirect])

    const isOrderCancable = () => {
        return ((props.order.status == "Waiting For Payment" && props.order.payment_method == "bank") || (props.order.status == "Pending" && props.order.payment_method == "cod"));
    }

    const navigateToOrder = () => {
        navigate(`/my_account/orders/${props.order.id}`);
    }
    
    return (
        <tr className="order-item">
            <td onClick={() => {
                navigateToOrder();
            }} className="order-item" data-title="Order"><a>#{props.order.id}</a></td>
            <td className="order-item" data-title="Date"><time>{props.order.date}</time></td>
            <td className="order-item" data-title="Status">{t(props.order.status)}</td>
            <td className="order-item" data-title="Type">{props.order.payment_method}</td>
            <td className="order-item" data-title="Total"><span><span>{t('aed')}</span>&nbsp;{props.order.total}</span> for {props.order.total_quantity} {t('items')}</td>
            <td className="order-item d-flex" data-title="Actions">
                { (props.order.status == 'Waiting For Payment') && <a onClick={() => {
                    if(!loadingPaymentLink) {
                        paymentLinkAction(props.order.id);
                    }
                }} className="order-view-button">{t('pay')}</a>}
                <a onClick={() => {
                    navigateToOrder();
                }} className="order-view-button ms-1 me-1">{t('view')}</a>
                { isOrderCancable() && <a onClick={() => {
                    cancelOrderAction({ order_id: props.order.id });
                }} className="order-view-button">{t('cancel')}</a>}
            </td>
        </tr>
    );
}