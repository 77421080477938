import { useSelector } from 'react-redux';
import FlashSalesBackgroundImage from '../../../assets/flashsales_background.jpg';
import { RootState } from '../../../store/rootState';
import { FlashSale } from './flashsale';
import { useLanguage } from '../../../utils/hooks/language';
import { useTranslation } from 'react-i18next';

export const FlashSales = () => {
    const { loadingSlider, loadingFlashSales, slider, flashSales } = useSelector((state: RootState) => state.home);
    const [en, ar] = useLanguage();
    const { t, i18n } = useTranslation();

    if((flashSales ?? []).length == 0) {
        return <></>
    }
    
    return (
      <div
        className='flashsales pt-4 pb-3 mb-2'>
        <div className='container'>
          <h2 style={{ fontWeight: 'bold' }}>{t('flash_sale')}</h2>
        </div>

        <div className='container pt-4'>
          <div className=' row'>
          <div style={{flex: 1}}>
          <div className='flashsells'>
            <ul>
            {[...(flashSales ?? []).map((product, index) =>
              <FlashSale product={product}/>
            )]}
            </ul>
          </div>
          </div>
          </div>
        </div>
      </div>
    )
  }