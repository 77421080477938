import { createReducer } from 'deox';
import { produce } from 'immer';
import { RepairTypes } from './types';
import { RepairActions } from './actions';

const initialState: RepairTypes = {
  repair_details: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    device_manufacture: 'Apple',
    service: 'HOME & ONSITE REPAIR SERVICE',
    address: '',
    emirate: '',
    note: '',
    model: '',
    image:'',
  },
  success: false,
  loading: false,
  errors: []
};

export const repairReducer = createReducer(initialState, handleAction => [
  handleAction(RepairActions.repair.set, (state, { payload }) =>
    produce(state, draft => {
      draft.repair_details = payload;
    }),
  ),
  handleAction(RepairActions.repair.request, (state) =>
    produce(state, draft => {
      // Errors
      var errors : string[] = []

    
      // Validating billing address
      const repair_details = draft.repair_details;
      if(repair_details.first_name.length == 0) {
        errors.push('First name is a required field.');
      }
      if(repair_details.last_name.length == 0) {
        errors.push('Last name is a required field.');
      }
      if(repair_details.email.length == 0) {
        errors.push('Emaio is a required field.');
      }
      if(repair_details.phone.length == 0) {
        errors.push('Phone number is a required field.');
      }
      if(repair_details.device_manufacture.length == 0) {
        errors.push('Device Manufactor is a required.');
      }
      if(repair_details.service.length == 0) {
        errors.push('Service type is a required.');
      }
      if(repair_details.address.length == 0) {
        errors.push('Address is a required.');
      }
      if(repair_details.emirate.length == 0) {
        errors.push('Emirate is a required.');
      }
      if(repair_details.model.length == 0) {
        errors.push('Model is a required.');
      }
      if(repair_details.image.length == 0) {
        errors.push('Image is a required, Please upload device image.');
      }
      draft.errors = errors;
      draft.success = false;

      if(errors.length == 0) {
        draft.loading = true;
      }      
    }),
  ),
  handleAction(RepairActions.repair.success, (state) =>
    produce(state, draft => {
      draft.repair_details = initialState.repair_details;
      draft.loading = false;
      draft.success = true;
    }),
  ),
  handleAction(RepairActions.repair.fail, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.success = false;
      if(payload) {
        draft.errors = [payload]
      }
    }),
  ),
  handleAction(RepairActions.loading.set, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = payload;
    }),
  ),
]);
