import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  HeaderActions,
} from './actions';
import { HeaderTypes } from './types';
import { log } from 'console';

const initialState: HeaderTypes = {
  loadingProducts: false,
  products: [],
  sidenav: false,
  search: '',
  searchType: 'name'
};

export const headerReducer = createReducer(initialState, handleAction => [
  handleAction(HeaderActions.products.request, (state ) =>
    produce(state, draft => {
      draft.loadingProducts = true;
    }),
  ),
  handleAction(HeaderActions.products.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingProducts = false;
      draft.products = (payload == null) ? [] : payload
    }),
  ),
  handleAction(HeaderActions.products.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingProducts = false;
    }),
  ),
  handleAction(HeaderActions.products.clear, (state ) =>
    produce(state, draft => {
      draft.products = [];
    }),
  ),

  // Sidenav
  handleAction(HeaderActions.sidenav.open, (state) =>
    produce(state, draft => {
      draft.sidenav = true;
    }),
  ),
  handleAction(HeaderActions.sidenav.close, (state) =>
    produce(state, draft => {
      draft.sidenav = false;
    }),
  ),

  // Search
  handleAction(HeaderActions.search, (state, { payload }) =>
    produce(state, draft => {
      draft.search = payload;
    }),
  ),

  handleAction(HeaderActions.setSearchType, (state, { payload }) =>
    produce(state, draft => {
      draft.searchType = payload;
    }),
  ),
]);
