import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAction } from "../../../utils/hooks/action";
import { CartActions } from "../../cart/module/actions";
import { CheckoutActions } from "../module/actions";

export const CheckoutCoupon = () => {
  const { cart } = useSelector((state: RootState) => state.app);
  const { coupon: appliedCoupon, loadingCalculate } = useSelector(
    (state: RootState) => state.cart
  );
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [coupon, setCoupon] = useState("");

  const calculateAction = useAction(CheckoutActions.calculate.request);
  const setAppliedCouponAction = useAction(CartActions.coupon.apply);
  const removeAppliedCouponAction = useAction(CartActions.coupon.clear);

  useEffect(() => {
    if (appliedCoupon) {
      setCoupon(appliedCoupon);
      setShow(true);
    }
  }, []);

  return (
    <div>
      {!show && (
        <div className="woocommerce-form-coupon-toggle">
          <div className="woocommerce-info">
            {t("have_a_coupon")}{" "}
            <a
              onClick={() => {
                setShow(true);
              }}
              className="showcoupon pointer"
            >
              {t("click_here_to_enter_coupon_code")}
            </a>
          </div>
        </div>
      )}
      <div className="woocommerce-notices-wrapper" />
      {show && (
        <form className="checkout_coupon woocommerce-form-coupon">
          <p style={{ textAlign: "start" }}>
            {t("If you have a coupon code, please apply it below")}
          </p>
          <div
            className="d-flex"
            style={{ direction: "ltr"}}
          >
            <p
              style={{ textAlign: "start" }}
              className="form-row form-row-first"
            >
              <input
                type="text"
                name="coupon_code"
                className="input-text"
                id="coupon_code"
                value={coupon}
                placeholder={`${t("coupon_code")}`}
                onChange={(e) => {
                  setCoupon(e.target.value);
                }}
              />
            </p>
            <p
              style={{ textAlign: "start" }}
              className="form-row form-row-last"
            >
              <button
                type="submit"
                className="button"
                name="apply_coupon"
                value="Apply coupon"
                disabled={loadingCalculate}
                onClick={() => {
                  if (loadingCalculate) {
                    return;
                  }
                  console.log(`applied coupon ${appliedCoupon}`);
                  if (appliedCoupon.length > 0) {
                    removeAppliedCouponAction();
                    calculateAction({
                      products: cart.map((item) => {
                        return { quantity: item.quantity, id: item.product.id };
                      }),
                    });
                  } else {
                    console.log(`set coupon ${coupon}`);
                    setAppliedCouponAction(coupon);
                    calculateAction({
                      coupon: coupon,
                      products: cart.map((item) => {
                        return { quantity: item.quantity, id: item.product.id };
                      }),
                    });
                  }
                }}
              >
                {loadingCalculate ? (
                  <div
                    style={{ width: 20, height: 20 }}
                    className="loader"
                  ></div>
                ) : (
                  <div>
                    {t(
                      appliedCoupon.length == 0
                        ? "apply_coupon"
                        : "remove_coupon"
                    )}
                  </div>
                )}
              </button>
            </p>
          </div>

          <div className="clear"></div>
        </form>
      )}
    </div>
  );
};
