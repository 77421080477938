import { Footer } from '../../components/footer'
import { CartListComponent } from './components/cart'
import { Header } from '../../components/header/headers'
import { useEffect } from 'react'
import { useAction } from '../../utils/hooks/action'
import { CartActions } from './module/actions'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { BasePage } from '../base/base'
import TabbyPromoComponent from '../../components/tabby_promo'



export const CartPage = () => {
  const requestCartAction = useAction(CartActions.cart.request);
  const clearLocalUpdatedCartAction = useAction(CartActions.localUpdatedCart.clear);
  const { loadingCalculate: loadingCalculate, loadingCart } = useSelector((state: RootState) => state.cart);

  useEffect(() => {
    requestCartAction();
    clearLocalUpdatedCartAction();
    
  }, [])

  if (loadingCart) {
    return <></>
  }

  return (
    <BasePage>
      <div>
        <div id="page" className="hfeed site">
          <Header />
          <div id="content" className="site-content" >
            {/* <TabbyPromoComponent/> */}
            <div className="container">
              <CartListComponent />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </BasePage>
  )
}