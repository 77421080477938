import React, { CSSProperties, useEffect, useState } from 'react'
import { Header } from '../../components/header/headers';
import { Footer } from '../../components/footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootState';
import { useAction } from '../../utils/hooks/action';
import { ProductsList } from './components/list';
import { ProductsPayload } from '../../server/payload/products';
import { ProductsActions } from './module/actions';
import { useSearchParams } from "react-router-dom";
import { BasePage } from '../base/base';
import { HomeActions } from '../home/module/actions';
import { ProductsBrands } from './components/products_brands';
import TabbyBlackIconSvgImage from '../../assets/tabby-black-icon.svg'
import { ProductsNavigation } from './components/products_navigation';

export const ProductsPage = () => {
  // Search params
  let [searchParams, setSearchParams] = useSearchParams();
  // States
  const { loadingProducts, products, attributes, total_count, sort } = useSelector((state: RootState) => state.products);
  // Actions
  const productsAction = useAction(ProductsActions.products.request);
  const requestBrandsAction = useAction(HomeActions.brands.request);


  useEffect(() => {
    var id: any = searchParams.get("id");
    var name: any = searchParams.get("name");
    var min_price: any = searchParams.get("min_price");
    var max_price: any = searchParams.get("max_price");
    var attributes: any = searchParams.get("attrs");
    var page: any = searchParams.get("page") ?? 0;
    var sort: any = searchParams.get("sort") ?? 'latest';

    var payload: ProductsPayload = {}
    if (id) {
      payload = { ...payload, id: id };
    }
    if (name) {
      payload = { ...payload, name: name };
    }
    if (min_price) {
      payload = { ...payload, min_price: min_price };
    }
    if (max_price) {
      payload = { ...payload, max_price: max_price };
    }
    if (attributes) {
      payload = { ...payload, attrs: attributes.split(',') };
    }
    if (page) {
      payload = { ...payload, page: page };
    }
    if (sort) {
      payload = { ...payload, sort: sort };
    }

    // Fetch products
    productsAction(payload)
  }, [searchParams])

  // Fetch brands
  useEffect(() => {
    requestBrandsAction();
  }, [])


  if (loadingProducts) {
    return <></>
  }

  const AdsBar = () => {
    return (
      <div className="hwrap">
        <div className="hmove">
          {
            Array.from({ length: 5 }, (_, i) =>
              <div key={i} className="hitem">Split in 4 with<div className='ms-2 me-2 d-flex'><img width={94} height={30} src={TabbyBlackIconSvgImage} />.</div>Buy now, pay later with your next HiPhone Telecom Purchase</div>
            )
          }
        </div>
      </div>
    )
  }

  return (
    <BasePage>
      <div className='disable-selection'>
        <Header />
        <div className='mt-2' />
        <ProductsNavigation/>
        <ProductsBrands/>
        {/* <AdsBar/> */}
        <ProductsList />
        <Footer />
      </div>
    </BasePage>
  )
}