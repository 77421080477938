import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';

export const CartTotal = () => {
    const { calculate } = useSelector((state: RootState) => state.cart);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="cart-collaterals" style={{ textAlign: 'start' }}>
            <div className="cart_totals ">
                <h2>{t('cart_total')}</h2>
                <table
                    cellSpacing={0}
                    className="shop_table shop_table_responsive"
                >
                    <tbody>
                        <tr className="cart-subtotal">
                            <th>{t('subtotal')}</th>
                            <td data-title="Subtotal">
                                <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                            {t('aed')}
                                        </span>
                                        &nbsp;{calculate.subtotal}
                                    </bdi>
                                </span>
                            </td>
                        </tr>
                        {calculate.discount && <tr className="cart-subtotal">
                            <th>{t('discount')}</th>
                            <td data-title="Discount">
                                <bdi className="" style={{ color: 'green' }}>
                                    <span className="woocommerce-Price-currencySymbol">
                                        {t('aed')}
                                    </span>
                                    -{calculate.discount}
                                </bdi>
                            </td>
                        </tr>}
                        
                        <tr className="cart-subtotal">
                            <th>{t('flat_rate')}</th>
                            <td data-title="Subtotal">
                                <span className="woocommerce-Price-amount amount">
                                    {calculate.delivery_fee != '0' && <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                            {t('aed')}
                                        </span>
                                        &nbsp;{calculate.delivery_fee}
                                    </bdi>}

                                    {calculate.delivery_fee == '0' && <div className="bold" style={{ color: 'green' }}>FREE</div>}
                                </span>
                            </td>
                        </tr>
                        <tr className="order-total">
                            <th>{t('total')}</th>
                            <td data-title="Total">
                                <strong>
                                    <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                                {t('aed')}
                                            </span>
                                            &nbsp;{calculate.total}
                                        </bdi>
                                    </span>
                                </strong>{" "}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="wc-proceed-to-checkout">
                    <div
                        id="tabbyPromo"
                        style={{ marginBottom: 20 }}
                        data-tabby-price={25.0}
                        data-tabby-currency="AED"
                    />
                    <a
                    onClick={() => {
                        navigate('/checkout')
                      }}
                        className="checkout-button button alt wc-forward"
                    >
                        {t('proceed_to_checkout')}
                    </a>
                </div>
                {+calculate.subtotal < 100 && <div>
                    <span className="woocommerce-Price-amount amount">
                        <bdi>
                            <span className="woocommerce-Price-currencySymbol">
                                {t('aed')}
                            </span>
                            &nbsp;{100 - +calculate.subtotal}
                        </bdi>
                    </span>{" "}
                    {t('left_for_free_shipping')}
                </div>}
                {+calculate.subtotal >= 100 && <div className="bold">
                    {t('order_delivery_free')}
                </div>}
                { calculate.message && <div className='bold'>{calculate.message}</div>}
            </div>
        </div>
    )
}