import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { BrandModel } from '../../../server/models/brand';


export class HomeActions {
  static slider = {
    request: createAction('home/slider_request'),
    success: createAction('auth/slider_success', resolve => (payload: SliderModel[]) =>
      resolve(payload),
    ),
    fail: createAction('auth/slider_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static flashSales = {
    request: createAction('home/flash_sales_request'),
    success: createAction('auth/flash_sales_success', resolve => (payload: ProductModel[]) =>
      resolve(payload),
    ),
    fail: createAction('auth/flash_sales_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static bestSellers = {
    request: createAction('home/best_sellers_request'),
    success: createAction('auth/best_sellers_success', resolve => (payload: ProductModel[]) =>
      resolve(payload),
    ),
    fail: createAction('auth/best_sellers_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static youMayLike = {
    request: createAction('home/you_may_like_request'),
    success: createAction('auth/you_may_like_success', resolve => (payload: ProductModel[]) =>
      resolve(payload),
    ),
    fail: createAction('auth/you_may_like_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static brands = {
    request: createAction('home/brands_request'),
    success: createAction('auth/brands_success', resolve => (payload: BrandModel[]) =>
      resolve(payload),
    ),
    fail: createAction('auth/brands_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static primaryProduct = {
    request: createAction('home/primary_product_request', resolve => (payload: {id: string}) =>
    resolve(payload),
  ),
    success: createAction('auth/primary_product_success', resolve => (payload: ProductModel[]) =>
      resolve(payload),
    ),
    fail: createAction('auth/primary_product_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
