import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { OrdersActions } from './actions';
import { OrdersApis } from '../../../../server/api/Orders';
import { AppActions } from '../../../../modules/app/actions';
import { CheckoutApis } from '../../../../server/api/Checkout';


function* orders(): SagaIterator {
  try {
    console.log('orders sSaga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OrdersApis.orders) as any;
    console.log('orders response: ');
    console.log(data);
    yield* put(OrdersActions.orders.success(data.data)) as any;
    
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(OrdersActions.orders.fail(errorMessage)) as any;
  }
}

function* paymentLink({ payload }: ActionType<typeof OrdersActions.paymentLink.request>): SagaIterator {
  try {
    console.log('paymentLink payload: ${payload}');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CheckoutApis.paymentLink, payload) as any;
    console.log('paymentLink response: ');
    console.log(data);
    yield* put(OrdersActions.paymentLink.success(data.data)) as any;
  } catch (error) {
    console.log('paymentLink error: ');
    // console.log((error as any).response.data);
    // const errorMessage = (error as any).response.data.data;
    yield* put(OrdersActions.paymentLink.fail('Couldn\'t create payment link')) as any;
  }
}

function* cancel({ payload }: ActionType<typeof OrdersActions.cancel.request>): SagaIterator {
  try {
    console.log('cancel Saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OrdersApis.cancel, payload) as any;
    console.log('cancel response:');
    console.log(data);
    yield* put(OrdersActions.cancel.success()) as any;

    const orderResponse = yield* call(OrdersApis.orders, payload) as any;
    yield* put(OrdersActions.orders.success(orderResponse.data.data)) as any;
    
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(OrdersActions.cancel.fail(errorMessage)) as any;
  }
}


export function* watchOrders(): SagaIterator {
  yield* takeLatest(OrdersActions.orders.request, orders) as any;
  yield* takeLatest(OrdersActions.paymentLink.request, paymentLink) as any;
  yield* takeLatest(OrdersActions.cancel.request, cancel) as any;
}