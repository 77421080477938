import { useState } from "react";

export const SidenavSubItem = (props: {title: string, onClick: Function}) => {
    return (<li className='d-flex sidenav-item-nested ps-4 pe-4' onClick={() => {
        // closeNav();
        props.onClick();
    }}>
        <div className='pointer' style={{ display: 'flex', alignItems: 'center' }}>
            {props.title}
        </div>
    </li>)
}