import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { HomeActions } from './actions';
import { AppActions } from '../../../modules/app/actions';
import { HomeApis } from '../../../server/api/Home';
import { RootState } from '../../../store/rootState';


function* slider(): SagaIterator {
  try {
    console.log('sliderSaga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(HomeApis.slider) as any;
    console.log('slider response: ');
    console.log(data);
    yield* put(HomeActions.slider.success(data.data)) as any;
  } catch (error) {
    yield* put(HomeActions.slider.fail('')) as any;
  }
}

function* flashSales(): SagaIterator {
  try {
    console.log('flash sales Saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(HomeApis.flashSales) as any;
    console.log('flash sales response: ');
    console.log(data);
    yield* put(HomeActions.flashSales.success(data.data)) as any;
  } catch (error) {
    yield* put(HomeActions.flashSales.fail('')) as any;
  }
}

function* bestSellers(): SagaIterator {
  try {
    console.log('best sellers Saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(HomeApis.bestSellers) as any;
    console.log('best sellers response: ');
    console.log(data);
    yield* put(HomeActions.bestSellers.success(data.data)) as any;
  } catch (error) {
    yield* put(HomeActions.bestSellers.fail('')) as any;
  }
}

function* youMayLike(): SagaIterator {
  try {
    console.log('you may like Saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(HomeApis.youMayLike) as any;
    console.log('you may like response: ');
    console.log(data);
    yield* put(HomeActions.youMayLike.success(data.data)) as any;
  } catch (error) {
    yield* put(HomeActions.youMayLike.fail('')) as any;
  }
}

function* brands(): SagaIterator {
  try {
    const { brands } = yield* select((state: RootState) => state.home) as any;
    if(brands.length > 0) {
      yield* put(HomeActions.brands.success(brands)) as any;
      return;
    }
    
    console.log('brands Saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(HomeApis.brands) as any;
    console.log('brands response: ');
    console.log(data);
    yield* put(HomeActions.brands.success(data.data)) as any;
  } catch (error) {
    yield* put(HomeActions.brands.fail('')) as any;
  }
}

function* primaryProduct({ payload }: ActionType<typeof HomeActions.primaryProduct.request>): SagaIterator {
  try {
    console.log('primary product Saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(HomeApis.primaryProduct, payload) as any;
    console.log('primary product response: ');
    console.log(data);
    yield* put(HomeActions.primaryProduct.success(data)) as any;
  } catch (error) {
    yield* put(HomeActions.primaryProduct.fail('')) as any;
  }
}

export function* watchHome(): SagaIterator {
  yield* takeLatest(HomeActions.slider.request, slider) as any;
  yield* takeLatest(HomeActions.flashSales.request, flashSales) as any;
  yield* takeLatest(HomeActions.bestSellers.request, bestSellers) as any;
  yield* takeLatest(HomeActions.youMayLike.request, youMayLike) as any;
  yield* takeLatest(HomeActions.brands.request, brands) as any;
  yield* takeLatest(HomeActions.primaryProduct.request, primaryProduct) as any;
}