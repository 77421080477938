import { Topbar } from '../../components/topbar'
import { SearchMenu } from '../../components/header/search_menu'
import { Footer } from '../../components/footer'

import { AboutComponent } from '../../components/about'
import { useTranslation } from 'react-i18next'
import { BasePage } from '../base/base'
import { Header } from '../../components/header/headers'

export const AboutPage = () => {
  const { t } = useTranslation();
  
  return <>
  <BasePage>
      <div>
        <Header />
        <div className='mt-3' />        
        <AboutComponent/>

        <Footer />
      </div>
    </BasePage>
    </>

}