import { Topbar } from '../../components/topbar'
import { SearchMenu } from '../../components/header/search_menu'
import { Footer } from '../../components/footer'

import { AboutComponent } from '../../components/about'
import { useTranslation } from 'react-i18next'
import { BasePage } from '../base/base'
import { Header } from '../../components/header/headers'

export const ReturnPolicyPage = () => {
  const { t } = useTranslation();

  return <>
    <BasePage>
      <div>
        <Header />
        <div className='mt-3' />
        <div id="content" className="site-content" tabIndex={-1}>
          <div className="container">
            <nav className="woocommerce-breadcrumb">
              <a href="https://hiphonetelecom.com">{t("return policy")}</a>
              <span className="delimiter">
                <i className="fa fa-angle-right" />
              </span>
              {t("return policy")}
            </nav>
            <div className="site-content-inner">
              <div id="primary" className="w-100">
                <main id="main" className="site-main">
                  <article
                    id="post-65713"
                    className="post-65713 page type-page status-publish hentry"
                  >
                    <header className="entry-header">
                      <h1 className="entry-title">{t("return policy")}</h1>
                    </header>
                    <p className="container">
                      1. In the event that the product is returned, Product should not be opened and package should be in same condition.
                      <br />
                      2. Mobile phones have no return and exchange option
                    </p>
                  </article>
                </main>
                {/* #main */}
              </div>
              {/* #primary */}
            </div>
          </div>
          {/* .col-full */}
        </div>

        <Footer />
      </div>
    </BasePage>
  </>

}