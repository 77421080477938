import { useTranslation } from "react-i18next"
import { Footer } from "../../../components/footer"
import { Header } from "../../../components/header/headers"
import { BasePage } from "../../base/base"
import { MyAccountMenu } from "../components/menu"

export const AccountDetailsPage = () => {
    const { t } = useTranslation();
    return (
        <BasePage>
            <div className="d-flex flex-column">
                <Header />
                <div className="font40 p-4 pb-5 d-flex justify-content-center">{t('account_details')}</div>
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-3 col-12">
                            <MyAccountMenu />
                        </div>
                        <div className="col-lg-9 col-12" style={{ backgroundColor: 'red' }}>details</div>
                    </div>
                </div>
                <Footer />
            </div>
        </BasePage>
    )
}