import { useLocation, useSearchParams } from "react-router-dom";
import { AttributeModel } from "../../../server/models/attribute"
import { useTranslation } from "react-i18next";

export const Attribute = (props: { name, attributes: AttributeModel[] }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const isAttributeSelected = (attribute: AttributeModel) => {
        var attrs = searchParams.get('attrs')
        var result = false;
        if(attrs) {
            if(attrs.includes(',')) {
                attrs.split(',').forEach((key, index) => {
                    //console.log(`log: ${key} + ${attribute.value_name_en}`)
                    if(!result) {
                        result = key.toLowerCase() === attribute.value_name_en.toLowerCase();
                    }
                })
            } else {
                result = attrs.toLowerCase() === attribute.value_name_en.toLowerCase();
            }
        }
        return result;
    }

    const removeAttribute = (attribute: AttributeModel) => {
        var attrs = searchParams.get('attrs')
        console.log('remove called')
        if(attrs) {
            if(attrs.includes(',')) {
                var result = attrs.split(',')
                result.forEach((key, index, object) => {
                    if(key.toLowerCase() === attribute.value_name_en.toLowerCase()) {
                        result.splice(index, 1);
                    }
                })
                if(result.length == 0) {
                    searchParams.delete("attrs");
                } else {
                    searchParams.set("attrs", result.toString());
                    console.log(`attrs after remove = ${result?.toString()}`)
                    setSearchParams(searchParams, { replace: true });   
                }
            } 
            else {
                if(attrs.toLowerCase() === attribute.value_name_en.toLowerCase()) {
                    searchParams.delete("attrs");
                    setSearchParams(searchParams, { replace: true });   
                }
            }
        }
    }

    const navigateToProducts = (id: string | undefined, title: string | undefined, attribute: AttributeModel | undefined) => {
        searchParams.set("page", '0');

        if(title) {
            searchParams.set("title", title);   
        } 
        else {
            var _title = searchParams.get('title')
            if(_title) {
                searchParams.set("title", _title);
            }
        }

        if(id) {
            searchParams.delete('name')
            searchParams.delete('attrs')
            searchParams.set("id", id);   
        } 
        else {
            var _id = searchParams.get('id')
            if(_id) {
                searchParams.set("id", _id);
            }
        }

        if(attribute) {
            var attributes = searchParams.get('attrs')
            if(attributes) {
                searchParams.set("attrs", attribute.value_name_en);
            } else {
                searchParams.set("attrs", attribute.value_name_en);
            }
        }

        setSearchParams(searchParams, { replace: true });
    }

    const SubAttribute = (props: { attribute: AttributeModel }) => {
        //console.log(`attrrrs ${isAttributeSelected(props.attribute)}`)
        return (
            <div onClick={() => {
                if(isAttributeSelected(props.attribute)) {
                    removeAttribute(props.attribute);
                } else {
                    navigateToProducts(undefined, undefined, props.attribute);
                }
            }} className="d-flex w-100 align-items-center mb-1 pointer">
                <div className={`d-flex justify-content-center align-items-center checkbox-${isAttributeSelected(props.attribute) ? 'checked' : 'unchecked'}`}>
                    {isAttributeSelected(props.attribute) && <div>✓</div>}
                </div>
                <div className={'font14 bold d-flex'}>
                    <div className="ms-2 me-1"
                    >
                        {props.attribute.value_name_en}
                    </div>
                    <div className="count">({props.attribute.product_count})</div>
                </div>
            </div>
        )
    }

    if(props.name.toLowerCase() != 'brands') {
        return <></>
    }
    

    return (
        <div
         className="m-3">
            <h3 className="font14 bold">{(t(props.name.toLowerCase()) as String).toUpperCase()}</h3>
            <div className="w-100">
                {[...props.attributes.map((attribute, index) =>
                    <SubAttribute attribute={attribute} />
                )]
                }

            </div>
        </div>
    )
}