import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';


export class ProductActions {
  static product = {
    request: createAction('product/product_request', resolve => (payload: { id: number}) =>
      resolve(payload),
    ),
    success: createAction('product/product_success', resolve => (payload: { product: ProductModel }) =>
      resolve(payload),
    ),
    fail: createAction('product/product_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
