import HiPhoneLogoImage from "../assets/hiphone-logo.svg";
import FooterPaymentsImage from "../assets/footer-payments.png";
import GotQuestionImage from "../assets/got-question.svg";
import FacebookImage from "../assets/footer-facebook.png";
import TwitterImage from "../assets/footer-twitter.png";
import WhatsappImage from "../assets/footer-whatsapp.png";
import WhatsappImageGreen from "../assets/footer-whatsapp-green.svg";
import InstagramImage from "../assets/footer-instagram.png";
import YoutubeImage from "../assets/footer-youtube.png";
import PlayStoreImage from "../assets/playstore.png";
import AppStoreImage from "../assets/appstore.png";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootState";

export const Footer = () => {
  const { login } = useSelector((state: RootState) => state.app);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const Copyright = () => {
    return (
      <div
        className="w-100 disable-selection"
        style={{ backgroundColor: "#343434", color: "white", height: 75 }}
      >
        <div className="container h-100">
          <div className="d-flex justify-content-between align-items-center h-100">
            <div>
              <a
                href="https://www.hiphonetelecom.com"
                style={{ color: "white", fontWeight: "bold" }}
              >
                © HiPhone Telecom
              </a>{" "}
              - All Rights Reserved
            </div>
            <div>
              <img src={FooterPaymentsImage} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <footer>
      <div className="disable-selection" style={{ backgroundColor: "#222222" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-lg-6 mt-4 pt-2 pb-2 mb-4"
              style={{ color: "white", textAlign: "start" }}
            >
              <img style={{ width: 300 }} src={HiPhoneLogoImage} />
              <div className="mt-4 bold font16">{t("got_questions")}</div>
              <div className="d-flex align-items-center mt-3">
                <img className="me-2" src={GotQuestionImage} />
                <div className="d-flex justify-content-center flex-column">
                  <div className="d-flex align-items-center">
                    <a href="https://wa.me/+971551907002">
                      <img
                        style={{ height: 20 }}
                        className="me-2 ms-2"
                        src={WhatsappImageGreen}
                      />
                    </a>
                    <div className="d-flex align-items-center ">
                      {t("mobile_and_whatsapp")}:{" "}
                      <div style={{ direction: "ltr" }}>+971 5519 07002</div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center me-2 ms-2">
                      {t("landline")}:{" "}
                      <div style={{ direction: "ltr" }}>+971 4 294 6664</div>
                  </div>
                </div>
              </div>

              <div className="mt-4 bold font16">{t("contact_info")}</div>
              <div className="mt-2 font16">{t("footer_address")}</div>
              <div className="d-flex mt-4">
                <a href="https://www.facebook.com/hiphonetelecom/" target="_blank">
                  <img
                    style={{ height: 35 }}
                    className="me-4"
                    src={FacebookImage}
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCbAwkMGjOONTKRY1iUDpGzg" target="_blank">
                  <img
                    style={{ height: 35 }}
                    className="me-4"
                    src={TwitterImage}
                  />
                </a>
                <a
                  className="me-4"
                  target="_blank"
                  href="https://web.whatsapp.com/send?phone=971551332280"
                >
                  <img
                    style={{ height: 35 }}
                    className="mb-1"
                    src={WhatsappImage}
                  />
                </a>
                <a href="https://www.instagram.com/hiphone_telecom/" target="_blank">
                  <img
                    style={{ height: 35 }}
                    className="me-4"
                    src={InstagramImage}
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCbAwkMGjOONTKRY1iUDpGzg" target="_blank">
                  <img
                    style={{ height: 35 }}
                    className="me-4"
                    src={YoutubeImage}
                  />
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 pt-2 pb-2 mb-4 white">
              <div className="row p-2" style={{ backgroundColor: "#343434" }}>
                <div className="col-7">
                  <div className="bold font18">
                    {t("subscribe_to_our_awesome_emails")}
                  </div>
                  <div className="font12">
                    {t("subscribe_to_our_awesome_emails_description")}
                  </div>
                  <div
                    className="d-flex"
                    style={{ backgroundColor: "#222222" }}
                  >
                    <input
                      className="w-100 ps-2"
                      style={{ background: "#222222", outline: 0, border: 0 }}
                    />
                    <div
                      className="pointer d-flex justify-content-center bold font11 ps-3 pe-3"
                      style={{
                        backgroundColor: "white",
                        width: 80,
                        color: "black",
                      }}
                    >
                      <div>{t("subscribe")}</div>
                    </div>
                  </div>
                </div>

                <div className="col-5">
                  <div className="bold font18">{t("download_our_apps")}</div>
                  <div className="font12">
                    {t("download_our_apps_description")}.
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <img className="w-100" src={AppStoreImage} />
                    </div>
                    <div className="col-6">
                      <img className="w-100" src={PlayStoreImage} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-7">
                  <div className="bold font18 mb-4">{t("find_it_fast")}.</div>
                  <div
                    className="font17 mb-2 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/products`,
                        search: `?${createSearchParams({
                          min_price: "1",
                        })}`,
                      });
                    }}
                  >
                    {t("sale")}
                  </div>
                  <div
                    className="font17 mb-2 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/products`,
                        search: `?${createSearchParams({
                          id: "37",
                        })}`,
                      });
                    }}
                  >
                    {t("bundle_offer")}
                  </div>
                  <div
                    className="font17 mb-2 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/products`,
                        search: `?${createSearchParams({
                          id: "28",
                        })}`,
                      });
                    }}
                  >
                    {t("cases_and_covers")}
                  </div>
                  <div
                    className="font17 mb-2 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/products`,
                        search: `?${createSearchParams({
                          id: "42",
                        })}`,
                      });
                    }}
                  >
                    {t("crazy_deals")}
                  </div>
                  <div
                    className="font17 mb-2 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/products`,
                        search: `?${createSearchParams({
                          id: "46",
                        })}`,
                      });
                    }}
                  >
                    {t("powerbank")}
                  </div>
                  <div className="font17 mb-2 pointer">{t("wishlist")}</div>
                </div>

                <div className="col-5">
                  <div className="bold font18 mb-4">{t("customer_care")}</div>
                  <div
                    className="font17 mb-2 pointer"
                    onClick={() => {
                      if (login) {
                        navigate("/my_account");
                      } else {
                        navigate("/auth");
                      }
                    }}
                  >
                    {t("my_account")}
                  </div>
                  <div className="font17 mb-4 pointer">{t("faqs")}</div>

                  <div
                    className="bold font18 mb-2 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/about`,
                      });
                    }}
                  >
                    {t("about_us")}
                  </div>
                  <div
                    className="bold font18 mb-4 pointer"
                    onClick={() => {
                      navigate({
                        pathname: `/branches`,
                      });
                    }}
                  >
                    {t("branches")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
};
