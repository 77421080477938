import { useSelector } from 'react-redux';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { RootState } from '../../store/rootState';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../utils/hooks/language';

export const FreeDeliverySection = () => {
    const navigate = useNavigate();
    const { login } = useSelector((state: RootState) => state.app);
    const { t, i18n } = useTranslation();
    const [en, ar] = useLanguage();

    const changeLanguage = (language: 'ar' | 'en') => {
        i18n.changeLanguage(language);
      }
    
    return (
        <div className="top-bar disable-selection" style={{ background: 'white'}}>
            <div className="container">
                <ul className={`nav nav-inline pull-${ar ? 'right' : 'left'} electro-animate-dropdown flip bold`}><div id="menu-item-3233" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3233" style={{color: "#334141"}}>
                    {t('free_delivery')}
                    </div>
                </ul>
                <ul className={`nav nav-inline pull-${ar ? 'left' : 'right'} electro-animate-dropdown flip d-flex`} style={{fontSize: 12}}>
                    <div onClick={() => {
                        navigate({
                            pathname: `/products`, search: `?${createSearchParams({
                                'min_price': '0',
                            })}`
                        })
                    }} id="menu-item-4099" className="pointer bold"><a title="Shop" ><i className="ec ec-shopping-bag me-1 ms-1"></i>{t('shop')}</a></div>
                    <div className='ms-2 me-2 d-none d-lg-block' style={{ color: '#D0DADA' }}>|</div>
                    <div onClick={() => {
                        if (login) {
                            navigate("/my_account")
                        } else {
                            navigate("/auth")
                        }
                    }} id="menu-item-4100" className="pointer bold"><a title={`${t('my_account')}`}><div className="ec ec-user me-1 ms-1"></div>{t('my_account')}</a></div>
                    <div className='ms-2 me-2 d-none d-lg-block' style={{ color: '#D0DADA' }}>|</div>
                    
                    { (ar) && <li onClick={() => {
                        changeLanguage('en');
                    }} className='pointer' style={{ display: 'flex', alignItems: 'center', paddingLeft: 20, paddingRight: 20 }}>
                        <img height={12} width={18} src={"https://hiphonetelecom.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png"} alt="ar" title="العربية" />
                        &nbsp;&nbsp;<span >(English)</span>
                    </li>}

                    { en &&  <div onClick={() => {
                        changeLanguage('ar');
                    }} className='pointer bold' style={{ display: 'flex', alignItems: 'center', paddingLeft: 20, paddingRight: 20 }}>
                        <img height={12} width={18} src={"https://hiphonetelecom.com/wp-content/uploads/flags/flag_final.png"} alt="ar" title="العربية" />
                        &nbsp;&nbsp;<span >العربية</span>
                        &nbsp;&nbsp;<span >(Arabic)</span>
                    </div>}
                </ul>
            </div>
        </div>
    )
}