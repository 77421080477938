import { createAction } from 'deox';
import { OrderModel } from '../../../../server/models/order';
import { ProductModel } from '../../../../server/models/product';
import { AddressModel } from '../../../../server/models/address';

export class OrderActions {
  static order = {
    request: createAction('order/order_request', resolve => (id: string) =>
      resolve(id),
    ),
    success: createAction('order/order_success', resolve => (payload: {order: OrderModel, products: ProductModel[], billingAddress: AddressModel, shippingAddress: AddressModel}) =>
      resolve(payload),
    ),
    fail: createAction('order/order_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static redirect = createAction('order/redirect', resolve => (payload: boolean) => resolve(payload));
}
