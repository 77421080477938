import { Api } from './Api';
import { ADD_TO_WISHLIST_URL, REMOVE_FROM_WISHLIST_URL, WISHLIST_URL } from '../../configs';
import { AddToCartPayload } from '../payload/add_to_cart';
import { AddToWishlistPayload } from '../payload/add_to_wishlist';


export class WishlistApis {
  static wishlist(): ReturnType<typeof Api.get> {
    return Api.get(WISHLIST_URL);
  }

  static add(paylod : AddToWishlistPayload): ReturnType<typeof Api.post> {
    return Api.post(ADD_TO_WISHLIST_URL, paylod);
  }

  static remove(payload: { product_id: string}): ReturnType<typeof Api.post> {
    return Api.post(REMOVE_FROM_WISHLIST_URL, payload);
  }
}
