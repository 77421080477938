import { useSelector } from 'react-redux';
import { CartItems } from './cart_item';
import { RootState } from '../../../store/rootState';
import { useAction } from '../../../utils/hooks/action';
import { useEffect, useState } from 'react';
import { CartTotal } from './cart_total';
import { CartActions } from '../module/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../utils/hooks/language';
import TabbyPromoComponent from '../../../components/tabby_promo';

export const CartListComponent = () => {
  const { cart } = useSelector((state: RootState) => state.app);
  const { localUpdatedCart: local_updated_cart, calculate, loadingCalculate: loadingCalculate, loading, coupon: appliedCoupon } = useSelector((state: RootState) => state.cart);
  const requestUpdateCartAction = useAction(CartActions.update.request);
  const setLocalUpdatedCartAction = useAction(CartActions.localUpdatedCart.set);
  const calculateAction = useAction(CartActions.calculate.request);
  const setAppliedCouponAction = useAction(CartActions.coupon.apply);
  const removeAppliedCouponAction = useAction(CartActions.coupon.clear);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ en, ar ] = useLanguage();
  const [coupon, setCoupon] = useState('');

  useEffect(() => {
    calculateAction({
      products: cart.map((item) => {
        return { quantity: item.quantity, id: item.product.id }
      })
    });
  }, [cart])

  useEffect(() => {
    console.log('calculate')
    console.log(calculate)
  }, [calculate])
  
  useEffect(() => {
    console.log('cart')
    console.log(cart)
  }, [cart])

  return (
    <div id="content" className="site-content" tabIndex={-1}>
      <div className="container">
        <nav className="woocommerce-breadcrumb">
          <a href="">{t('home')}</a>
          <span className="delimiter">
            <i className={`fa fa-angle-${en ? 'right' : 'left'}`}/>
          </span>
          {t('cart')}
        </nav>
        <div className="site-content-inner">
          <div id="primary" className="content-area primary-large">
            <main id="main" className="site-main">
              <article
                id="post-3134"
                className="post-3134 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">{t( cart.length > 0 ? 'cart' : 'your_cart_is_currently_empty')}</h1>
                </header>
                { cart.length > 0 && <div className="entry-content">
                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper" />

                    <table
                      className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                      cellSpacing={0}
                    >
                      <thead>
                        <tr>
                          <th className="product-remove">&nbsp;</th>
                          <th className="product-thumbnail">&nbsp;</th>
                          <th className="product-name">{t('product')}</th>
                          <th className="product-price">{t('price')}</th>
                          <th className="product-quantity">{t('quantity')}</th>
                          <th className="product-subtotal">{t('subtotal')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...(cart ?? []).map((item, index) =>
                          <CartItems
                            cart={item}
                            onQuantity={(item) => {
                              setLocalUpdatedCartAction({ cart: cart, item: item });
                            }} />
                        )]}


                        <tr>
                          <td colSpan={6} className="actions">
                            {
                              (local_updated_cart.length > 0 && !loading) &&

                              <button
                                onClick={() => {
                                  requestUpdateCartAction(local_updated_cart)
                                }}
                                className="button"
                                name="update_cart"
                                value="Update cart"
                                aria-disabled="true"
                              >
                                {t('update_cart')}
                              </button>
                            }
                            <div className="coupon" dir='ltr'>
                              <label htmlFor="coupon_code">{t('coupon')}:</label>{" "}
                              <input
                                type="text"
                                name="coupon_code"
                                className="input-text"
                                id="coupon_code"
                                defaultValue={coupon}
                                disabled={loadingCalculate}
                                placeholder={`${t('coupon_code')}`}
                                onChange={(e) => {
                                  setCoupon(e.target.value)
                                }}
                              />{" "}
                              <button
                                type="submit"
                                className="button"
                                name="apply_coupon"
                                disabled={loadingCalculate}
                                onClick={() => {
                                  if (loadingCalculate) {
                                    return;
                                  }
                                  console.log(`applied coupon ${appliedCoupon}`);
                                  if (appliedCoupon.length > 0) {
                                    removeAppliedCouponAction();
                                    calculateAction({
                                      products: cart.map((item) => {
                                        return { quantity: item.quantity, id: item.product.id }
                                      })
                                    });
                                  } else {
                                    console.log(`set coupon ${coupon}`);
                                    setAppliedCouponAction(coupon)
                                    calculateAction({
                                      coupon: coupon,
                                      products: cart.map((item) => {
                                        return { quantity: item.quantity, id: item.product.id }
                                      })
                                    });
                                  }
                                }}
                              >
                                {loadingCalculate ? <div style={{ width: 20, height: 20 }} className='loader'></div> : <div>{t(appliedCoupon.length == 0 ? 'apply_coupon' : 'remove_coupon')}</div>}
                              </button>
                            </div>

                            {(local_updated_cart.length == 0 && !loadingCalculate) && <div
                              className="wc-proceed-to-checkout">
                              <TabbyPromoComponent amount={calculate.total} language={'en'}/>
                              <a
                              onClick={() => {
                                navigate('/checkout')
                              }}
                                className="checkout-button button alt wc-forward mt-2"
                              >
                                {t('proceed_to_checkout')}
                              </a>
                            </div>}

                            {(loadingCalculate || loading) && <div className='loader wc-proceed-to-checkout' />}
                          </td>
                        </tr>
                      </tbody>
                      
                    </table>

                    { cart.length > 0 && <CartTotal />}
                  </div>
                </div>}
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  )

}