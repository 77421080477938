import { useTranslation } from "react-i18next";
import { ProductModel } from "../../../server/models/product"
import IPhoneAdImage1 from '../../../assets/15-series-1.jpg'
import IPhoneAdImage2 from '../../../assets/15-series-2.jpg'
import HeartSvgImage from '../../../assets/heart.svg'
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../store/rootState";
import { useNavigate } from "react-router";
import { useAction } from "../../../utils/hooks/action";
import { WishlistActions } from "../../wishlist/module/actions";
import HeartRedSvgImage from '../../../assets/heart-red.svg'

export const PrimaryProduct = (props: {product: ProductModel | undefined}) => {
    // Navigation
    const navigate = useNavigate();
    // Actions
    const addToWishlistRequestAction = useAction(WishlistActions.add.request);
    const removeFromWishlistRequestAction = useAction(WishlistActions.remove.request);
    // States
    const { wishlist } = useSelector((state: RootState) => state.app);
    const { loadingAddToWishlist, loadingRemoveFromWishlist } = useSelector((state: RootState) => state.wishlist);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (!loadingAddToWishlist && !loadingRemoveFromWishlist) {
            setLoading(false);
        }
    }, [loadingAddToWishlist, loadingRemoveFromWishlist]);

    const inWishlist = () => {
        if(!props.product) {
            return false;
        }
        return (wishlist ?? []).filter((item) => item.id == props.product?.id).length > 0
    }

    const discount = (product: ProductModel) => {
      return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
  }

    if(!props.product) {
      return <></>
    }
    
    return (
      <div onClick={() => {
        navigate(`/product/${props.product?.id}`);
        }} className='container pointer'>
        <div className='row column-same-height'>
          <div className='col-12 col-md-8 col-lg-8 mb-4' style={{}}>
            <div style={{ backgroundColor: 'blue', height: '100%' }}>
              <img style={{ height: '100%', width: '100%' }} src={IPhoneAdImage2} />
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-4 mb-4'>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <img style={{ width: '100%', height: '100%', backgroundColor:'#D9D9D9' }} className='mb-2' src={props.product.image} />
              <div className='mb-2' style={{ display: 'block', fontWeight: 400, fontSize: 18, width: '100%', color: '#343E48', textAlign: 'start', maxHeight: '3.6em', lineHeight: '1.2em', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }}>{props.product.name_en}</div>
              <div className="d-flex justify-content-between algn-items-center mt-2 w-100">
                {/* <span className="onsale" style={{ borderRadius: 6 }}>
                    -<span className="percentage">{10}%</span>
                  </span> */}
                { (discount(props.product) > 0) && <div className='d-flex justify-content-center align-items-center ps-3 pe-3 me-2' style={{ backgroundColor: '#EB212E', height: 33, borderRadius: 6, fontWeight: 'bold', fontSize: 15, color: 'white' }}>-<div >{discount(props.product)}%</div></div>}
                { (discount(props.product) > 0) && <div className='me-2' />}
                <div className="d-flex justify-content-start align-items-end w-100" style={{ fontWeight: 400, textAlign: 'start', fontSize: 14, color: 'black', height: '100%' }}>
                  <div className='bold' style={{ fontSize: 16 }}>
                    {t('aed')} {props.product.sale_price}
                  </div>
                  <div className='ms-2 me-2' />
                  { (discount(props.product) > 0) && <del style={{ fontSize: 15, color: 'red' }}>
                    <div style={{ color: 'red' }}>{t('aed')} {props.product.regular_price}</div>
                  </del>}
                </div>
                {<img onClick={(e) => {
                        if (inWishlist()) {
                            setLoading(true);
                            removeFromWishlistRequestAction(props.product!);
                        }
                        else {
                            setLoading(true);
                            addToWishlistRequestAction(props.product!);
                        }
                        e.stopPropagation();
                    }} className="pointer" width={35} height={35} src={inWishlist() ? HeartRedSvgImage : HeartSvgImage} />}
                    {/* {loading && <div style={{ width: 25, height: 25 }} className="loader"></div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }