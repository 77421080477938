import { useEffect, useState } from "react";
import { AvailableCart } from "./available_cart";
import { EmptyCart } from "./empty_cart";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootState";
import { ProductModel } from "../../server/models/product";
import { useAction } from "../../utils/hooks/action";
import { CartActions } from "../../pages/cart/module/actions";
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from "../../utils/hooks/language";
import { useTranslation } from "react-i18next";

export const CartOnTop = () => {
    // Navigation
    const navigate = useNavigate();
    // States
    const { cart } = useSelector((state: RootState) => state.app);
    const [show, setShow] = useState(false);
    // Actions
    const requestRemoveFromCartAction = useAction(CartActions.remove.request);
    const requestCartAction = useAction(CartActions.cart.request);
    const [en, ar] = useLanguage();
    const { t, i18n } = useTranslation();
    
    const total = () => {
        var result = 0;
        (cart ?? []).forEach(item => {
            if(item.product) {
                if(item.product.sale_price && item.quantity) {
                    result += (+item.quantity * +(item.product.sale_price))
                }
            }
        })
        return result.toFixed(2);
    }

    const count = () => {
        var result = 0;
        (cart ?? []).forEach(item => {
            result += (+item.quantity)
        })
        return result;
    }

    
    useEffect(() => {
        //document.addEventListener('mousedown', () => setShow(false));
        window.addEventListener('click', function(e){   
            var cartOnTop = (document as any).getElementById('cartTop');
            if(cartOnTop) {
                if (cartOnTop.contains(e.target)) {
                    // Clicked in box
                  } else{
                    // Clicked outside the box
                    setShow(false)
                  }
            }
          });
    },[]);

    console.log('cart'  +(cart ?? []))

    return (
        <div
            className={show ? "header-icon header-icon__cart animate-dropdown dropdown open" : "header-icon"}
            data-toggle="tooltip"
            data-placement="bottom"
            data-title="Cart"
            data-original-title=""
            title=""
            id="cartTop"
            onClick={() => {
                setShow(true)
            }}
        >
            <a
                className="pointer d-flex align-items-center"
                data-toggle="dropdown"
                aria-expanded="true"
                style={{height:75}}
            >
                <i className="ec ec-shopping-bag" />
                <span className="cart-items-count count header-icon-counter" style={{right:5, marginBottom: 20}}>{count()}</span>
                <span className="cart-items-total-price total-price">
                    <span className="woocommerce-Price-amount amount">
                        <bdi>
                            <span className="woocommerce-Price-currencySymbol">{t('aed')}</span>
                            &nbsp;{total()}
                        </bdi>
                    </span>
                </span>
            </a>
            <ul className="dropdown-menu dropdown-menu-mini-cart" style={{left: en ? 'auto' : 0, right: ar ? 'auto' : 0, borderTop: '2px solid #ED1C24', borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                <li>
                    <div className="widget_shopping_cart_content">
                        {((cart ?? []).length > 0)
                            ? <>
                                {[...cart.map((item, index) =>
                                    <AvailableCart
                                        cart={item}
                                        onRemove={function (product: ProductModel) {
                                            console.log('remove product id');
                                            console.log(product);
                                            requestRemoveFromCartAction({ product_id: product.id })
                                        }} />
                                )]}
                                <p className="woocommerce-mini-cart__buttons buttons">
                                    <a
                                        onClick={() => {
                                            navigate('/cart')
                                        }}
                                        className="button wc-forward">
                                        {t('view_cart')}
                                    </a>
                                    <a
                                    onClick={() => {
                                        navigate('/checkout')
                                    }}
                                        className="button checkout wc-forward"
                                    >
                                        {t('checkout')}
                                    </a>
                                </p>
                            </>
                            : <EmptyCart />}
                    </div>
                </li>
            </ul>
        </div>
    )
};