import { createAction } from 'deox';
import { resolveConfig } from 'prettier';
import { LoginRequestPayload } from '../../../server/payload/login';
import { RegisterRequestPayload } from '../../../server/payload/register';

export class AuthActions {
  static login = {
    request: createAction('auth/login_request', resolve => (payload: LoginRequestPayload) =>
      resolve(payload),
    ),
    success: createAction('auth/login_success'),
    fail: createAction('auth/login_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static register = {
    request: createAction('auth/register_request', resolve => (payload: RegisterRequestPayload) =>
      resolve(payload),
    ),
    success: createAction('auth/register_success', resolve => (payload: string) =>
      resolve(payload),
    ),
    fail: createAction('auth/register_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
