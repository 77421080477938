import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { ADDRESSES_URL, ADDRESS_URL, ORDERS_URL, ORDER_URL } from '../../configs';
import { AddressModel } from '../models/address';
import { AddressesModel } from '../models/addresses';


export class AddressesApis {
  static addresses(): ReturnType<typeof Api.get> {
    return Api.get(ADDRESSES_URL);
  }

  static putAddress(payload: AddressesModel): ReturnType<typeof Api.post> {
    return Api.post(ADDRESS_URL, payload);
  }
}
