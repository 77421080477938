export const SidenavItem = (props: {title: string, onClick: Function}) => {
    return (
        <li className='d-flex sidenav-item ps-4 pe-4' onClick={() => {
            props.onClick()
        }}>
            <div className='pointer' style={{ display: 'flex', alignItems: 'center' }}>
                {props.title}
            </div>
        </li>
    )
}