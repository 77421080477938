import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export const BranchComponent = () => {
  const { t } = useTranslation();
  // Search params
  let [params, setParams] = useSearchParams();
  const [branch, setBranch] = useState("dubai");

  useEffect(() => {
    if (params.get("name")) {
      setBranch(params.get("name") ?? "dubai");
    }
  }, [params]);

  const Seperator = () => {
    return (
      <>
        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
          <span className="vc_sep_holder vc_sep_holder_l">
            <span className="vc_sep_line" />
          </span>
          <span className="vc_sep_holder vc_sep_holder_r">
            <span className="vc_sep_line" />
          </span>
        </div>
      </>
    );
  };

  const Emirates = () => {
    return (
      <>
        <div className="vc_tta-tabs-container" style={{textAlign:'start'}}>
          <ul className="vc_tta-tabs-list">
            <li
              className={`vc_tta-tab ${branch == "dubai" ? "vc_active" : ""}`}
              data-vc-tab=""
              onClick={() => {
                params.set("name", "dubai");
                setParams(params);
              }}
            >
              <a data-vc-tabs="" data-vc-container=".vc_tta">
                <span className="vc_tta-title-text">{t("Dubai")}</span>
              </a>
            </li>
            <li
              className={`vc_tta-tab ${
                branch == "abu dhabi" ? "vc_active" : ""
              }`}
              data-vc-tab=""
              onClick={() => {
                params.set("name", "abu dhabi");
                setParams(params);
              }}
            >
              <a data-vc-tabs="" data-vc-container=".vc_tta">
                <span className="vc_tta-title-text">{t("Abu Dhabi")}</span>
              </a>
            </li>
            <li
              className={`vc_tta-tab ${branch == "sharjah" ? "vc_active" : ""}`}
              data-vc-tab=""
              onClick={() => {
                params.set("name", "sharjah");
                setParams(params);
              }}
            >
              <a data-vc-tabs="" data-vc-container=".vc_tta">
                <span className="vc_tta-title-text">{t("Sharjah")}</span>
              </a>
            </li>
            <li
              className={`vc_tta-tab ${branch == "ajman" ? "vc_active" : ""}`}
              data-vc-tab=""
              onClick={() => {
                params.set("name", "ajman");
                setParams(params);
              }}
            >
              <a data-vc-tabs="" data-vc-container=".vc_tta">
                <span className="vc_tta-title-text">{t("Ajman")}</span>
              </a>
            </li>
            <li
              className={`vc_tta-tab ${
                branch == "umm al quwain" ? "vc_active" : ""
              }`}
              data-vc-tab=""
              onClick={() => {
                params.set("name", "umm al quwain");
                setParams(params);
              }}
            >
              <a data-vc-tabs="" data-vc-container=".vc_tta">
                <span className="vc_tta-title-text">{t("Umm Al Quwain")}</span>
              </a>
            </li>
            <li
              className={`vc_tta-tab ${
                branch == "fujairah" ? "vc_active" : ""
              }`}
              data-vc-tab=""
              onClick={() => {
                params.set("name", "fujairah");
                setParams(params);
              }}
            >
              <a data-vc-tabs="" data-vc-container=".vc_tta">
                <span className="vc_tta-title-text">{t("Fujairah")}</span>
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  };

  const Dubai = () => {
    return (
      <>
        <div
          className={`vc_tta-panel ${branch == "dubai" ? "vc_active" : ""}`}
          id="dubai"
          data-vc-content=".vc_tta-panel-body"
        >
          <div className="vc_tta-panel-heading">
            <h4 className="vc_tta-panel-title">
              <a
                href="branches/dubai"
                data-vc-accordion=""
                data-vc-container=".vc_tta-container"
              >
                <span className="vc_tta-title-text">{t("Dubai")}</span>
              </a>
            </h4>
          </div>
          <div className="vc_tta-panel-body">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1604169525610">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      The Dubai Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602361129548">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Level-1.jpg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Level-1.jpg?w=1280&ssl=1 1280w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Level-1.jpg?resize=300%2C225&ssl=1 300w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Level-1.jpg?resize=1024%2C768&ssl=1 1024w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Level-1.jpg?resize=768%2C576&ssl=1 768w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Level-1.jpg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          The Dubai Mall- Level 2
                          <br />
                          Contact: 055 133 2269
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/NvWQggvH48r"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/NvWQggvH48r
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      The Dubai Mall - Reel Cinema
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602361231581">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Reel-Cinemas.jpeg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Reel-Cinemas.jpeg?w=1280&ssl=1 1280w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Reel-Cinemas.jpeg?resize=300%2C225&ssl=1 300w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Reel-Cinemas.jpeg?resize=1024%2C768&ssl=1 1024w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Reel-Cinemas.jpeg?resize=768%2C576&ssl=1 768w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dubai-Mall-Reel-Cinemas.jpeg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Dubai Mall Reel Cinema
                          <br />
                          Contact: 056 410 2991
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/NvWQggvH48r"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/NvWQggvH48r
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Mall of the Emirates
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602361355981">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Mall-of-the-Emirates.jpeg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Mall-of-the-Emirates.jpeg?w=1280&ssl=1 1280w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Mall-of-the-Emirates.jpeg?resize=300%2C225&ssl=1 300w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Mall-of-the-Emirates.jpeg?resize=1024%2C768&ssl=1 1024w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Mall-of-the-Emirates.jpeg?resize=768%2C576&ssl=1 768w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Mall-of-the-Emirates.jpeg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Mall of the Emirates -Ground Level
                          <br />
                          Contact: 055 133 2256
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/uqk8AfX8XWH2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/uqk8AfX8XWH2
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Seperator />
            <div className="vc_row wpb_row vc_inner vc_row-fluid">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Deira
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602361775303">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1024}
                            height={768}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Carrefour.jpeg?fit=1024%2C768&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Carrefour.jpeg?w=1024&ssl=1 1024w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Carrefour.jpeg?resize=300%2C225&ssl=1 300w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Carrefour.jpeg?resize=768%2C576&ssl=1 768w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Carrefour.jpeg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1024px) 100vw, 1024px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          City Centre Deira Level One, near Carrefour
                          <br />
                          Contact: 055 133 2264
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/8W8wgKUxkXQ2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/8W8wgKUxkXQ2
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Deira
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602361887820">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={960}
                            height={720}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Metro-Link.jpg?fit=960%2C720&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Metro-Link.jpg?w=960&ssl=1 960w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Metro-Link.jpg?resize=300%2C225&ssl=1 300w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Metro-Link.jpg?resize=768%2C576&ssl=1 768w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Deira-City-Centre-near-Metro-Link.jpg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 960px) 100vw, 960px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          City Centre Deira,&nbsp;Level One near Metro Link
                          <br />
                          Contact: 055 133 2265
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/8W8wgKUxkXQ2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/8W8wgKUxkXQ2
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Me'aisem
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602362170152">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1032}
                            height={774}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/190911123243.jpg?fit=1032%2C774&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/190911123243.jpg?w=1032&ssl=1 1032w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/190911123243.jpg?resize=300%2C225&ssl=1 300w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/190911123243.jpg?resize=1024%2C768&ssl=1 1024w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/190911123243.jpg?resize=768%2C576&ssl=1 768w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/190911123243.jpg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1032px) 100vw, 1032px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          City Centre Me’aisem, Ground Level
                          <br />
                          Contact: 056 433 6372
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/xA5V6bSUXMw"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/xA5V6bSUXMw
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Seperator />
            <div className="vc_row wpb_row vc_inner vc_row-fluid">
              {/* <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Dubai Marina Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602362487839">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={2560}
                            height={1920}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?fit=2560%2C1920&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?w=2560&ssl=1 2560w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?resize=300%2C225&ssl=1 300w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?resize=1024%2C768&ssl=1 1024w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?resize=768%2C576&ssl=1 768w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?resize=1536%2C1152&ssl=1 1536w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?resize=2048%2C1536&ssl=1 2048w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?resize=600%2C450&ssl=1 600w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Marina-Mall-Ground-Level-scaled.jpg?w=2340&ssl=1 2340w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Dubai Marina Mall, Ground Level
                          <br />
                          Contact: 055 133 2279
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/JMMgy2TkZGGRwUtRA"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/JMMgy2TkZGGRwUtRA
                          </a>
                          <br />
                          Timing: 10 AM – 10 PM on weekdays. 10AM – 12 AM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Burjuman Centre
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602430065397">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/burjuman_new.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/burjuman_new.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/burjuman_new.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Burjuman Centre, Ground Level
                          <br />
                          Contact: 055 133 2287
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/Ge3GGMwKkLu"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/Ge3GGMwKkLu
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 11 PM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Mirdif
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602444700280">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/MIrdif.jpg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/MIrdif.jpg?w=1280&ssl=1 1280w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/MIrdif.jpg?resize=300%2C225&ssl=1 300w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/MIrdif.jpg?resize=1024%2C768&ssl=1 1024w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/MIrdif.jpg?resize=768%2C576&ssl=1 768w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/MIrdif.jpg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          City Centre Mirdif, Ground Floor
                          <br />
                          Contact: 055 337 7386
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/8Yh39UwgzLcYZkxLA"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/8Yh39UwgzLcYZkxLA
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      The Dubai Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/01/DM-Lower.jpg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/01/DM-Lower.jpg?w=1280&ssl=1 1280w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/01/DM-Lower.jpg?resize=300%2C225&ssl=1 300w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/01/DM-Lower.jpg?resize=1024%2C768&ssl=1 1024w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/01/DM-Lower.jpg?resize=768%2C576&ssl=1 768w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/01/DM-Lower.jpg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          The Dubai Mall- Lower Ground
                          <br />
                          Contact: 056 400 2158
                          <br />
                          Map:{" "}
                          <a href="https://goo.gl/maps/NvWQggvH48r">
                            https://goo.gl/maps/NvWQggvH48r
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Seperator />
            <div className="vc_row wpb_row vc_inner vc_row-fluid">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      My City Centre Barsha
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602444982446">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/200721101715.jpeg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/200721101715.jpeg?w=1280&ssl=1 1280w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/200721101715.jpeg?resize=300%2C225&ssl=1 300w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/200721101715.jpeg?resize=1024%2C768&ssl=1 1024w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/200721101715.jpeg?resize=768%2C576&ssl=1 768w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/200721101715.jpeg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          My City Centre Barsha-Ground Floor
                          <br />
                          Contact: 052 995 4214
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/gfsMHr6tBMwDN2Zd9"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/gfsMHr6tBMwDN2Zd9
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Fujairah = () => {
    return (
      <>
        <div
          className={`vc_tta-panel ${branch == "fujairah" ? "vc_active" : ""}`}
          id="1602368451077-da5f563b-dfb6"
          data-vc-content=".vc_tta-panel-body"
        >
          <div className="vc_tta-panel-heading">
            <h4 className="vc_tta-panel-title">
              <a
                href="branches/1602368451077-da5f563b-dfb6"
                data-vc-accordion=""
                data-vc-container=".vc_tta-container"
              >
                <span className="vc_tta-title-text">Fujairah</span>
              </a>
            </h4>
          </div>
          <div className="vc_tta-panel-body">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1604169636779">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Fujairah
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602452217745">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/City-Centre-Fujairah-Ground-Level-new.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/City-Centre-Fujairah-Ground-Level-new.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/City-Centre-Fujairah-Ground-Level-new.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Fujairah City Center, Ground Level
                          <br />
                          Contact: 055 133 2286
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/yqLCcRrRsSC2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/yqLCcRrRsSC2
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const AbuDhabi = () => {
    return (
      <>
        <div
          className={`vc_tta-panel ${branch == "abu dhabi" ? "vc_active" : ""}`}
          id="1602359769530-b03be800-b958"
          data-vc-content=".vc_tta-panel-body"
        >
          <div className="vc_tta-panel-heading">
            <h4 className="vc_tta-panel-title">
              <a
                href="branches/1602359769530-b03be800-b958"
                data-vc-accordion=""
                data-vc-container=".vc_tta-container"
              >
                <span className="vc_tta-title-text">Abu Dhabi</span>
              </a>
            </h4>
          </div>
          <div className="vc_tta-panel-body">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1604169563737">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Abu Dhabi Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602449235595">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/abu-dhabi-mall-1.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/abu-dhabi-mall-1.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/abu-dhabi-mall-1.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Abu Dhabi Mall, Level One
                          <br />
                          Contact: 055 440 3100
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/GVVDpU8kkgp"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/GVVDpU8kkgp
                          </a>
                          <br />
                          10 AM – 12 AM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Dalma Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602449337040">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dalma-Mall-Level-new.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dalma-Mall-Level-new.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Dalma-Mall-Level-new.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Dalma&nbsp;Mall, Level One
                          <br />
                          Contact: 055 260 6800
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/B1HsEozcmxj"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/B1HsEozcmxj
                          </a>
                          <br />
                          10 AM – 11 PM on weekdays. 10AM – 12 AM on weekends
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Mushrif Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602449448100">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/mushrif_mall_new.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/mushrif_mall_new.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/mushrif_mall_new.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Mushrif Mall, Basement Level
                          <br />
                          Contact: 055 343 4039
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/pNixER6hKPx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/pNixER6hKPx
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 11 PM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Seperator />
            <div className="vc_row wpb_row vc_inner vc_row-fluid">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      My City Centre Masdar
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602449717184">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/masdar_new.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/masdar_new.jpg?w=600&ssl=1 600w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/masdar_new.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          My City Centre Masdar Abu Dhabi, Ground Level
                          <br />
                          Contact: 0551 906 500
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/k8kowcdnTMgG45Db8"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/k8kowcdnTMgG45Db8
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Sharjah = () => {
    return (
      <>
        <div
          className={`vc_tta-panel ${branch == "sharjah" ? "vc_active" : ""}`}
          id="1602368407856-cea7364c-cdba"
          data-vc-content=".vc_tta-panel-body"
        >
          <div className="vc_tta-panel-heading">
            <h4 className="vc_tta-panel-title">
              <a
                href="branches/1602368407856-cea7364c-cdba"
                data-vc-accordion=""
                data-vc-container=".vc_tta-container"
              >
                <span className="vc_tta-title-text">Sharjah</span>
              </a>
            </h4>
          </div>
          <div className="vc_tta-panel-body">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1604169587360">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Sharjah
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602450196019">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/City-Centre-Sharjah-new-1.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/City-Centre-Sharjah-new-1.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/City-Centre-Sharjah-new-1.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          City Centre Sharjah, Level One
                          <br />
                          Contact: 055 133 2254
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/3fKaMWGNV7MBVd346"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/3fKaMWGNV7MBVd346
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Sharjah Mega Mall
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602450336767">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/mega_mall_new-1.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/mega_mall_new-1.jpg?w=600&ssl=1 600w, https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/mega_mall_new-1.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Sharjah Mega Mall, Ground Level
                          <br />
                          Contact: 055 337 7392
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/bUWn3mgUEpL2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/bUWn3mgUEpL2
                          </a>
                          <br />
                          10 AM – 11 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Sahara Centre
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602450457461">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Sahara-Centre.jpeg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Sahara-Centre.jpeg?w=1280&ssl=1 1280w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Sahara-Centre.jpeg?resize=300%2C225&ssl=1 300w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Sahara-Centre.jpeg?resize=1024%2C768&ssl=1 1024w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Sahara-Centre.jpeg?resize=768%2C576&ssl=1 768w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Sahara-Centre.jpeg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Sahara Centre, Ground Level
                          <br />
                          Contact: 056 538 4431
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/8FMWSQYguFWkpjwK7"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/8FMWSQYguFWkpjwK7
                          </a>
                          <br />
                          10 AM – 11 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Ajman = () => {
    return (
      <>
        <div
          className={`vc_tta-panel ${branch == "ajman" ? "vc_active" : ""}`}
          id="1602368419449-f9a8e3bd-db7e"
          data-vc-content=".vc_tta-panel-body"
        >
          <div className="vc_tta-panel-heading">
            <h4 className="vc_tta-panel-title">
              <a
                href="branches/1602368419449-f9a8e3bd-db7e"
                data-vc-accordion=""
                data-vc-container=".vc_tta-container"
              >
                <span className="vc_tta-title-text">Ajman</span>
              </a>
            </h4>
          </div>
          <div className="vc_tta-panel-body">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1604169603588">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      City Centre Ajman
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602451335217">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1280}
                            height={960}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Ajman-City-Centre.jpg?fit=1280%2C960&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Ajman-City-Centre.jpg?w=1280&ssl=1 1280w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Ajman-City-Centre.jpg?resize=300%2C225&ssl=1 300w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Ajman-City-Centre.jpg?resize=1024%2C768&ssl=1 1024w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Ajman-City-Centre.jpg?resize=768%2C576&ssl=1 768w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Ajman-City-Centre.jpg?resize=600%2C450&ssl=1 600w"
                            sizes="(max-width: 1170px) 100vw, 1170px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          City Centre Ajman, Ground Level
                          <br />
                          Contact: 055 133 2274
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/6Kd91oY3XLoBzw6F9"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/6Kd91oY3XLoBzw6F9
                          </a>
                          <br />
                          10 AM – 10 PM on weekdays. 10AM – 12 AM on weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const UAQ = () => {
    return (
      <>
        <div
          className={`vc_tta-panel ${
            branch == "umm al quwain" ? "vc_active" : ""
          }`}
          id="1602368436356-b53e23db-e8b0"
          data-vc-content=".vc_tta-panel-body"
        >
          <div className="vc_tta-panel-heading">
            <h4 className="vc_tta-panel-title">
              <a
                href="branches/1602368436356-b53e23db-e8b0"
                data-vc-accordion=""
                data-vc-container=".vc_tta-container"
              >
                <span className="vc_tta-title-text">Umm Al Quwain</span>
              </a>
            </h4>
          </div>
          <div className="vc_tta-panel-body">
            <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1604169616312">
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Umm Al Quwain King Faizal Road
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602451801523">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Umm-Al-Quwain-King-Faisal-Road-New.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Umm-Al-Quwain-King-Faisal-Road-New.jpg?w=600&ssl=1 600w, https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Umm-Al-Quwain-King-Faisal-Road-New.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Umm Al Quwain King Faizal Road
                          <br />
                          Contact: 055 133 2276
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/W34jYTgcQU92"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/W34jYTgcQU92
                          </a>
                          <br />9 AM – 10 PM on weekdays. 9 AM – 10 PM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-4">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <h4
                      style={{
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      className="vc_custom_heading"
                    >
                      Umm Al Quwain Al Salamah
                    </h4>
                    <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1602451910313">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={600}
                            height={447}
                            src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Umm-Al-Quwain-Al-Salamah-New.jpg?fit=600%2C447&ssl=1"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                            srcSet="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Umm-Al-Quwain-Al-Salamah-New.jpg?w=600&ssl=1 600w, https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/10/Umm-Al-Quwain-Al-Salamah-New.jpg?resize=300%2C224&ssl=1 300w"
                            sizes="(max-width: 600px) 100vw, 600px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Umm Al Quwain Al Salamah
                          <br />
                          Contact: 056 6861 630
                          <br />
                          Map:{" "}
                          <a
                            href="https://goo.gl/maps/L78EJso7f9A2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://goo.gl/maps/L78EJso7f9A2
                          </a>
                          <br />9 AM – 10 PM on weekdays. 9 AM – 10 PM on
                          weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Seperator />
          </div>
        </div>
      </>
    );
  };

  return (
    <div id="content" className="site-content" tabIndex={-1}>
      <div className="container">
        <nav className="woocommerce-breadcrumb">
          <a href="https://hiphonetelecom.com">{t("home")}</a>
          <span className="delimiter">
            <i className="fa fa-angle-right" />
          </span>
          {t("branches")}
        </nav>
        <div className="site-content-inner">
          <div id="primary" className="w-100">
            <main id="main" className="site-main">
              <article
                id="post-65713"
                className="post-65713 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">{t("branches")}</h1>
                </header>
                {/* .entry-header */}
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classic vc_tta-shape-rounded vc_tta-spacing-1 vc_tta-tabs-position-top vc_tta-controls-align-left">
                        <Emirates />
                        <div className="vc_tta-panels-container">
                          <div className="vc_tta-panels">
                            <Dubai />
                            <AbuDhabi />
                            <Sharjah />
                            <Ajman />
                            <UAQ />
                            <Fujairah />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .entry-content */}
              </article>
            </main>
            {/* #main */}
          </div>
          {/* #primary */}
        </div>
      </div>
      {/* .col-full */}
    </div>
  );
};
