import React, { CSSProperties, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { Slideshow } from "./components/slideshow";
import TabbyBlackIconSvgImage from "../../assets/tabby-black-icon.svg";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Header } from "../../components/header/headers";
import { Footer } from "../../components/footer";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootState";
import { useAction } from "../../utils/hooks/action";
import { HomeCategories } from "./components/home_categories";
import { HomeActions } from "./module/actions";
import { FlashSales } from "./components/flashsales";
import { BasePage } from "../base/base";
import { Brands } from "./components/brands";
import RepairImage from "../../assets/repair-banner-2.jpg";
import NorthLadderImageEn from "../../assets/trade-in-banner-en.jpg";
import NorthLadderImageAr from "../../assets/trade-in-banner-ar.jpg";
import IPhone15SeriesBannerImage from "../../assets/15-series-banner.jpg";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { BestSeller } from "./components/best_sellers";
import { YouMayAlsoLike } from "./components/you_may_also_like";
import { useTranslation } from "react-i18next";
import { PrimaryProduct } from "./components/primary_product";
import { WishlistActions } from "../wishlist/module/actions";
import { useLanguage } from "../../utils/hooks/language";

export const HomePage = () => {
  const {
    loadingSlider,
    loadingFlashSales,
    slider,
    loadingYouMayLike,
    loadingBestSellers,
    loadingBrands,
    loadingPrimaryProduct,
    primaryProduct,
  } = useSelector((state: RootState) => state.home);
  const requestSliderAction = useAction(HomeActions.slider.request);
  const requestFlashSalesAction = useAction(HomeActions.flashSales.request);
  const requestBestSellersAction = useAction(HomeActions.bestSellers.request);
  const requestYouMayLikeAction = useAction(HomeActions.youMayLike.request);
  const requestBrandsAction = useAction(HomeActions.brands.request);
  const requestPrimaryProducts = useAction(HomeActions.primaryProduct.request);
  const requestWishlistAction = useAction(WishlistActions.wishlist.request);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [en, ar] = useLanguage();

  useEffect(() => {
    requestSliderAction();
    requestFlashSalesAction();
    requestBestSellersAction();
    requestYouMayLikeAction();
    requestBrandsAction();
    requestPrimaryProducts({ id: "123595" });
    requestWishlistAction();
  }, []);

  if (
    loadingSlider ||
    loadingFlashSales ||
    loadingBestSellers ||
    loadingYouMayLike ||
    loadingBrands ||
    loadingPrimaryProduct
  ) {
    return <></>;
  }

  const AdsBar = () => {
    if (ar) {
      return (
        <div className="hwrap-ar" style={{ direction: "ltr" }}>
          <div className="hmove-ar">
            {Array.from({ length: 5 }, (_, i) => (
              <div key={i} className="hitem-ar">
                {t("buy_now_pay_later")}
                <div className="ms-2 me-2 d-flex">
                  .<img width={94} height={30} src={TabbyBlackIconSvgImage} />
                </div>
                {t("split_in_4_with")}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className="hwrap" style={{ direction: "ltr" }}>
        <div className="hmove">
          {Array.from({ length: 5 }, (_, i) => (
            <div key={i} className="hitem">
              {t("split_in_4_with")}
              <div className="ms-2 me-2 d-flex">
                <img width={94} height={30} src={TabbyBlackIconSvgImage} />.
              </div>
              {t("buy_now_pay_later")}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <BasePage>
      <div>
        <Header />
        <div className="mt-3" />

        {loadingSlider ? <></> : <Slideshow images={slider} />}
        <AdsBar />
        <FlashSales />

        {/* <div className='container' >
          <video autoPlay loop muted>
            <source src={'videos/home-page-video.mp4'} type='video/mp4' />
          </video>
        </div> */}

        <Brands />
        {/* Home categories */}
        <HomeCategories />

        {/* Repair */}
        <div className="pointer mb-4 container">
          <a
            style={{ width: "100%" }}
            href="https://hiphone.northladder.net/"
            target="_blank"
          >
            <img
              style={{ width: "100%" }}
              className="item-shadow"
              src={ar ? NorthLadderImageAr : NorthLadderImageEn}
            />
          </a>
        </div>

        {/* Repair */}
        <div
          className="pointer mb-4 container"
          onClick={() => {
            navigate("/repair");
          }}
        >
          <img
            style={{ width: "100%" }}
            className="item-shadow"
            src={RepairImage}
          />
        </div>
        
        {/* 15 Series Banner */}
        <div
          className="pointer mb-4 container"
          onClick={() => {
            //navigate("/repair");
            navigate({
              pathname: `/products`, search: `?${createSearchParams({
                'id': '21',
                'title': "IPhone 15 Series",
                'name': `IPhone 15`,
              })}`})
          }}
        >
          <img
            style={{ width: "100%" }}
            className="item-shadow"
            src={IPhone15SeriesBannerImage}
          />
        </div>


        {/* Iphone */}
        <PrimaryProduct product={primaryProduct} />

        {/* Best Seller */}
        <BestSeller />

        {/* YOU MAY ALSO LIKE */}
        <YouMayAlsoLike />

        <div className="news"></div>
        <Footer />
      </div>
    </BasePage>
  );
};
