import { useSelector } from 'react-redux';
import FlashSalesBackgroundImage from '../../../assets/flashsales_background.jpg';
import { RootState } from '../../../store/rootState';
import { FlashSale } from './flashsale';
import { BestSell } from './best_sell';
import LeftArrowSvgImage from '../../../assets/left-arrow.svg'
import RightArrowSvgImage from '../../../assets/right-arrow.svg'
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../utils/hooks/language';

export const BestSeller = () => {
  const { loadingSlider, loadingFlashSales, slider, bestSellers } = useSelector((state: RootState) => state.home);
  const { t, i18n } = useTranslation();
  const [en, ar] = useLanguage();

  if ((bestSellers ?? []).length == 0) {
    return <></>
  }

  return (
    <div
      className='flashsales pt-4 pb-3 mb-2'>
      <div className='container'>
        <h2 style={{ fontWeight: 'bold' }}>{t('best_seller')}</h2>
      </div>

      <div className='container'>
        <div className='row' style={{ position: 'relative' }}>
          <div style={{ flex: 1 }}>
            <div className='best-sellers pt-4 pb-4' style={{ backgroundColor: '#D9D9D9' }}>
              <ul>
                {[...(bestSellers ?? []).map((product, index) =>
                  <BestSell product={product} />
                )]}
              </ul>
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-center' style={{ position: 'absolute', top: '45%' }}>
            <img className='pointer ms-2 me-2' src={en ? LeftArrowSvgImage : RightArrowSvgImage} style={{ height: 20, width: 20 }} />
            <img className='pointer ms-2 me-2' src={en ? RightArrowSvgImage : LeftArrowSvgImage} style={{ height: 20, width: 20 }} />
          </div>
        </div>
      </div>
    </div>
  )
}