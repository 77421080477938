import React, { useEffect } from "react";
import LoadScript from "react-load-script";

const TabbyPromoComponent = (props: {amount: String, language: 'en' | 'ar'}) => {
  const handleScriptLoad = () => {
    (window as any).TabbyPromo({
      selector: "#TabbyPromo", // required, content of tabby Promo Snippet will be placed in element with that selector.
      currency: "AED", // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      price: props.amount, // required, price or the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
      installmentsCount: 4, // Optional, for non-standard plans.
      lang: props.language, // Optional, language of snippet and popups, if the property is not set, then it is based on the attribute 'lang' of your html tag.
      source: "cart", // Optional, snippet placement; `product` for product page and `cart` for cart page.
      publicKey: "pk_test_12c04f33-2c1d-4e5d-afe1-0c2077afa273", // required, store Public Key which identifies your account when communicating with tabby.
      merchantCode: "ae", // required
    });
    console.log("script loaded successfully");
  };

  return (
    <div>
      <LoadScript
        url="https://checkout.tabby.ai/tabby-promo.js"
        onLoad={handleScriptLoad}
      />
      <div id="TabbyPromo"></div>
    </div>
  );
};

export default TabbyPromoComponent;
