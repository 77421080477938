import { HomePage } from "../pages/home"
import { AuthPage } from "../pages/auth"
import { DashboardPage } from "../pages/my_account/dashboard/dashboard"
import { OrdersPage } from "../pages/my_account/orders/orders"
import { AddressesPage } from "../pages/my_account/addresses/addresses"
import { AccountDetailsPage } from "../pages/my_account/account_details/account_details"
import { EditBillingAddressPage } from "../pages/my_account/addresses/edit_billing_address"
import { CommonPage } from "../pages/common"
import { BranchesPage } from "../pages/branch"
import { AboutPage } from "../pages/about"
import { CartPage } from "../pages/cart"
import { CheckoutPage } from "../pages/checkout"
import { ProductsPage } from "../pages/products"
import { ProductPage } from "../pages/product"
import { RepairServicesPage } from "../pages/repair_services"
import { WishlistPage } from "../pages/wishlist"
import { hexDecode } from "../utils/string"
import { OrderPage } from "../pages/my_account/order/order"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { EditShippingAddressPage } from "../pages/my_account/addresses/edit_shipping_address"
import { RootState } from "../store/rootState"
import { useSelector } from 'react-redux';
import { TermsAndConditionsPage } from "../pages/terms_and_conditions"
import { ReturnPolicyPage } from "../pages/return_policy"

export const ProtectedRoute = ({ children }) => {
  const { login } = useSelector((state: RootState) => state.app);
  if (!login) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/my_account" element={
        <ProtectedRoute children={<DashboardPage />} />
      }></Route>
      <Route path="/my_account/dashboard" element={
        <ProtectedRoute children={<DashboardPage />} />
      } />
      <Route path="/my_account/details" element={
        <ProtectedRoute children={<AccountDetailsPage />} />
      } />
      <Route path="/my_account/addresses" element={
        <ProtectedRoute children={<AddressesPage />} />
      } />
      <Route path="/my_account/edit_billing_address" element={
        <ProtectedRoute children={<EditBillingAddressPage />} />
      } />
      <Route path="/my_account/edit_shipping_address" element={
        <ProtectedRoute children={<EditShippingAddressPage />} />
      } />
      <Route path="/my_account/orders" element={
        <ProtectedRoute children={<OrdersPage />} />
      } />
      <Route path="/my_account/orders/:id" element={
        <ProtectedRoute children={<OrderPage />} />
      } />

      <Route path="/branches" element={<BranchesPage />}></Route>
      <Route path="/about" element={<AboutPage />}></Route>
      <Route path="/cart" element={<CartPage />}></Route>
      <Route path="/checkout" element={<CheckoutPage />}></Route>
      <Route path="/repair" element={<RepairServicesPage />}></Route>
      <Route path="/wishlist" element={<WishlistPage />}></Route>
      <Route path="/products" element={<ProductsPage/>}></Route>
      <Route path="/product/:id" element={<ProductPage/>}></Route>
      <Route path="/terms_and_conditions" element={<TermsAndConditionsPage/>}></Route>
      <Route path="/return_policy" element={<ReturnPolicyPage/>}></Route>
    </Route>
  )
);

// export const Routes = () => {
//   return <>
//     <Route path="/"><HomePage /></Route>
//     <Route path="/auth"><AuthPage /></Route>

//     {/* My Account */}
//     <Route path="/my_account"><DashboardPage /></Route>
//     <Route path="/my_account/dashboard"><DashboardPage /></Route>
//     <Route path="/my_account/details"><AccountDetailsPage /></Route>
//     <Route path="/my_account/addresses"><AddressesPage /></Route>
//     <Route path="/my_account/edit_billing_address"><EditBillingAddressPage /></Route>

//     <Route path="/commonpage"><CommonPage /></Route>
//     <Route path="/branchpage"><BranchPage /></Route>
//     <Route path="/prodsinglepage"><ProdSinglePage /></Route>
//     <Route path="/prodlistpage"><ProdListPage /></Route>
//     <Route path="/aboutpage"><AboutPage /></Route>
//     <Route path="/cart"><CartPage /></Route>
//     <Route path="/checkout"><CheckoutPage /></Route>
//     <Route path="/repairservicespage"><RepairServicesPage /></Route>
//     <Route path="/wishlist"><WishlistPage /></Route>



//     <Route path="/products/:search?">{(params) => {
//       var data = {}
//       try {
//         if (params.search) {
//           data = hexDecode(params.search as string);
//           data = JSON.parse(data as string);
//         }
//       } catch {
//         data = {};
//       }
//       return <ProductsPage payload={data} />;
//     }}</Route>

//     <Route path="/single_product/:id?">{(params) => {
//       if (!params.id) {
//         return <div>NOT FOUND</div>
//       }
//       return <SingleProductPage id={params.id} />;
//     }}</Route>

//     <Route path="/my_account/orders/:id?">
//       {(params) => {
//         console.log(params);
//         if (params.id) {
//           return <OrderPage id={params.id}/>
//         }
//         return <OrdersPage />
//       }}
//     </Route>



//     <Route path="/users/:name">{(params) => <div>Hello, {params.name}!</div>}</Route>
//     <Route path="/about">About Us</Route>

//   </>
// }
