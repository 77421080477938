import { Api } from './Api';
import { ADD_TO_CART_URL, CALCULATE_URL, CART_URL, CHECKOUT_STATUS_URL, CHECKOUT_URL, PAYMENT_LINK_URL, REMOVE_FROM_CART_URL, UPDATE_CART_URL } from '../../configs';
import { AddToCartPayload } from '../payload/add_to_cart';
import { CalculatePayload } from '../payload/calculate';
import { CheckoutPayload } from '../payload/checkout';


export class CheckoutApis {
  static checkout(payload: CheckoutPayload): ReturnType<typeof Api.post> {
    return Api.post(CHECKOUT_URL, payload);
  }

  static paymentLink(order_id: string): ReturnType<typeof Api.post> {
    return Api.post(PAYMENT_LINK_URL, { "order_id": order_id });
  }

  static checkoutStatus(payment_id: string): ReturnType<typeof Api.get> {
    return Api.get(CHECKOUT_STATUS_URL, { "payment_id": payment_id });
  }
}