import { ActionType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { AppActions } from '../../../modules/app/actions';
import { CartApis } from '../../../server/api/Cart';
import { RootState } from '../../../store/rootState';
import { CartActions } from './actions';


function* cart({ }: ActionType<typeof CartActions.cart.request>): SagaIterator {
  try {
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    if (!login || !user) {
      yield* put(CartActions.cart.success()) as any;
      return;
    }

    // Fetching wishlistå products
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.cart) as any;
    console.log('cart server response: ');
    console.log(data);
    yield* put(CartActions.cart.success()) as any;

    var cartList = data.data.map((item) => {
      return { 'quantity': item.quantity, 'product': item }
    });
    yield* put(AppActions.cart.set(cartList)) as any;
  } catch (error) {
    yield* put(CartActions.cart.fail('')) as any;
  }
}

function* add({ payload }: ActionType<typeof CartActions.add.request>): SagaIterator {
  try {
    console.log('add to cart payload');
    console.log(payload);
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    if (!login || !user) {
      yield* put(AppActions.cart.add(payload)) as any;
      yield* put(CartActions.add.success()) as any;
      return;
    }
    console.log('insuring token');
    // Fetching wishlistå products
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.add, { quantity: payload.quantity, item_id: payload.product.id }) as any;
    console.log('add to cart response: ');
    console.log(data);
    yield* put(AppActions.cart.add(payload)) as any;
    yield* put(CartActions.add.success()) as any;
  } catch (error) {
    yield* put(CartActions.cart.fail('')) as any;
  }
}

function* remove({ payload }: ActionType<typeof CartActions.remove.request>): SagaIterator {
  try {
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    if (!login || !user) {
      yield* put(AppActions.cart.remove({ id: payload.product_id })) as any;
      yield* put(CartActions.remove.success()) as any;
      return;
    }

    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.remove, { item_id: payload.product_id }) as any;
    console.log('remove from cart response: ');
    console.log(data);
    yield* put(AppActions.cart.remove({ id: payload.product_id })) as any;
    yield* put(CartActions.remove.success()) as any;
  } catch (error) {
    yield* put(CartActions.cart.fail('')) as any;
  }
}

function* update({ payload }: ActionType<typeof CartActions.update.request>): SagaIterator {
  try {
    console.log('update cart payload')
    console.log(payload)
    const { login, user } = yield* select((state: RootState) => state.app) as any;

    if (!login || !user) {
      yield* put(AppActions.cart.update(payload)) as any;
      yield* put(CartActions.update.success()) as any;
      return;
    }

    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.update, { cart: payload.map(item => {
      return { quantity: item.quantity, item_id: item.product.id }
    })}) as any;

    console.log('update cart response:');
    console.log(data);

    yield* put(AppActions.cart.update(payload)) as any;
    yield* put(CartActions.update.success()) as any;
    // yield* put(AppActions.insureToken()) as any;
    // const { data } = yield* call(CartApis.remove, {item_id: payload.product_id}) as any;
    // console.log('remove from cart response: ');
    // console.log(data);
    // yield* put(CartActions.cart.success({
    //   products: data.data
    // })) as any;
    // yield* put(AppActions.cart.remove({id: payload.product_id})) as any;
  } catch (error) {
    yield* put(CartActions.cart.fail('')) as any;
  }
}

function* calculate({ payload }: ActionType<typeof CartActions.calculate.request>): SagaIterator {
  try {
    // Fetching wishlistå products
    console.log('calculate payload:');
    console.log(payload);
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CartApis.calculate, payload) as any;
    console.log('calculate response: ');
    console.log(data);
    yield* put(CartActions.cart.success()) as any;
    yield* put(CartActions.calculate.success(data.data)) as any;
  } catch (error) {
    yield* put(CartActions.cart.fail('')) as any;
  }
}

export function* watchCart(): SagaIterator {
  yield* takeLatest(CartActions.cart.request, cart) as any;
  yield* takeLatest(CartActions.add.request, add) as any;
  yield* takeLatest(CartActions.remove.request, remove) as any;
  yield* takeLatest(CartActions.update.request, update) as any;
  yield* takeLatest(CartActions.calculate.request, calculate) as any;
}
