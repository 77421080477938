import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export const useLanguage = () => {
    const { i18n } = useTranslation();
    const [en, setEn] = useState(i18n.language == 'en');
    const [ar, setAr] = useState(i18n.language == 'ar');


    useEffect(() => {
        if(i18n.language) {
            setEn(i18n.language == 'en')
            setAr(i18n.language == 'ar')
        }
    }, [i18n.language])

    return [en, ar]
}