import { useSelector } from "react-redux";
import { useEffect } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BasePage } from "../base/base";
import { Header } from "../../components/header/headers";
import { Footer } from "../../components/footer";
import { t } from "i18next";


export const NotFoundPage = () => {
    return (
        <BasePage>
        <div className="d-flex flex-column">
            <Header/>
            <div className="container font40 p-4 pb-5 d-flex justify-content-center mb-5 mt-5">{t('404')}</div>
            <Footer />
        </div>
        </BasePage>
    )
}