import { createAction } from 'deox';
import { resolveConfig } from 'prettier';
import { UserModel } from '../../server/models/user';
import { CategoryModel } from '../../server/models/category';
import { ProductModel } from '../../server/models/product';
import { CartModel } from '../../server/models/cart';


export class AppActions {
  static setUser = createAction('app/set_user', resolve => (payload: UserModel) => resolve(payload))
  static login = createAction('app/login')
  static logout = createAction('app/logout')
  static insureToken = createAction('app/insure_token')

  static categories = {
    request: createAction('app/categories_request'),
    success: createAction('app/categories_success', resolve => (payload: { categories: CategoryModel[] }) =>
      resolve(payload),
    ),
    fail: createAction('app/categories_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static cart = {
    add: createAction('app/cart_add', resolve => (payload: CartModel) =>
      resolve(payload),
    ),
    update: createAction('app/cart_update', resolve => (payload: CartModel[]) =>
      resolve(payload),
    ),
    list: createAction('app/cart_list'),
    remove: createAction('app/cart_remove', resolve => (payload: { id: string }) =>
      resolve(payload),
    ),
    clear: createAction('app/cart_clear'),
    set: createAction('app/cart_set', resolve => (payload: CartModel[]) =>
      resolve(payload),
    ),
  };

  static wishlist = {
    add: createAction('app/wishlist_add', resolve => (payload: ProductModel) =>
      resolve(payload),
    ),
    list: createAction('app/wishlist_list'),
    remove: createAction('app/wishlist_remove', resolve => (payload: { id: string }) =>
      resolve(payload),
    ),
    clear: createAction('app/wishlist_clear'),
    set: createAction('app/wishlist_set', resolve => (payload: ProductModel[]) =>
      resolve(payload),
    ),
  };
}