import { useSelector } from "react-redux";
import { useAction } from "../../../utils/hooks/action";
import { RootState } from "../../../store/rootState";
import { CheckoutActions } from "../module/actions";
import { useTranslation } from "react-i18next";

export const ShippingAddress = () => {
    const { shippingAddress: shipping_address } = useSelector((state: RootState) => state.checkout);
    const setShipping = useAction(CheckoutActions.shippingAddress.set);
    const { t, i18n } = useTranslation();

    return <div>
        <div className="col-1">
            <div className="woocommerce-shipping-fields">
            <h3>{t('shipping_details')}</h3>

                <div className="woocommerce-shipping-fields__field-wrapper">
                    <p
                        className="form-row form-row-first validate-required"
                        id="shipping_first_name_field"
                        data-priority={10}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_first_name" className="" >
                        {t('first_name')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                        <input
                                type="text"
                                className="input-text "
                                name="shipping_dirst_name"
                                id="shipping_first_name"
                                placeholder=""
                                defaultValue=""
                                autoComplete="family-name"
                                value={shipping_address.first_name}
                                onChange={(e) => {
                                    setShipping({...shipping_address, first_name: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row form-row-last validate-required"
                        id="shipping_last_name_field"
                        data-priority={20}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_last_name" className="">
                        {t('last_name')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="text"
                                className="input-text "
                                name="shipping_last_name"
                                id="shipping_last_name"
                                placeholder=""
                                defaultValue=""
                                autoComplete="family-name"
                                value={shipping_address.last_name}
                                onChange={(e) => {
                                    setShipping({...shipping_address, last_name: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row form-row-wide"
                        id="shipping_company_field"
                        data-priority={30}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_company" className="">
                        {t('company_name')}&nbsp;
                            <span className="optional">({t('optional')})</span>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="text"
                                className="input-text "
                                name="shipping_company"
                                id="shipping_company"
                                placeholder=""
                                defaultValue=""
                                autoComplete="organization"
                                value={shipping_address.company}
                                onChange={(e) => {
                                    setShipping({...shipping_address, company: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row form-row-wide address-field update_totals_on_change validate-required"
                        id="shipping_country_field"
                        data-priority={40}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_country" className="">
                        {t('Country / Region')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <strong>{t('United Arab Emirates')}</strong>
                            <input
                                type="hidden"
                                name="shipping_country"
                                id="shipping_country"
                                defaultValue="AE"
                                autoComplete="country"
                                className="country_to_state"

                            />
                        </span>
                    </p>
                    <p
                        className="form-row address-field validate-required form-row-wide"
                        id="shipping_address_1_field"
                        data-priority={50}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_address_1" className="">
                        {t('street_address')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="text"
                                className="input-text "
                                name="shipping_address_1"
                                id="shipping_address_1"
                                placeholder={`${t('House number and street name')}`}
                                defaultValue=""
                                autoComplete="address-line1"
                                data-placeholder={`${t('House number and street name')}`}
                                value={shipping_address.apartment}
                                onChange={(e) => {
                                    setShipping({...shipping_address, apartment: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row address-field form-row-wide"
                        id="shipping_address_2_field"
                        data-priority={60}
                        style={{textAlign: 'start'}}
                    >
                        <label
                            htmlFor="shipping_address_2"
                            className="screen-reader-text"
                        >
                            {t("Apartment, suite, unit, etc.")}&nbsp;
                            <span className="optional">({t('optional')})</span>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="text"
                                className="input-text "
                                name="shipping_address_2"
                                id="shipping_address_2"
                                placeholder={`${t('Apartment, suite, unit, etc')}`}
                                defaultValue=""
                                autoComplete="address-line2"
                                data-placeholder={`${t('Apartment, suite, unit, etc')}`}
                                value={shipping_address.street}
                                onChange={(e) => {
                                    setShipping({...shipping_address, street: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row address-field validate-required form-row-wide"
                        id="shipping_city_field"
                        data-priority={70}
                        data-o_class="form-row form-row-wide address-field validate-required"
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_city" className="">
                            {t('Town / City')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="text"
                                className="input-text "
                                name="shipping_city"
                                id="shipping_city"
                                placeholder=""
                                defaultValue=""
                                autoComplete="address-level2"
                                value={shipping_address.city}
                                onChange={(e) => {
                                    setShipping({...shipping_address, city: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row address-field validate-state form-row-wide"
                        id="shipping_state_field"
                        data-priority={80}
                        data-o_class="form-row form-row-wide address-field validate-state"
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_state" className="">
                        {t('State / County')}&nbsp;
                            <span className="optional">({t('optional')})</span>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="text"
                                className="input-text "
                                defaultValue=""
                                placeholder=""
                                name="shipping_state"
                                id="shipping_state"
                                autoComplete="address-level1"
                                data-input-classes=""
                                value={shipping_address.state}
                                onChange={(e) => {
                                    setShipping({...shipping_address, state: e.target.value})
                                }}
                            />
                        </span>
                    </p>

                    <p
                        className="form-row form-row-wide validate-required validate-phone"
                        id="shipping_phone_field"
                        data-priority={100}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_phone" className="">
                        {t('phone')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="tel"
                                className="input-text "
                                name="shipping_phone"
                                id="shipping_phone"
                                placeholder=""
                                defaultValue=""
                                autoComplete="tel"
                                value={shipping_address.phone}
                                onChange={(e) => {
                                    setShipping({...shipping_address, phone: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                    <p
                        className="form-row form-row-wide validate-required validate-email woocommerce-validated"
                        id="shipping_email_field"
                        data-priority={110}
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="shipping_email" className="">
                        {t('email_address')}&nbsp;
                            <abbr className="required" title="required">
                                *
                            </abbr>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <input
                                type="email"
                                className="input-text "
                                name="shipping_email"
                                id="shipping_email"
                                placeholder=""
                                defaultValue=""
                                autoComplete="email username"
                                value={shipping_address.email}
                                onChange={(e) => {
                                    setShipping({...shipping_address, email: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                </div>
            </div>

        </div>
        <div className="col-2">
            <div className="woocommerce-additional-fields">
                <div className="woocommerce-additional-fields__field-wrapper">
                    <p
                        className="form-row notes"
                        id="order_comments_field"
                        data-priority=""
                        style={{textAlign: 'start'}}
                    >
                        <label htmlFor="order_comments" className="">
                            {t('Order notes')}&nbsp;
                            <span className="optional">({t('optional')})</span>
                        </label>
                        <span className="woocommerce-input-wrapper">
                            <textarea
                                name="order_comments"
                                className="input-text "
                                id="order_comments"
                                placeholder={`${t('Notes about your order, e.g. special notes for delivery')}`}
                                rows={2}
                                cols={5}
                                value={shipping_address.note}
                                onChange={(e) => {
                                    setShipping({...shipping_address, note: e.target.value})
                                }}
                            />
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
}