import { Topbar } from '../../components/topbar'
import { SearchMenu } from '../../components/header/search_menu'
import { Footer } from '../../components/footer'
import { RepairServicesComponent } from './components/repair_services'
import { Header } from '../../components/header/headers'
import { Base } from '../base/components/base'
import { BasePage } from '../base/base'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { useAction } from '../../utils/hooks/action'
import { RepairActions } from './module/actions'
import { useEffect, useState } from 'react'

export const RepairServicesPage = () => {
  // Translation
  const { t, i18n } = useTranslation();
  const { loading, repair_details, errors, success } = useSelector((state: RootState) => state.repair);

  const setRepairDetails = useAction(RepairActions.repair.set);
  const setLoading = useAction(RepairActions.loading.set);
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  // Convert file into base64
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb((reader.result as any).split(',')[1])
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl as any)

    // Convert file into base64
    getBase64(selectedFile, (base64: any) => {
      console.log('image base64')
      console.log(base64)
      setRepairDetails({ ...repair_details, image: base64 });
    })

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }

  return (
    <BasePage>
      <div className="off-canvas-wrapper">
        <div id="page" className="hfeed site">
          <Header />
          <div id="content" className="site-content" >
            <div className="container">
              <nav className="woocommerce-breadcrumb"><a href="https://hiphonetelecom.com">{t('home')}</a><span className="delimiter"><i className="fa fa-angle-right"></i></span>{t('repair')}</nav><div className="site-content-inner"></div>
              <div className="site-content-inner">
                <div id="primary" className="content-area primary-large">
                  <main id="main" className="site-main">
                    <article
                      id="post-28666"
                      className="post-28666 page type-page status-publish hentry"
                    >
                      <header className="entry-header">
                        <h1 className="entry-title">{t('repair')}</h1>
                      </header>

                      {success && <div className="woocommerce-info" style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'green' }}>
                        {t('repair_request_submitted_successfully')}
                      </div>}

                      {errors.length > 0 && <div className="woocommerce-form-coupon-toggle">
                        <div className="woocommerce-info" style={{ display: 'flex', flexDirection: 'column' }}>
                          {[...errors.map((error, index) => <div>{error}</div>
                          )]}
                        </div>
                      </div>}

                      <div className='row column-same-height'>
                        <div className='col-12 col-lg-4'>
                          <div className='container h-100' style={{ border: '3px solid red', borderRadius: 42 }}>
                            <div className='d-flex justify-content-center align-items-center flex-column p-4 h-100'>
                              {preview && <img src={preview} style={{ width: 250 }} />}
                              <input disabled={loading} id='image_input' onChange={onSelectFile} type='file' accept="image/*" className='m-4 bold' style={{ width: 200, borderRadius: 10, backgroundColor: 'red', color: 'white', visibility: 'hidden' }} />
                              <button onClick={() => {
                                var element = document.getElementById('image_input');
                                element?.click();
                              }} className='m-4 bold' style={{ width: 200, borderRadius: 10, backgroundColor: 'red', color: 'white' }}>UPLOAD IMAGE</button>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-lg-8'>
                          <RepairServicesComponent />
                        </div>
                      </div>
                    </article>
                  </main>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </BasePage>
  )
}