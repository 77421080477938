import { Footer } from '../../components/footer'
import { BranchComponent } from './components/branches'
import { Header } from '../../components/header/headers'
import { BasePage } from '../base/base'
import { useEffect } from 'react'

export const BranchesPage = () => {
  
  return (
    <BasePage>
      <div className="off-canvas-wrapper">
        <div id="page" className="hfeed site">
          <Header />
          <BranchComponent />
          <Footer />
        </div>
      </div>
    </BasePage>
  )
}