import { createReducer } from 'deox';
import { produce } from 'immer';
import {
  AppActions,
} from './actions';
import { AppTypes } from './types';
import { Api } from '../../server/api';

const initialState: AppTypes = {
  user: undefined,
  login: false,
  categories: [],
  cart: [],
  wishlist: []
};

export const appReducer = createReducer(initialState, handleAction => [
  // Login
  handleAction(AppActions.login, (state, payload) =>
    produce(state, draft => {
      draft.login = true;
    }),
  ),
  // Logout
  handleAction(AppActions.logout, (state, payload) =>
    produce(state, draft => {
      draft.user = undefined;
      draft.login = false;
      draft.cart = [];
      draft.wishlist = [];
      Api.clearAuthToken();
    }),
  ),
  // Set user details
  handleAction(AppActions.setUser, (state, { payload }) =>
    produce(state, draft => {
      draft.user = payload;
    }),
  ),
  // Categories
  handleAction(AppActions.categories.request, (state) =>
    produce(state, draft => {
      console.log('request categories redux triggered')
    }),
  ),
  handleAction(AppActions.categories.success, (state, { payload }) =>
    produce(state, draft => {
      draft.categories = payload.categories;
    }),
  ),
  handleAction(AppActions.categories.fail, (state) =>
    produce(state, draft => {
    }),
  ),

  // Cart
  handleAction(AppActions.cart.add, (state, { payload }) =>
    produce(state, draft => {
      // Search for the item
      var _cart = (state.cart ?? []).slice(0)
      console.log('cart cache')
      console.log(_cart)
      var _index: number = -1;
      _cart.forEach((cart, index) => {
        console.log(`${payload.product.id} - ${cart.product.id} : ${payload.product.id === cart.product.id}`)
        if (payload.product.id === cart.product.id) {
          _index = index;
        }
      })


      if (_index > -1) {
        // Update
        _cart[_index] = payload
      } else {
        // Add
        console.log(`push index: ${_index}`)
        _cart.push(payload)
      }

      draft.cart = _cart;
    }),
  ),

  handleAction(AppActions.cart.update, (state, { payload }) =>
    produce(state, draft => {
      var _cart = (state.cart ?? []).slice(0)
      payload.forEach(item => {
        // Search for the item
        console.log('cart cache')
        console.log(_cart)
        var _index: number = -1;
        _cart.forEach((cart, index) => {
          console.log(`${item.product.id} - ${cart.product.id} : ${item.product.id === cart.product.id}`)
          if (item.product.id === cart.product.id) {
            _index = index;
          }
        })


        if (_index > -1) {
          // Update
          _cart[_index] = item
        } else {
          // Add
          console.log(`push index: ${_index}`)
          _cart.push(item)
        }
      })

      draft.cart = _cart;
    }),
  ),

  handleAction(AppActions.cart.remove, (state, { payload }) =>
    produce(state, draft => {
      // Search for the item
      var _cart = (state.cart ?? []).slice(0)
      console.log('cart cache')
      console.log(_cart)
      var _index: number | undefined = undefined;
      _cart.forEach((cart, index) => {
        console.log(`${payload.id} - ${cart.product.id} : ${payload.id === cart.product.id}`)
        if (payload.id == cart.product.id) {
          _index = index;
        }
      })

      console.log(`remove index: ${_index}`)

      _cart = _cart.filter(item => (payload.id != item.product.id));

      draft.cart = _cart;
    }),
  ),

  handleAction(AppActions.cart.clear, (state) =>
    produce(state, draft => {
      draft.cart = [];
    }),
  ),
  handleAction(AppActions.cart.set, (state, { payload }) =>
    produce(state, draft => {
      console.log('setting cart: ')
      console.log(payload)
      draft.cart = payload;
    }),
  ),

  // Wishlist
  handleAction(AppActions.wishlist.add, (state, { payload }) =>
    produce(state, draft => {
      // Search for the item
      var _wishlist = (state.wishlist ?? []).slice(0)
      console.log('wishlist cache')
      console.log(_wishlist)
      var _index: number = -1;
      _wishlist.forEach((item, index) => {
        console.log(`${payload.id} - ${item.id} : ${payload.id === item.id}`)
        if (payload.id === item.id) {
          _index = index;
        }
      })


      if (_index > -1) {
        // Update
        _wishlist[_index] = payload
      } else {
        // Add
        console.log(`push index: ${_index}`)
        _wishlist.push(payload)
      }

      draft.wishlist = _wishlist;
    }),
  ),

  handleAction(AppActions.wishlist.remove, (state, { payload }) =>
    produce(state, draft => {
      // Search for the item
      var _wishlist = (state.wishlist ?? []).slice(0)
      console.log('cart cache')
      console.log(_wishlist)
      var _index: number | undefined = undefined;
      _wishlist.forEach((item, index) => {
        console.log(`${payload.id} - ${item.id} : ${payload.id === item.id}`)
        if (payload.id == item.id) {
          _index = index;
        }
      })

      console.log(`remove index: ${_index}`)

      _wishlist = _wishlist.filter(item => (payload.id != item.id));

      draft.wishlist = _wishlist;
    }),
  ),

  handleAction(AppActions.wishlist.clear, (state) =>
    produce(state, draft => {
      draft.wishlist = [];
    }),
  ),
  handleAction(AppActions.wishlist.set, (state, { payload }) =>
    produce(state, draft => {
      draft.wishlist = payload;
    }),
  ),

  handleAction(AppActions.insureToken, (state) =>
    produce(state, draft => {
      try {
        if(draft.user) {
          var token = draft.user.token
          console.log(`token: ${token}`)
          if (token) {
            Api.setAuthToken(token);
          }
        }
      } catch (exception) {
        console.log('insureToken exception')
        console.log(exception)
      }
    }),
  ),
]);