import { useEffect } from "react";
import { useAction } from "../../../utils/hooks/action";
import { AppActions } from "../../../modules/app/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const MyAccountMenu = () => {
    // Navigation
    const location = useLocation();
    const navigate = useNavigate();
    // Translation
    const { t, i18n } = useTranslation();
    // State
    const { login } = useSelector((state: RootState) => state.app);
    const logoutAction = useAction(AppActions.logout);

    useEffect(() => {
        if (location) {
            console.log(`location: ${location}`);
        }
    }, [location])

    useEffect(() => {
        if (!login) {
            navigate('/auth', { replace: true })
        }
    }, [login])

    // const Seperator = () => {
    //     return <hr className="m-1" style={{ color: '#CCCCCC' }} />;
    // }

    // const Item = (props: { title: string, selected: boolean, onClick: Function }) => {
    //     return (
    //         <div onClick={() => {
    //             props.onClick();
    //         }}
    //             className="ps-1 pe-1 pt-2 pb-2 font14"
    //             style={{ textAlign: 'start', fontWeight: props.selected ? 'bold' : '300' }}>{props.title}</div>
    //     );
    // }

    return <nav className="woocommerce-MyAccount-navigation me-4" style={{ textAlign: 'start' }}>
        <ul>
            <li 
            onClick={() => {
                if (location.pathname != '/my_account/dashboard') {
                    navigate('/my_account/dashboard', { replace: true })
                }
            }}
            className={"pointer woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard" + ((location.pathname == '/my_account/dashboard' || location.pathname == '/my_account') ? ' is-active' : '')}>
                <a>{t('dashboard')}</a>
            </li>
            <li 
            onClick={() => {
                if (location.pathname != '/my_account/orders') {
                    navigate('/my_account/orders', { replace: true })
                }
            }}
            className={"pointer woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--orders" + ((location.pathname == '/my_account/orders') ? ' is-active' : '')}>
                <a>{t('orders')}</a>
            </li>
            <li 
            onClick={() => {
                if (location.pathname != '/my_account/addresses') {
                    navigate('/my_account/addresses', { replace: true })
                }
            }}
            className={"pointer woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-address"  + ((location.pathname == '/my_account/addresses') ? ' is-active' : '')}>
                <a>{t('addresses')}</a>
            </li>
            {/* <li 
            onClick={() => {
                if (location.pathname != '/my_account/details') {
                    navigate('/my_account/details', { replace: true })
                }
            }}
            className={"pointer woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account" + ((location.pathname == '/my_account/details') ? ' is-active' : '')} >
                <a>Account details</a>
            </li> */}
            <li onClick={() => {
                logoutAction();
            }} 
            className="pointer woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--customer-logout">
                <a>{t('logout')}</a>
            </li>
        </ul>
    </nav>

    // return (
    //     <div>
    //         <Seperator />
    //         <Item title="Dashboard" onClick={() => {
    //             if (location != '/my_account/dashboard' && location != '/my_account') {
    //                 setLocation('/my_account/dashboard', { replace: true })
    //             }
    //         }} selected={location == '/my_account/dashboard' || location == '/my_account'} />
    //         <Seperator />
    //         <Item title="Orders" onClick={() => {
    //             if (location != '/my_account/orders') {
    //                 setLocation('/my_account/orders', { replace: true })
    //             }
    //         }} selected={location == '/my_account/orders'} />
    //         <Seperator />
    //         <Item title="Addresses" onClick={() => {
    //             if (location != '/my_account/addresses') {
    //                 setLocation('/my_account/addresses', { replace: true })
    //             }
    //         }} selected={location == '/my_account/addresses'} />
    //         <Seperator />
    //         <Item title="Account Details" onClick={() => {
    //             if (location != '/my_account/details') {
    //                 setLocation('/my_account/details', { replace: true })
    //             }
    //         }} selected={location == '/my_account/details'} />
    //         <Seperator />
    //         <Item title="Change Password" onClick={() => {
    //             //setLocation('my_account/change_password')
    //         }} selected={false} />
    //         <Seperator />
    //         <Item title="Logout" onClick={() => {
    //             // Trigger logout acction
    //             logoutAction();

    //         }} selected={false} />
    //         <Seperator />
    //     </div>
    // )
}