import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import { useAction } from "../../../utils/hooks/action";
import { HeaderActions } from "../../../modules/header/actions";
import { useLanguage } from "../../../utils/hooks/language";

export var Cover = () => {
    const { sidenav } = useSelector((state: RootState) => state.header);
    const closeSidenavAction = useAction(HeaderActions.sidenav.close);
    const [en, ar] = useLanguage();
    
    return <div onClick={() => {
        closeSidenavAction();
    }} className={`cover-${en ? 'ltr' : 'rtl'}-${sidenav ? 'opened' : 'closed'}`}></div>
}