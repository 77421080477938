import { Topbar } from '../../components/topbar'
import { SearchMenu } from '../../components/header/search_menu'
import { Footer } from '../../components/footer'

import { WishListComponent } from './components/wishlist'
import { useAction } from '../../utils/hooks/action'
import { useSelector } from 'react-redux'
import { WishlistActions } from './module/actions'
import { RootState } from '../../store/rootState'
import { useEffect } from 'react'
import { AppActions } from '../../modules/app/actions'
import { Header } from '../../components/header/headers'
import { Base } from '../base/components/base'
import { BasePage } from '../base/base'


export const WishlistPage = () => {
  const { loadingWishlist } = useSelector((state: RootState) => state.wishlist);
  const requestWishlistAction = useAction(WishlistActions.wishlist.request);

  useEffect(() => {
    requestWishlistAction();
  }, [])

  if(loadingWishlist) {
    return <></>
  }

  return (
    <BasePage>
    <Header/>
    <div className="off-canvas-wrapper">
      <WishListComponent />
    </div>
    <Footer />
    </BasePage>
  )
}