import { ActionType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { AddressesApis } from '../../../server/api/Addresses';
import { CartApis } from '../../../server/api/Cart';
import { CheckoutApis } from '../../../server/api/Checkout';
import { CheckoutPayload } from '../../../server/payload/checkout';
import { RootState } from '../../../store/rootState';
import { RepairActions } from './actions';
import { AppActions } from '../../../modules/app/actions';
import { RepairApis } from '../../../server/api/Repair';


function* repair({  }: ActionType<typeof RepairActions.repair.request>): SagaIterator {
  try {
    const { repair_details, errors } = yield* select((state: RootState) => state.repair) as any;
    if(errors.length > 0) {
      yield* put(RepairActions.repair.fail(undefined)) as any;
      return;
    }
    console.log('repair payload');
    console.log(repair);

    // Insure token is set
    yield* put(AppActions.insureToken()) as any;

    const { data } = yield* call(RepairApis.repair, repair_details) as any;
    console.log('repair response');
    console.log(data);
    yield* put(RepairActions.repair.success()) as any;
  } catch (error) {
    console.log('repair error');
    console.log(error);
    yield* put(RepairActions.repair.fail('Something went wrong while trying to submit request.')) as any;
  }
}


export function* watchRepair(): SagaIterator {
  yield* takeLatest(RepairActions.repair.request, repair) as any;
}