import { useSelector } from 'react-redux';
import { WishlistItem } from './wishlist_item';
import { RootState } from '../../../store/rootState';
import { useAction } from '../../../utils/hooks/action';
import { AppActions } from '../../../modules/app/actions';
import { WishlistActions } from '../module/actions';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const WishListComponent = () => {
  const { wishlist } = useSelector((state: RootState) => state.app);
  const { t, i18n } = useTranslation();

  return (
    <div id="content" className="site-content" tabIndex={-1}>
      <div className="container">
        <nav className="woocommerce-breadcrumb">
          <a>{t('home')}</a>
          <span className="delimiter">
            <i className="fa fa-angle-right" />
          </span>
          {t('wishlist')}
        </nav>
        <div className="site-content-inner">
          <div id="primary" className="content-area primary-large">
            <main id="main" className="site-main">
              <article
                id="post-3855"
                className="post-3855 page type-page status-publish hentry"
              >
                <div className="entry-content">
                  <form
                    id="yith-wcwl-form"
                    action="https://hiphonetelecom.com/wishlist-2/?wishlist-action=view&wishlist_id=5HY9VRN41GD6"
                    method="post"
                    className="woocommerce yith-wcwl-form wishlist-fragment"
                    data-fragment-options='{"per_page":5,"current_page":1,"pagination":"no","wishlist_id":239,"action_params":false,"no_interactions":false,"layout":"","is_default":true,"is_custom_list":true,"wishlist_token":"5HY9VRN41GD6","is_private":false,"count":2,"page_title":"My wishlist","default_wishlsit_title":"My wishlist","page_links":false,"is_user_logged_in":true,"is_user_owner":true,"can_user_edit_title":true,"show_price":true,"show_dateadded":true,"show_stock_status":false,"show_add_to_cart":true,"show_remove_product":true,"add_to_cart_text":"Add to cart","show_ask_estimate_button":false,"ask_estimate_url":"","price_excl_tax":false,"show_cb":false,"show_quantity":false,"show_variation":true,"show_price_variations":false,"show_update":false,"enable_drag_n_drop":false,"enable_add_all_to_cart":false,"move_to_another_wishlist":false,"repeat_remove_button":false,"show_last_column":true,"heading_icon":"<i class=\"fa fa-heart-o\"><\/i>","share_enabled":true,"template_part":"view","additional_info":false,"available_multi_wishlist":false,"form_action":"https:\/\/hiphonetelecom.com\/wishlist-2\/?wishlist-action=view&wishlist_id=5HY9VRN41GD6","ajax_loading":false,"item":"wishlist"}'
                  >
                    {/* TITLE */}
                    <div className="wishlist-title-container">
                      <div className="wishlist-title wishlist-title-with-form">
                        <h2>{t('my_wishlist')}</h2>{" "}

                      </div>

                    </div>
                    {/* WISHLIST TABLE */}
                    <table
                      className="shop_table cart wishlist_table wishlist_view traditional responsive   "
                      data-pagination="no"
                      data-per-page={5}
                      data-page={1}
                      data-id={239}
                      data-token="5HY9VRN41GD6"
                    >
                      <thead>
                        <tr>
                          <th className="product-remove">
                            <span className="nobr"></span>
                          </th>
                          <th className="product-thumbnail" />
                          <th className="product-name">
                            <span className="nobr">{t('product_name')} </span>
                          </th>
                          <th className="product-price">
                            <span className="nobr">{t('unit_price')}</span>
                          </th>
                          <th className="product-add-to-cart">
                            <span className="nobr"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="wishlist-items-wrapper">
                        {[...(wishlist ?? []).map((product, index) =>
                          <WishlistItem
                            product={product}
                            addedDate='-'
                          />
                        )]
                        }

                      </tbody>
                    </table>


                  </form>
                </div>
                {/* .entry-content */}
              </article>
              {/* #post-## */}
            </main>
            {/* #main */}
          </div>
          {/* #primary */}
        </div>{" "}
        { wishlist.length == 0 && <td className="container">{t('no_products_added_to_the_wishlist')}</td>}
      </div>
      {/* .col-full */}
    </div>

  )
}