import MenuSvgImage from '../../assets/menu.svg'
import MenuBlackSvgImage from '../../assets/menu-black.svg'
import SearchSvgImage from '../../assets/search.svg'
import CartSvgImage from '../../assets/cart.svg'
import AccountSvgImage from '../../assets/account.svg'
import { RootState } from '../../store/rootState';
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { SearchMenu } from './search_menu'
import { useAction } from '../../utils/hooks/action'
import { HeaderActions } from '../../modules/header/actions'
import { FreeDeliverySection } from './free_delivery'
import { SearchOptions } from './search_options'
import { useTranslation } from 'react-i18next'
import { SearchMenuNew } from './search_menu_new'
import { useNavigate, createSearchParams } from 'react-router-dom';


export const Header = () => {
    // Navigation
    const navigate = useNavigate();
    const { login } = useSelector((state: RootState) => state.app);
    const { search } = useSelector((state: RootState) => state.header);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    
    const resetSearchProductsAction = useAction(HeaderActions.products.success);
    const productsAction = useAction(HeaderActions.products.request);

    useEffect(() => {
        window.addEventListener('click', function (e) {
            var productsOnTop = (document as any).getElementById('productsOnTop');
            if (productsOnTop) {
                if (productsOnTop.contains(e.target)) {
                } else {
                    resetSearchProductsAction([])
                }
            }
        });
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                productsAction(search)
            } else {
                resetSearchProductsAction([]);
            }
            // Send Axios request here
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    

    var Brand = (props: { title: string }) => {
        return <div onClick={() => {
            navigate({
                pathname: `/products`, search: `?${createSearchParams({
                    'attrs': props.title
                })}`
            })
        }} className='ps-2 pe-2 header-branch'>{t(props.title)}</div>
    }


    var BrandSeperator = () => {
        return <div style={{ color: '#E1E7E7' }}>|</div>
    }

    var Brands = () => {
        return <div className='d-none d-lg-block'><div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <Brand title={'apple'}/>
            <BrandSeperator />
            <Brand title={'atouch'}/>
            <BrandSeperator />
            <Brand title={'beats'}/>
            <BrandSeperator />
            <Brand title={'belkin'}/>
            <BrandSeperator />
            <Brand title={'ferrari'}/>
            <BrandSeperator />
            <Brand title={'harrods'}/>
            <BrandSeperator />
            <Brand title={'huawei'}/>
            <BrandSeperator />
            <Brand title={'isafe'}/>
            <BrandSeperator />
            <Brand title={'maestro'}/>
            <BrandSeperator />
            <Brand title={'jbl'}/>
            <BrandSeperator />
            <Brand title={'maserati'}/>
            <BrandSeperator />
            <Brand title={'samsung'}/>
            <BrandSeperator />
            <Brand title={'others'}/>
        </div></div>
    }

    const MobileMenu = () => {
        const { sidenav } = useSelector((state: RootState) => state.header);
        const openSidenavAction = useAction(HeaderActions.sidenav.open);
        const closeSidenavAction = useAction(HeaderActions.sidenav.close);
        const setSearchProductsAction = useAction(HeaderActions.products.success);
        return <div className='hidden-lg-up' style={{
            height: 49,
            background: '#CCCCCC'
        }}>
            <div className="container d-flex align-items-center">
                <img onClick={() => {
                    if(sidenav) {
                        closeSidenavAction();
                        setSearchProductsAction([]);
                      } else {
                        openSidenavAction();
                      }
                }} src={MenuSvgImage} width={28} height={28} />
                
                <div className='d-flex align-items-center w-100' style={{ height: 49, justifyContent: 'space-between' }}>
                    <img onClick={() => {
                        navigate("/")
                    }} className="ms-3" src="https://hiphonetelecom.com/wp-content/uploads/2020/06/logo.png" alt="HiPhone Telecom" style={{ height: 20 }} />

                    <div >
                        <div className="d-flex align-items-center justiry-content-center" style={{ height: 49 }}>
                            <img onClick={() => {
                                if (login) {
                                    navigate("/my_account")
                                } else {
                                    navigate("/auth")
                                }
                            }} className='me-4' src={AccountSvgImage} width={15} height={15} />
                            <img onClick={() => {
                                navigate('/cart')
                            }} className='me-1' src={CartSvgImage} width={15} height={15} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }

    return (
        <div>
            <div>
                {/* Free Delivery */}
                <FreeDeliverySection />

                {/* Divider */}
                {/* <div style={{ borderBottom: '1px solid #f1f1f1f1' }} /> */}

                {/* Menu and Search Section */}
                <SearchMenuNew stick={false} />
                {/* <SearchMenuNew stick={true}/> */}
                <MobileMenu />
                <SearchOptions />

                {(location.pathname == '/' || location.pathname == '/home') && <Brands />}

                {/* Images Slider */}
                {/* <Slideshow images={images}/> */}
                <div className='mt-3' />
            </div>
        </div>
    )
}
