import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';


export class ProductsActions {
  static products = {
    request: createAction('products/products_request', resolve => (payload: ProductsPayload) =>
      resolve(payload),
    ),
    success: createAction('products/products_success', resolve => (payload: { products: ProductModel[], attributes: AttributeModel[], total_count: string }) =>
      resolve(payload),
    ),
    fail: createAction('products/products_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static sort = createAction('products/sort', resolve => (payload: 'latest' | 'lower' | 'higher') => resolve(payload));

  static view = {
    set: createAction('products/view_set', resolve => (payload: string) =>
      resolve(payload),
    )
  };
}