import { useSelector } from "react-redux"
import { Footer } from "../../../components/footer"
import { Header } from "../../../components/header/headers"
import { MyAccountMenu } from "../components/menu"
import { RootState } from "../../../store/rootState"
import { useEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { Base } from "../../base/components/base"
import { BasePage } from "../../base/base"
import { useTranslation } from "react-i18next"

export const DashboardPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.app);
    const { t } = useTranslation();

    useEffect(() => {
        console.log(`user:`)
        console.log(user)
    }, [user])

    return (
        <BasePage>
            <div className="d-flex flex-column">
                <Header />
                <div className="font40 p-4 pb-5 d-flex justify-content-center">{t('my_account')}</div>
                <div className="container  mb-5">
                    <div className="row">
                        <div className="col-lg-3 col-12">
                            <MyAccountMenu />
                        </div>
                        <div className="col-lg-9 col-12 font14 d-flex flex-column justify-content-start align-items-start" style={{ textAlign: 'start' }}>
                            <div>Hello <span className="bold">{user?.username}</span> (not <span className="bold">{user?.username}</span>? <span style={{ color: '#0275d8' }}>Log out</span>)</div>
                            <br />
                            <div>From your account dashboard you can view your <span style={{ color: '#0275d8' }}>recent orders</span>, manage your <span style={{ color: '#0275d8' }}>shipping and billing addresses</span>, and <span style={{ color: '#0275d8' }}>edit your password and account details</span>.</div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </BasePage>
    )
}