import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import {
  BrowserRouter,
  HashRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { ProtectedRoute, router } from "./routes";
import "./i18n/config";
import { AboutPage } from "./pages/about";
import { AuthPage } from "./pages/auth";
import { BranchesPage } from "./pages/branch";
import { CartPage } from "./pages/cart";
import { CheckoutPage } from "./pages/checkout";
import { HomePage } from "./pages/home";
import { AccountDetailsPage } from "./pages/my_account/account_details/account_details";
import { AddressesPage } from "./pages/my_account/addresses/addresses";
import { EditBillingAddressPage } from "./pages/my_account/addresses/edit_billing_address";
import { EditShippingAddressPage } from "./pages/my_account/addresses/edit_shipping_address";
import { DashboardPage } from "./pages/my_account/dashboard/dashboard";
import { OrderPage } from "./pages/my_account/order/order";
import { OrdersPage } from "./pages/my_account/orders/orders";
import { ProductPage } from "./pages/product";
import { ProductsPage } from "./pages/products";
import { RepairServicesPage } from "./pages/repair_services";
import { WishlistPage } from "./pages/wishlist";
import { PersistGate } from "redux-persist/integration/react";
import { CheckoutSuccessPage } from "./pages/checkout/success";
import { CheckoutFailedPage } from "./pages/checkout/failed";
import { ReturnPolicyPage } from "./pages/return_policy";
import { TermsAndConditionsPage } from "./pages/terms_and_conditions";
import { ContactUsPage } from "./pages/contact_us";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter basename="/">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route
              path="/my_account"
              element={<ProtectedRoute children={<DashboardPage />} />}
            ></Route>
            <Route
              path="/my_account/dashboard"
              element={<ProtectedRoute children={<DashboardPage />} />}
            />
            <Route
              path="/my_account/details"
              element={<ProtectedRoute children={<AccountDetailsPage />} />}
            />
            <Route
              path="/my_account/addresses"
              element={<ProtectedRoute children={<AddressesPage />} />}
            />
            <Route
              path="/my_account/edit_billing_address"
              element={<ProtectedRoute children={<EditBillingAddressPage />} />}
            />
            <Route
              path="/my_account/edit_shipping_address"
              element={
                <ProtectedRoute children={<EditShippingAddressPage />} />
              }
            />
            <Route
              path="/my_account/orders"
              element={<ProtectedRoute children={<OrdersPage />} />}
            />
            <Route
              path="/my_account/orders/:id"
              element={<ProtectedRoute children={<OrderPage />} />}
            />

            <Route path="/branches" element={<BranchesPage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/cart" element={<CartPage />}></Route>
            <Route path="/checkout" element={<CheckoutPage />}></Route>
            <Route path="/checkout/success" element={<CheckoutSuccessPage/>}></Route>
            <Route path="/checkout/failed" element={<CheckoutFailedPage/>}></Route>
            <Route path="/repair" element={<RepairServicesPage />}></Route>
            <Route path="/wishlist" element={<WishlistPage />}></Route>
            <Route path="/products" element={<ProductsPage />}></Route>
            <Route path="/product/:id" element={<ProductPage />}></Route>
            <Route path="/terms_and_conditions" element={<TermsAndConditionsPage/>}></Route>
            <Route path="/return_policy" element={<ReturnPolicyPage/>}></Route>
            <Route path="/contact_us" element={<ContactUsPage/>}></Route>
          </Routes>

        </HashRouter>
      </PersistGate>
      {/* <RouterProvider router={router} /> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
