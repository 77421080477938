import { useTranslation } from "react-i18next";

export const TitleWithDivider = (props: {title: String, edit? : boolean, onEdit? : Function}) => {
    const { t, i18n } = useTranslation();
    return (
        <h3 className='divider w-100 pb-3' style={{color: '#333E48', fontWeight: 400}}>
            <div>{props.title}</div>
            { (props.edit ?? false) && <div onClick={() => { {props.onEdit && props.onEdit()} }} className="d-flex font14 mt-2 me-2" style={{alignItems:'center', justifyContent: 'center', color:'#0275d8'}}>{t('edit')}</div>}
        </h3>
    )
}