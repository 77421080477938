import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  HomeActions,
} from './actions';
import { HomeTypes } from './types';
import { log } from 'console';

const initialState: HomeTypes = {
  slider: [],
  flashSales: [],
  bestSellers: [],
  youMayLike: [],
  brands: [],
  loadingSlider: true,
  loadingFlashSales: true,
  loadingBestSellers: true,
  loadingYouMayLike: true,
  loadingBrands: true,
  loadingPrimaryProduct: false,
  primaryProduct: undefined
};

export const homeReducer = createReducer(initialState, handleAction => [
  // Home
  handleAction(HomeActions.slider.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting sliders');
      draft.loadingSlider= true;
    }),
  ),
  handleAction(HomeActions.slider.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingSlider= false;
      draft.slider = payload;
    }),
  ),
  handleAction(HomeActions.slider.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingSlider= false;
    }),
  ),

  // Flash Sales
  handleAction(HomeActions.flashSales.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting sliders');
      draft.loadingFlashSales= true;
    }),
  ),
  handleAction(HomeActions.flashSales.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingFlashSales= false;
      draft.flashSales = payload;
    }),
  ),
  handleAction(HomeActions.flashSales.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingFlashSales= false;
    }),
  ),

  // You may like
  handleAction(HomeActions.youMayLike.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting you may like');
      draft.loadingYouMayLike= true;
    }),
  ),
  handleAction(HomeActions.youMayLike.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingYouMayLike= false;
      draft.youMayLike = payload;
    }),
  ),
  handleAction(HomeActions.youMayLike.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingYouMayLike= false;
    }),
  ),

  // Best Sellers
  handleAction(HomeActions.bestSellers.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting best sellers');
      draft.loadingBestSellers= true;
    }),
  ),
  handleAction(HomeActions.bestSellers.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingBestSellers= false;
      draft.bestSellers = payload;
    }),
  ),
  handleAction(HomeActions.bestSellers.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingBestSellers= false;
    }),
  ),

  // Brands
  handleAction(HomeActions.brands.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting brands sellers');
      draft.loadingBrands= true;
    }),
  ),
  handleAction(HomeActions.brands.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingBrands= false;
      draft.brands = payload;
    }),
  ),
  handleAction(HomeActions.brands.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingBrands= false;
    }),
  ),

  // Primary Product
  handleAction(HomeActions.primaryProduct.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting brands sellers');
      draft.loadingPrimaryProduct= true;
    }),
  ),
  handleAction(HomeActions.primaryProduct.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingPrimaryProduct = false;
      draft.primaryProduct = payload;
    }),
  ),
  handleAction(HomeActions.primaryProduct.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingPrimaryProduct= false;
    }),
  ),
]);
