import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import axios from 'axios';
import { HomeApis } from '../../server/api/Home';
import { AuthApis } from '../../server/api/Auth';
import { OrdersApis } from '../../server/api/Orders';
import { AddressesApis } from '../../server/api/Addresses';
import { AddressesModel } from '../../server/models/addresses';
import { AppActions } from '../app/actions';
import { HeaderActions } from './actions';
import { RootState } from '../../store/rootState';
import { ProductsApis } from '../../server/api/Products';
import { ProductsActions } from '../../pages/products/module/actions';


function* products({ payload }: ActionType<typeof HeaderActions.products.request>): SagaIterator {
  try {
    console.log('products payload');
    console.log(payload);
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    const { searchType } = yield* select((state: RootState) => state.header) as any;
    
    var body;
    if(searchType == 'name') {
      body = { "name": payload }
    } 
    else if(searchType == 'barcode') {
      body = { "barcode": payload }
    }

    // Fetching Products
    const { data } = yield* call(ProductsApis.products, body) as any;
    console.log('products response: ');
    console.log(data);
    yield* put(HeaderActions.products.success(data.data.products)) as any;
  }
  catch (error) {
    console.log('products error: ');
    console.log(error);
  }
}

export function* watchHeader(): SagaIterator {
  yield* takeLatest(HeaderActions.products.request, products) as any;
}
