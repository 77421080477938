import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { PRODUCTS_URL, PRODUCT_URL } from '../../configs';


export class ProductsApis {
  static products(payload): ReturnType<typeof Api.get> {
    const params = new URLSearchParams(payload).toString();
    console.log('params')
    console.log(params)

    return Api.get(PRODUCTS_URL, payload);
  }

  static product(payload): ReturnType<typeof Api.get> {
    return Api.get(PRODUCT_URL, payload);
  }
}
