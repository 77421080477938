import { createAction } from 'deox';
import { CalculateModel } from '../../../server/models/calculate';
import { CartModel } from '../../../server/models/cart';
import { ProductModel } from '../../../server/models/product';
import { CalculatePayload } from '../../../server/payload/calculate';
import { RemoveFromWishlistPayload } from '../../../server/payload/remove_from_wishlist';


export class CartActions {
  static cart = {
    request: createAction('cart/cart_request'),
    success: createAction('cart/cart_success'),
    fail: createAction('cart/cart_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static add = {
    request: createAction('cart/add_request', resolve => (payload: {quantity: number, product: ProductModel}) =>
      resolve(payload),
    ),
    success: createAction('cart/add_success'),
    fail: createAction('cart/add_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static remove = {
    request: createAction('cart/remove_request', resolve => (payload: RemoveFromWishlistPayload) =>
      resolve(payload),
    ), 
    success: createAction('cart/remove_success'),
    fail: createAction('cart/remove_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static update = {
    request: createAction('cart/update_request', resolve => (payload: CartModel[]) =>
      resolve(payload),
    ),
    success: createAction('cart/update_success'),
    fail: createAction('cart/update_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static calculate = {
    request: createAction('cart/calculate_request', resolve => (payload: CalculatePayload) =>
      resolve(payload),
    ),
    success: createAction('cart/calculate_success', resolve => (payload: CalculateModel) =>
    resolve(payload),
    ),
    fail: createAction('cart/calculate_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static localUpdatedCart = {
    set: createAction('cart/local_updated_cart_set', resolve => (payload: {cart: CartModel[], item: CartModel}) =>
      resolve(payload),
    ),
    clear: createAction('cart/local_updated_cart_clear'),
  };

  static coupon = {
    apply: createAction('cart/coupon_apply', resolve => (payload: string) =>
      resolve(payload),
    ),
    clear: createAction('cart/coupon_clear'),
  };
}
