import { CartModel } from "../../../server/models/cart"

export const CheckoutCartItem = (props: { cart: CartModel }) => {
    return (
        <tr className="cart_item">
                            <td className="product-name">
                                {props.cart.product.name_en}&nbsp;&nbsp;{" "}
                                <strong className="product-quantity">
                                    ×&nbsp;{props.cart.quantity}
                                </strong>
                            </td>
                            <td className="product-total">
                                <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                            AED
                                        </span>
                                        &nbsp;{+props.cart.quantity * +props.cart.product.sale_price}
                                    </bdi>
                                </span>
                            </td>
                        </tr>
    )
}