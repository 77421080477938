import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import axios from 'axios';
import { HomeApis } from '../../../server/api/Home';
import { ProductActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';


function* product({ payload }: ActionType<typeof ProductActions.product.request>): SagaIterator {
  try {
    console.log('products saga');
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    const categoriesResponse = yield* call(AppApis.categories) as any;
    console.log('categories response: ');
    console.log(categoriesResponse.data);
    yield* put(AppActions.categories.success({
      categories: categoriesResponse.data.data
    })) as any;

    // Fetching Product
    const { data } = yield* call(ProductsApis.product, payload) as any;
    console.log('product response: ');
    console.log(data);
    yield* put(ProductActions.product.success({
      product: data
    })) as any;
  } catch (error) {
    yield* put(ProductActions.product.fail('')) as any;
  }
}

export function* watchProduct(): SagaIterator {
  yield* takeLatest(ProductActions.product.request, product) as any;
}
