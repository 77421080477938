import { createReducer } from 'deox';
import { produce } from 'immer';
import { WishlistActions } from './actions';
import { WishlistTypes } from './types';

const initialState: WishlistTypes = {
  loadingWishlist: false,
  loadingAddToWishlist: false,
  loadingRemoveFromWishlist: false
};

export const wishlistReducer = createReducer(initialState, handleAction => [
  // Wishlist
  handleAction(WishlistActions.wishlist.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingWishlist = true;
    }),
  ),
  handleAction(WishlistActions.wishlist.success, (state) =>
    produce(state, draft => {
      draft.loadingWishlist = false;
    }),
  ),
  handleAction(WishlistActions.wishlist.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingWishlist = false;
    }),
  ),

  // Add To Wishlist
  handleAction(WishlistActions.add.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingAddToWishlist = true;
    }),
  ),
  handleAction(WishlistActions.add.success, (state) =>
    produce(state, draft => {
      draft.loadingAddToWishlist = false;
    }),
  ),
  handleAction(WishlistActions.add.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingAddToWishlist = false;
    }),
  ),

  // Remove from Wishlist
  handleAction(WishlistActions.remove.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingRemoveFromWishlist = true;
    }),
  ),
  handleAction(WishlistActions.remove.success, (state) =>
    produce(state, draft => {
      draft.loadingRemoveFromWishlist = false;
    }),
  ),
  handleAction(WishlistActions.remove.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingRemoveFromWishlist = false;
    }),
  ),
]);
