import { Footer } from '../../components/footer'
import { Header } from '../../components/header/headers'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootState';
import { useEffect } from 'react';
import { useAction } from '../../utils/hooks/action';
import { CheckoutAddresses } from './components/checkout_addresses';
import { YourOrder } from './components/your_order';
import { CheckoutActions } from './module/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckoutCoupon } from './components/coupon';
import { BasePage } from '../base/base';
import { useTranslation } from 'react-i18next';
import { LoginModal } from './components/login_modal';




export const CheckoutPage = () => {
  // Navigation
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const { cart } = useSelector((state: RootState) => state.app);
  const { coupon } = useSelector((state: RootState) => state.cart);
  const {
    loadingCheckout,
    errors,
    redirect,
    paymentLink,
    loadingAddresses,
    loadingCalculate,
    loadingCart,
  } = useSelector((state: RootState) => state.checkout);
  // Actions
  const calculateAction = useAction(CheckoutActions.calculate.request);
  const redirectAction = useAction(CheckoutActions.redirect);
  const requestCartAction = useAction(CheckoutActions.cart.request);
  const requestAddressesAction = useAction(CheckoutActions.addresses.request);
  const loginModalAction = useAction(CheckoutActions.loginModal);
  const resetAction = useAction(CheckoutActions.reset);
  const { t, i18n } = useTranslation();

  // Request cart
  useEffect(() => {
    resetAction();
    requestCartAction();
    requestAddressesAction();
    loginModalAction(false)
  }, [])

  // Request calculate cart
  useEffect(() => {
    if ((cart ?? []).length > 0) {
      calculateAction({
        coupon: coupon,
        products: cart.map((item) => {
          return { quantity: item.quantity, id: item.product.id }
        })
      });
    }
  }, [cart])

  // Redirect to payment link
  useEffect(() => {
    if (redirect) {
      redirectAction(false)
      if (paymentLink) {
        window.location.replace(paymentLink);
      } else {
        navigate('/my_account/orders')
      }
    }
  }, [redirect])


  useEffect(() => {
    console.log(`loading cart: ${loadingCheckout}`)
  }, [loadingCheckout])

  if(loadingCheckout) {
    return <div className='d-flex justify-content-center align-items-center w-100 h-100'>
      <div>Redirecting...</div>
    </div>
  }

  if (loadingAddresses || loadingCalculate || loadingCart) {
    return <></>
  }
  

  return (
    <BasePage>
    <div className="off-canvas-wrapper" style={{ textAlign: 'start' }}>
      <Header />
      <div id="page" className="hfeed site">
        <div id="content" className="site-content" >
          <div className="container">

            <div id="content" className="site-content" tabIndex={-1}>
              {(cart.length == 0) && <div className='container'>
                <nav className="woocommerce-breadcrumb">
                  <a href="https://hiphonetelecom.com">{t('home')}</a>
                  <span className="delimiter">
                    <i className="fa fa-angle-right" />
                  </span>
                  {t('checkout')}
                </nav>
                <div className="woocommerce-form-coupon-toggle"><div className="woocommerce-info" style={{ display: 'flex', flexDirection: 'column' }}>Checkout is not available while your cart is empty.</div></div>
                <p className="cart-empty woocommerce-info">{t('your_cart_is_currently_empty')}.</p>
              </div>
              }
              {
                (cart.length > 0) && <div className="container">
                  <nav className="woocommerce-breadcrumb">
                    <a href="https://hiphonetelecom.com">{t('home')}</a>
                    <span className="delimiter">
                      <i className="fa fa-angle-right" />
                    </span>
                    {t('checkout')}
                  </nav>
                  <div className="site-content-inner">
                    <div id="primary" className="content-area primary-large">
                      <main id="main" className="site-main">
                        <article
                          id="post-3135"
                          className="post-3135 page type-page status-publish hentry"
                        >
                          <header className="entry-header">
                            <h1 className="entry-title">{t('checkout')}</h1>
                          </header>
                          {/* .entry-header */}
                          <div className="entry-content">
                            <div className="woocommerce">
                              <div className="woocommerce-notices-wrapper" />
                              {/* <div className="woocommerce-form-login-toggle">
                                <div className="woocommerce-info">
                                  Returning customer?{" "}
                                  <a href="#" className="showlogin">
                                    Click here to login
                                  </a>
                                </div>
                              </div> */}
                              {/* <form
                                            className="woocommerce-form woocommerce-form-login login"
                                        >
                                            <p className="before-login-text">
                                                Welcome back! Sign in to your account.
                                            </p>
                                            <p>
                                                If you have shopped with us before, please enter your
                                                details below. If you are a new customer, please proceed to
                                                the Billing section.
                                            </p>
                                            <p className="form-row form-row-first">
                                                <label htmlFor="username">
                                                    Username or email&nbsp;<span className="required">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="input-text"
                                                    name="username"
                                                    id="username"
                                                    autoComplete="username"
                                                />
                                            </p>
                                            <p className="form-row form-row-last">
                                                <label htmlFor="password">
                                                    Password&nbsp;<span className="required">*</span>
                                                </label>
                                                <input
                                                    className="input-text"
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                />
                                            </p>
                                            <div className="clear" />
                                            <p className="form-row">
                                                <label className="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                                    <input
                                                        className="woocommerce-form__input woocommerce-form__input-checkbox"
                                                        name="rememberme"
                                                        type="checkbox"
                                                        id="rememberme"
                                                        defaultValue="forever"
                                                    />{" "}
                                                    <span>Remember me</span>
                                                </label>
                                                <input
                                                    type="hidden"
                                                    id="woocommerce-login-nonce"
                                                    name="woocommerce-login-nonce"
                                                    defaultValue="c1683d0260"
                                                />
                                                <input
                                                    type="hidden"
                                                    name="_wp_http_referer"
                                                    defaultValue="/checkout-2/"
                                                />{" "}
                                                <input
                                                    type="hidden"
                                                    name="redirect"
                                                    defaultValue="https://hiphonetelecom.com/checkout-2/"
                                                />
                                                <button
                                                    type="submit"
                                                    className="woocommerce-button button woocommerce-form-login__submit"
                                                    name="login"
                                                    value="Login"
                                                >
                                                    Login
                                                </button>
                                            </p>
                                            <p className="lost_password">
                                                <a href="https://hiphonetelecom.com/my-account-2/lost-password/">
                                                    Lost your password?
                                                </a>
                                            </p>
                                            <div className="clear" />
                                        </form> */}
                              {errors.length > 0 && <div className="woocommerce-form-coupon-toggle">
                                <div className="woocommerce-info" style={{ display: 'flex', flexDirection: 'column' }}>
                                  {[...errors.map((error, index) => <div>{error}</div>
                                  )]}
                                </div>
                              </div>}


                              <CheckoutCoupon/>

                              <form className="checkout woocommerce-checkout">
                                <CheckoutAddresses />
                                <h3 id="order_review_heading">{t('your_order')}</h3>
                                <YourOrder />
                              </form>
                            </div>
                          </div>
                          {/* .entry-content */}
                        </article>
                        {/* #post-## */}
                      </main>
                      {/* #main */}
                    </div>
                    {/* #primary */}
                  </div>
                </div>}
              {/* .col-full */}
            </div>
          </div>
        </div>
        {/* #colophon */}
      </div>
      
      <Footer />
    </div>

    <LoginModal/>
    </BasePage>
  )
}