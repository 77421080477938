import { useTranslation } from "react-i18next";
import { Carousel } from "../../../components/repair-services/carousel";
import { useSelector } from "react-redux";
import { RepairActions } from "../module/actions";
import { RootState } from "../../../store/rootState";
import { useAction } from "../../../utils/hooks/action";
import { useEffect, useState } from "react";
export const RepairServicesComponent = () => {
  // Translation
  const { t, i18n } = useTranslation();
  // States
  const { loading, repair_details, errors, success } = useSelector((state: RootState) => state.repair);
  // Actions
  const setRepairDetails = useAction(RepairActions.repair.set);
  const requestRepair = useAction(RepairActions.repair.request);

  useEffect(() => {
    onSelectService();
    onSelectDeviceManufactor();
    onSelectEmirate();
  }, [])

  function onSelectService() {
    var element = document.getElementById("service_select");
    var value = (element as any).options[(element as any).selectedIndex].text;
    setRepairDetails({ ...repair_details, service: value })
  }

  function onSelectDeviceManufactor() {
    var element = document.getElementById("manufactor_select");
    var value = (element as any).options[(element as any).selectedIndex].text;
    setRepairDetails({ ...repair_details, device_manufacture: value })
  }

  function onSelectEmirate() {
    var element = document.getElementById("emirate_select");
    var value = (element as any).options[(element as any).selectedIndex].text;
    setRepairDetails({ ...repair_details, emirate: value })
  }

  function submit() {
    console.log(repair_details.image);
    requestRepair();
  }

  useEffect(() => {
    if (errors.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [errors, success])


  return (
    <div className="container">
      <div
        role="form"
        className="wpcf7"
        id="wpcf7-f28664-p28666-o1"
        dir="ltr"
      >
        <div className="screen-reader-response">
          <p
            role="status"
            aria-live="polite"
            aria-atomic="true"
          />{" "}
          <ul />
        </div>
        <div
          className="wpcf7-form init"
          data-status="init"
        >

          {/* First name and last name */}
          <div className="service_format">
            <div className="row mb-3">
              <div className="col-lg-6">
                <label htmlFor="FirstName">{t('first_name')}</label>
                <br />
                <span className="wpcf7-form-control-wrap FirstName">
                  <input
                    disabled={loading}
                    type="text"
                    name="FirstName"
                    defaultValue=""
                    size={40}
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    id="FirstName"
                    value={repair_details.first_name}
                    onChange={(event) => {
                      setRepairDetails({ ...repair_details, first_name: event.target.value })
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
              <div className="col-lg-6">
                <label htmlFor="LastName">{t('last_name')}</label>
                <br />
                <span className="wpcf7-form-control-wrap LastName">
                  <input
                    disabled={loading}
                    type="text"
                    name="LastName"
                    defaultValue=""
                    size={40}
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    id="LastName"
                    value={repair_details.last_name}
                    onChange={(event) => {
                      setRepairDetails({ ...repair_details, last_name: event.target.value })
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
            </div>

            {/* Email and phone */}
            <div className="row mb-3">
              <div className="col-lg-6">
                <label htmlFor="Email">{t('email_address')}</label>
                <br />
                <span className="wpcf7-form-control-wrap Email">
                  <input
                    disabled={loading}
                    type="email"
                    name="Email"
                    defaultValue=""
                    size={40}
                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                    id="Email"
                    value={repair_details.email}
                    onChange={(event) => {
                      setRepairDetails({ ...repair_details, email: event.target.value })
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
              <div className="col-lg-6">
                <label htmlFor="Phone">{t('phone')}</label>
                <br />
                <span className="wpcf7-form-control-wrap Email">
                  <input
                    disabled={loading}
                    type="email"
                    name="Phone"
                    defaultValue=""
                    size={40}
                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                    id="Phone"
                    value={repair_details.phone}
                    onChange={(event) => {
                      setRepairDetails({ ...repair_details, phone: event.target.value })
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
            </div>

            {/* Manufactor and model */}
            <div className="row mb-3">
              <div className="col-lg-6">
                <label htmlFor="DeviceManufacturer">
                  {t('device_manufacture')}
                </label>
                <br />
                <span className="wpcf7-form-control-wrap device_manufacturer">
                  <select
                    disabled={loading}
                    id="manufactor_select"
                    name="device_manufacturer"
                    className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control"
                    aria-required="true"
                    aria-invalid="false"
                    onChange={(e) => {
                      onSelectDeviceManufactor();
                    }}
                    style={{ border: '1px solid #dddddd', height: 48 }}
                  >
                    <option value="Apple">Apple</option>
                    <option value="Samsung">Samsung</option>
                    <option value="OnePlus">OnePlus</option>
                    <option value="VIVO">VIVO</option>
                    <option value="HONOR">HONOR</option>
                    <option value="HTC">HTC</option>
                    <option value="Sony">Sony</option>
                    <option value="LG">LG</option>
                    <option value="Blackberry">
                      Blackberry
                    </option>
                    <option value="XTOUCH">XTOUCH</option>
                    <option value="Motorola">Motorola</option>
                    <option value="Nokia">Nokia</option>
                    <option value="Xiaomi">Xiaomi</option>
                    <option value="OPPO">OPPO</option>
                    <option value="HUAWEI">HUAWEI</option>
                    <option value="Others">Others</option>
                  </select>
                </span>
              </div>
              <div className="col-lg-6">
                <div
                  data-id="apple_models"
                  data-orig_data_id="apple_models"
                  data-class="wpcf7cf_group"
                  style={{ height: "auto" }}
                  className="wpcf7cf-hidden"
                >
                  <label htmlFor="apple_models">{t('model')}</label>
                  <br />
                  <span className="wpcf7-form-control-wrap apple_models">
                    <input
                      disabled={loading}
                      type="text"
                      name="LastName"
                      defaultValue=""
                      size={40}
                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                      id="model"
                      value={repair_details.model}
                      onChange={(event) => {
                        setRepairDetails({ ...repair_details, model: event.target.value })
                      }}
                      aria-required="true"
                      aria-invalid="false"
                    />
                  </span>
                  <p />
                </div>


              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-12">
                <label htmlFor="service">
                  {t('service_required')}
                </label>
                <br />
                <span className="wpcf7-form-control-wrap service">
                  <select
                    disabled={loading}
                    name="service"
                    className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control"
                    id="service_select"
                    aria-required="true"
                    onChange={(e) => {
                      onSelectService();
                    }}
                    style={{ border: '1px solid #dddddd', height: 48 }}
                    aria-invalid="false"
                  >
                    <option value="HOME & ONSITE REPAIR SERVICE">
                      HOME &amp; ONSITE REPAIR SERVICE
                    </option>
                    <option value="REPAIR ALL TYPES OF MOBILES & LAPTOPS">
                      REPAIR ALL TYPES OF MOBILES &amp; LAPTOPS
                    </option>
                    <option value="SMART WATCHES & TABLETS REPAIR SERVICE">
                      SMART WATCHES &amp; TABLETS REPAIR SERVICE
                    </option>
                    <option value="DATA BACKUP & RECOVERY SERVICE">
                      DATA BACKUP &amp; RECOVERY SERVICE
                    </option>
                    <option value="FULL PHONE BODY SCRATCH PROTECTION">
                      FULL PHONE BODY SCRATCH PROTECTION
                    </option>
                    <option value="BODY & COVERS CUSTOMIZATION SERVICE">
                      BODY &amp; COVERS CUSTOMIZATION SERVICE
                    </option>
                    <option value="TRADE-IN SERVICE">
                      TRADE-IN SERVICE
                    </option>
                    <option value="REFURBISHMENT SRVICE">
                      REFURBISHMENT SRVICE
                    </option>
                  </select>
                </span>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-6">
                <label htmlFor="address">{t('address')}</label>
                <br />
                <span className="wpcf7-form-control-wrap address">
                  <input
                    disabled={loading}
                    type="text"
                    name="address"
                    defaultValue=""
                    size={40}
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    id="address"
                    value={repair_details.address}
                    onChange={(event) => {
                      setRepairDetails({ ...repair_details, address: event.target.value })
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
              <div className="col-lg-6">
                <label htmlFor="emirate">{t('emirate')}</label>
                <br />
                <span className="wpcf7-form-control-wrap emirate">
                  <select
                    disabled={loading}
                    name="emirate"
                    className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control"
                    id="emirate_select"
                    aria-required="true"
                    aria-invalid="false"
                    onChange={(e) => {
                      onSelectEmirate();
                    }}
                    style={{ border: '1px solid #dddddd', height: 48 }}
                  >
                    <option value="Dubai">Dubai</option>
                    <option value="Abu Dhabi">Abu Dhabi</option>
                    <option value="Sharjah">Sharjah</option>
                    <option value="Ajman">Ajman</option>
                    <option value="Al Ain">Al Ain</option>
                    <option value="Ras Al Khaimah">
                      Ras Al Khaimah
                    </option>
                    <option value="Fujairah">Fujairah</option>
                  </select>
                </span>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-12">
                <label htmlFor="additional_notes">
                  {t('additional_notes')}
                </label>
                <br />
                <span className="wpcf7-form-control-wrap additional_notes">
                  <textarea
                    disabled={loading}
                    name="additional_notes"
                    cols={40}
                    rows={10}
                    style={{ border: '1px solid #dddddd' }}
                    className="wpcf7-form-control wpcf7-textarea form-control"
                    id="additional_notes"
                    aria-invalid="false"
                    defaultValue={""}
                    value={repair_details.note}
                    onChange={(event) => {
                      setRepairDetails({ ...repair_details, note: event.target.value })
                    }}
                  />
                </span>
              </div>
            </div>

            <div className="container row mb-3">
              {!loading && <button
                style={{ width: 300 }}
                onClick={() => {
                  submit();
                }}
              >{t('submit_request')}</button>}
              {loading && <div style={{ width: 25, height: 25 }} className="loader"></div>}
            </div>
          </div>
          <div
            className="wpcf7-response-output"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  )
}