import { useEffect, useState } from "react";

export const InputField = (props: {title: String, value?: string, required: boolean, disabled?: boolean, isPassword?: boolean, onChange: (value: string) => void}) => {
    const [value, setValue] = useState<string>();

    useEffect(() => {
        if(props.value) {
            setValue(props.value);
        }
    }, [props.value])

    function handleChange(event: any) {
        setValue(event.target.value)
        props.onChange(event.target.value)
      }

    return (
        <p className="mt-2 d-flex flex-column align-items-start w-100">
            <label className='font14 bold'>{props.title}<div style={{display: props.required ? 'inline' : 'none'}}>&nbsp;<span className="required">*</span></div></label>
            <input disabled={props.disabled} type={props.isPassword ? "password" : "text"} className="form-field mt-3" name="username" id="username" value={value} onChange={handleChange}/>
        </p>
    )
}