import { ProductModel } from "../../../server/models/product";
import HeartSvgImage from '../../../assets/heart.svg'
import HeartRedSvgImage from '../../../assets/heart-red.svg'
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAction } from "../../../utils/hooks/action";
import { WishlistActions } from "../../wishlist/module/actions";
import { RootState } from "../../../store/rootState";
import { useSelector } from "react-redux";

export const YouMayAlsoLikeItem = (props: { product: ProductModel }) => {
    // Navigation
    const navigate = useNavigate();
    // Actions
    const addToWishlistRequestAction = useAction(WishlistActions.add.request);
    const removeFromWishlistRequestAction = useAction(WishlistActions.remove.request);
    // States
    const { wishlist } = useSelector((state: RootState) => state.app);
    const { loadingAddToWishlist, loadingRemoveFromWishlist } = useSelector((state: RootState) => state.wishlist);
    const [loading, setLoading] = useState(false);
    
    const { t, i18n } = useTranslation();
    const discount = (product: ProductModel) => {
      return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
    }

    useEffect(() => {
        if (!loadingAddToWishlist && !loadingRemoveFromWishlist) {
            setLoading(false);
        }
        console.log(loadingAddToWishlist)
        console.log(loadingRemoveFromWishlist)
    }, [loadingAddToWishlist, loadingRemoveFromWishlist]);

    const inWishlist = () => {
        return (wishlist ?? []).filter((item) => item.id == props.product.id).length > 0
    }
  
    return (
        <li className="pointer" onClick={() => {
            navigate(`/product/${props.product.id}`);
        }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <img style={{ width: '100%', height: '100%', backgroundColor:'#D9D9D9', border: '1px solid #D9D9D9' }} className='mb-2' src={props.product.image} />
                <div className='mb-2 text-container'>{props.product.name_en}</div>
                <div className="d-flex justify-content-between algn-items-center mt-2 w-100">
                    {/* <span className="onsale" style={{ borderRadius: 6 }}>
                    -<span className="percentage">{10}%</span>
                  </span> */}
                    { (discount(props.product) > 0) && <div className='d-flex justify-content-center align-items-center ps-2 pe-2' style={{ backgroundColor: '#4AB72C', height: 25, borderRadius: 6, fontWeight: 'bold', fontSize: 13, color: 'white' }}>-<div >{discount(props.product)}%</div></div>}
                    {( (discount(props.product) > 0)) && <img onClick={(e) => {
                        if (inWishlist()) {
                            setLoading(true);
                            removeFromWishlistRequestAction(props.product);
                        }
                        else {
                            setLoading(true);
                            addToWishlistRequestAction(props.product);
                        }
                        e.stopPropagation();
                    }} className="pointer" width={24} height={24} src={inWishlist() ? HeartRedSvgImage : HeartSvgImage} />}
                    {/* {(loading && discount(props.product) > 0) && <div style={{ width: 20, height: 20 }} className="loader"></div>} */}
                </div>

                <div className={`d-flex justify-content-${(discount(props.product) > 0) ? 'start' : 'between'} align-items-end w-100`} style={{ fontWeight: 400, textAlign: 'start', fontSize: 12, color: 'black', height: '100%' }}>
                    <div className='bold' style={{ fontSize: 14 }}>
                        {t('aed')} {(+props.product.sale_price).toFixed(2)}
                    </div>
                    <div className='ms-2 me-2' />
                    { (discount(props.product) > 0) && <del style={{ fontSize: 13, color: 'black' }}>
                        <div style={{ color: 'black' }}>{t('aed')} {(+props.product.regular_price).toFixed(2)}</div>
                    </del>}

                    
                    {( (discount(props.product) == 0)) && <img onClick={(e) => {
                        if (inWishlist()) {
                            setLoading(true);
                            removeFromWishlistRequestAction(props.product);
                        }
                        else {
                            setLoading(true);
                            addToWishlistRequestAction(props.product);
                        }
                        e.stopPropagation();
                    }} className="pointer ms-2 me-2" width={24} height={24} src={inWishlist() ? HeartRedSvgImage : HeartSvgImage} />}
                    {/* {(loading && discount(props.product) == 0) && <div style={{ width: 20, height: 20 }} className="loader ms-2 me-2"></div>} */}
                </div>
            </div>
        </li>
    );
}