import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { HeaderActions } from '../../modules/header/actions';
import { RootState } from '../../store/rootState';
import { useAction } from '../../utils/hooks/action';
import { Sidenav } from './components/sidenav';
import { Cover } from './components/cover';
import { Base } from './components/base';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../utils/hooks/language';

export const BasePage = ({children}) => {
    const { t, i18n } = useTranslation();
    const [ en, ar ] = useLanguage();
    
    useEffect(() => {
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
     }, [i18n, i18n.language]);

     useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
     
    return <Suspense>
        <div style={{direction: en ? 'ltr' : 'rtl'}}>
        <Base>{children}</Base>
        <Cover/>
        <Sidenav/>
    </div>
    </Suspense>
}