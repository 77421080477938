import { BillingAddress } from "./billing_address";
import { ShippingAddress } from "./shipping_address";

export const CheckoutAddresses = (props: {}) => {


    return (
        <div className="col2-set" id="customer_details">
            {/* shipping Address */}
            <BillingAddress/>

            {/* Shipping Address */}
            <ShippingAddress/>
        </div>
    )
}


