import { useState } from "react";
import Carousel from "react-bootstrap/esm/Carousel";

export const HomeCategory = (props: { index: number, id?: string, name: string, image: string, onClick: (id, name) => any }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  return (
    <div key={`home-category-${props.index}`} className='col-lg-3 col-sm-6 col-6 mb-4'>
      <div>
        <div onClick={() => {
          props.onClick(props.id, props.name);
        }} className='item-shadow mb-3 p-1 d-flex justify-content-center align-items-center pointer' style={{ fontSize: 20, backgroundColor: '#EB212E', alignItems: 'center', fontWeight: 'bold', textAlign: 'center', color: 'white' }}>{props.name}</div>
        <div onClick={() => {
          props.onClick(props.id, props.name);
        }}
          className="pointer item-shadow">
          <img style={{ backgroundColor: '#D9D9D9' }} height={'100%'} width={'100%'} src={props.image} />
        </div>

      </div>
    </div>
  );
}