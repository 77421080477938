import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { SliderModel } from "../../../server/models/slider";


export function Slideshow(props: { images: SliderModel[] }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  const SliderImage = (props: { index: number, image: SliderModel }) => {
    return (
      <div key={`slider-image-${index}`} className="vc_item" style={{ width: "20%", height: "355px" }}>
        <div className="vc_inner"><a href={props.image.url} target="_blank">
          <img width="2560" height="776p" src={props.image.image} className="attachment-full" alt="" loading="lazy" sizes="(max-width: 1170px) 100vw, 1170px" />
        </a>
        </div></div>
    );
  }

  useEffect(() => {
    (document.getElementById('right') as any).click()
  }, [])

  return (
    <div className="container wpb_images_carousel wpb_content_element vc_clearfix" style={{direction: 'ltr'}}>
      <div className="wpb_wrapper">
        <div id="vc_images-carousel-1-1685511102" data-ride="vc_carousel" data-wrap="true" data-interval="2500" data-auto-height="yes" data-mode="horizontal" data-partial="false" data-per-view="1" data-hide-on-end="false" className="vc_slide vc_images_carousel vc_build w-100">
          <ol className="vc_carousel-indicators">
          {[...props.images.map((image, index) =>
                <li key={`slider-image-indicator-${index}`} data-target="#vc_images-carousel-1-1685511102" data-slide-to={index.toString()} className={index == 0 ? "vc_active" : ""}></li>
              )]
              }
            
          </ol>
          <div className="vc_carousel-inner"><div className="vc_carousel-slideline" style={{ width: '7020px' }}>
            <div className="vc_carousel-slideline-inner" style={{ left: '-33.3333%' }}>

              {[...props.images.map((image, index) =>
                <SliderImage image={image} index={index} />
              )]
              }
            </div>
          </div>
          </div>
          <a id="left" className="vc_left vc_carousel-control" href="#vc_images-carousel-1-1685511102" data-slide="prev"><span className="icon-prev"></span></a>
          <a id="right" className="vc_right vc_carousel-control" href="#vc_images-carousel-1-1685511102" data-slide="next"><span className="icon-next"></span></a>
        </div>
      </div>
    </div>
  );

  // return (
  //   <Carousel className="container-lg" activeIndex={index} onSelect={handleSelect} style={{ height: 350 }}>
  //     {[...props.images.map((image, index) =>
  //       <Carousel.Item>
  //         <img
  //           style={{ height: 350, overflow: 'hidden', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', objectFit: 'cover' }}
  //           className="d-block w-100"
  //           src={image.image}
  //           alt="Second slide"
  //         />
  //       </Carousel.Item>)]
  //     }
  //   </Carousel>
  // );
}

