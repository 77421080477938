export const AboutComponent = () => {
  return (
    <div>
      <div>
        <div>
          <div>
            <div>
              <div className="container">
                <img
                  className="w-100"
                  src="https://hiphonetelecom.com/wp-content/uploads/2020/06/ddd-1.jpg?fit=1170%2C512&ssl=1"
                />

                <div className="d-flex align-items-center justify-content-start mt-4">
                  <img
                    width={262}
                    height={184}
                    style={{ width: 262, height: 184 }}
                    src="https://hiphonetelecom.com/wp-content/uploads/2020/06/01.jpg?fit=262%2C184&ssl=1"
                    alt=""
                    loading="lazy"
                    className="me-4"
                  />
                  <img
                    width={262}
                    height={184}
                    style={{ width: 262, height: 184 }}
                    src="https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/06/02.jpg?fit=262%2C184&ssl=1"
                    alt=""
                    loading="lazy"
                    className="me-4"
                  />
                  <img
                    width={262}
                    height={184}
                    src="https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/06/03.jpg?fit=262%2C184&ssl=1"
                    alt=""
                    loading="lazy"
                    className="me-4"
                  />
                </div>

                <div className="wpb_wrapper mt-4">
                  <h2 style={{ textAlign: "left" }}>
                    <strong>Our Achievements</strong>
                  </h2>
                  <ul>
                    <li style={{ textAlign: "left" }}>
                      HiPHONE TELECOM has won the Best Retailer of the Year/2012
                      by HH. Mohammed Bin Rashid Awards for Young Business
                      Leaders.
                    </li>
                    <li style={{ textAlign: "left" }}>
                      Dubai SME 100 (for the year 2012) Rank #51.
                    </li>
                    <li style={{ textAlign: "left" }}>
                      Dubai SME 100 (for the year 2013) Rank #24.
                    </li>
                    <li style={{ textAlign: "left" }}>
                      Arabia Fast Growth 500 + Turkey
                    </li>
                    <li style={{ textAlign: "left" }}>
                      In 2006, HiPHONE TELECOM were awarded second place at the
                      Techmart Awards for Nokia Accessory Display, and in 2003
                      were awarded a certificate of appreciation by Zayed
                      University for their efforts in organizing the first UAE
                      Products exhibition.
                    </li>
                    <li style={{ textAlign: "left" }}>
                      ISO 9001 2015 certified
                    </li>
                  </ul>
                </div>

                <div style={{backgroundColor: '#F5F5F5'}} className="mt-5">
                <div className="d-flex flex-column justify-content-center align-items-center p-5">
                              <h3
                                className="highlight"
                                style={{ textAlign: "center" }}
                              >
                                <strong>OUR SUCCESS STORY</strong>
                              </h3>
                              <p
                                style={{
                                  textAlign: "center",
                                  maxWidth: 800,
                                  margin: "auto",
                                }}
                              >
                                HiPHONE TELECOM believe that mobile phone
                                accessories are no longer just accessories, but
                                rather are a necessity and direction of
                                prevailing among consumers today, which requires
                                to keep up to date with peoples tastes and
                                desires to acquire the latest fashion in terms
                                of mobile phone accessories.
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                  maxWidth: 800,
                                  margin: "auto",
                                }}
                              >
                                It was 1994 when Mr. Saif Abdulla Al Ali decided
                                that there was a better way to trade &amp;
                                distribute mobile devices and accessories, which
                                was a small shop selling mobile accessories to
                                become a company that today owns and operates
                                over 33 retail shops across UAE, where other
                                expansion plan within GCC in the pipeline.
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                  maxWidth: 800,
                                  margin: "auto",
                                }}
                              >
                                Today we become “One destination for all telecom
                                needs”, with extremely high-end brand standards
                                &amp; outstanding customer services helps to
                                occupy leading position among telecom business.
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                  maxWidth: 800,
                                  margin: "auto",
                                }}
                              >
                                As per our successful experience we believe that
                                “People should never oversee the small steps as
                                they for sure will lead to huge success”.
                              </p>
                            </div>
                </div>

                {/* Life is all about changes... Always be creative to be
                      ready for competitions */}
                {/* .entry-header */}
                <div className="">
                  
                  <div className="vc_row-full-width vc_clearfix" />
                  <div className="vc_row wpb_row vc_row-fluid inner-sm">
                    <div className="text-boxes wpb_column vc_column_container vc_col-sm-7">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_row wpb_row vc_inner vc_row-fluid inner-bottom-xs">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <h3 className="highlight">OUR VISION</h3>
                                      <ul>
                                        <li>
                                          To be the favorite telecom destination
                                          across the region.
                                        </li>
                                        <li>
                                          To build high brand reputation through
                                          complete telecom services &amp;
                                          unmatched customer service.
                                        </li>
                                        <li>
                                          To provide prestigious service for
                                          mobility products and solutions that
                                          exchange the consumers perception
                                          &amp; enjoy the latest technologies
                                          with maximum shopping satisfaction.
                                        </li>
                                        <li>
                                          Innovate to be the best destination
                                          for all telecommunication needs.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                      <h3 className="highlight">OUR GOALS</h3>
                                      <ul>
                                        <li>
                                          To convoy the latest launches
                                          technologies within the telecom
                                          industry.
                                        </li>
                                        <li>
                                          To become the favorite &amp; preferred
                                          shopping destination for all telecom
                                          needs.
                                        </li>
                                        <li>
                                          To extend our value services with
                                          premium customers satisfactions
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb-accordion wpb_column vc_column_container vc_col-sm-5">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <h3 className="highlight">OUR MISSION</h3>
                              <ul>
                                <li>
                                  To combine leading customer service with high
                                  products quality.
                                </li>
                                <li>
                                  To inspire optimism and happiness: We believe
                                  that customer service doesn’t stop at the
                                  level of sale of a product, we seek to
                                  continue providing customers after sales
                                  support, communicate &amp; acknowledge his
                                  shopping experience through our “Loyalty
                                  Program &amp; Social Media Activities”.
                                </li>
                                <li>
                                  We have a simple philosophy: we don’t sell
                                  devices; we offer continuously extreme
                                  customer service with complete telecom
                                  services ensure premium customers
                                  satisfaction.
                                </li>
                                <li>
                                  To provide telecom users the latest
                                  technologies &amp; products launch
                                  implementing the highest retail standards.
                                </li>
                                <li>
                                  To serve our customers with unmatched quality
                                  services
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .entry-content */}
              </div>
              {/* #post-## */}
            </div>
            {/* #main */}
          </div>
          {/* #primary */}
        </div>
      </div>
      {/* .col-full */}
    </div>
  );
};
