import { ProductModel } from "../../../server/models/product";
import HeartSvgImage from '../../../assets/heart.svg'
import { BrandModel } from "../../../server/models/brand";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { UPLOADS_URL } from "../../../configs";

export const Brand = (props: {brand: BrandModel}) => {
  // Navigation
  const navigate = useNavigate();
  
  return (
    <li key={`brand-${props.brand.id}`} onClick={() => {
      navigate({
        pathname: `/products`, search: `?${createSearchParams({
            'attrs': props.brand.brand_name_en
        })}`
    })
    }} 
    className="ms-2 me-2">
      <div>
        <img src={UPLOADS_URL + props.brand.image} />
      </div>
    </li>
  );
}