import { createAction } from 'deox';
import { OrderModel } from '../../server/models/order';
import { AddressesModel } from '../../server/models/addresses';
import { AddressModel } from '../../server/models/address';
import { ProductModel } from '../../server/models/product';


export class HeaderActions {
  static products = {
    request: createAction('header/products_request', resolve => (payload: string) =>
      resolve(payload),
    ),
    success: createAction('header/products_success', resolve => (payload: ProductModel[]) =>
      resolve(payload),
    ),
    fail: createAction('header/products_failure', resolve => (error: any) =>
      resolve(error),
    ),
    clear: createAction('header/products_clear'),
  };

  static sidenav = {
    open: createAction('header/open_sidenav'),
    close: createAction('header/close_sidenav'),
  };

  static search = createAction('header/search', resolve => (payload: string) =>
    resolve(payload),
  );

  static setSearchType = createAction('header/set_search_type', resolve => (payload: 'name' | 'barcode') =>
    resolve(payload),
  );
}
