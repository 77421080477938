import { useEffect } from "react"
import { Footer } from "../../../components/footer"
import { Header } from "../../../components/header/headers"
import { TitleWithDivider } from "../../../components/title_with_divider"
import { MyAccountMenu } from "../components/menu"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/rootState"
import { useAction } from "../../../utils/hooks/action"
import { MyAccountActions } from "../../../modules/my_account/actions"
import { AddressModel } from "../../../server/models/address"
import { useNavigate, useLocation } from 'react-router-dom';
import { Base } from "../../base/components/base"
import { useTranslation } from "react-i18next"

export const AddressesPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // States
    const { addresses } = useSelector((state: RootState) => state.myAccount);
    // Actions
    const addressesAction = useAction(MyAccountActions.addresses.request);
    const { t } = useTranslation();

    useEffect(() => {
        // Fetch addresses
        addressesAction();
    }, [])

    const Address = (props: { address: AddressModel }) => {
        return <address style={{ color: '#6D757C', lineHeight: 2 }}>
            {props.address.first_name ?? '-'} {props.address.last_name ?? ''}<br />
            {props.address.company ?? '-'}<br />
            {props.address.street ?? '-'}<br />
            {props.address.apartment ?? '-'}<br />
            {props.address.city ?? '-'}<br />
            {props.address.state ?? '-'}
        </address>;
    }

    return (
        <Base>
            <div className="d-flex flex-column">
                <Header />
                <div className="font40 p-4 pb-5 d-flex justify-content-center">{t('addresses')}</div>
                <div className="container d-flex mb-5">
                    <div className="row w-100">
                        <div className="col-lg-3 col-sm-12">
                            <MyAccountMenu />
                        </div>
                        <div className="col-lg-9 col-sm-12 font14 container d-flex flex-column justify-content-start align-items-start" style={{ textAlign: 'start' }}>
                            <div>The following addresses will be used on the checkout page by default.</div>
                            <br />
                            <div className="row w-100">
                                <div className="col-lg-5 col-sm-12">
                                    <TitleWithDivider title={'Billing Address'} edit onEdit={() => { navigate('/my_account/edit_billing_address') }} />
                                    {addresses.billing_address && <Address address={addresses.billing_address} />}
                                </div>
                                <div className="col-lg-1" />
                                <div className="col-lg-5 col-sm-12">
                                    <TitleWithDivider title={'Shipping Address'} edit />
                                    {addresses.shipping_address && <Address address={addresses.shipping_address} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Base>
    )
}