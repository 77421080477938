import { SagaIterator } from 'redux-saga';
import './actions';
import { ActionType } from 'deox';
import { AppActions } from './actions';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import { AppApis } from '../../server/api/App';
import { RootState } from '../../store/rootState';


// function* categories({ }: ActionType<typeof AppActions.categories.request>): SagaIterator {
//   try {
//     console.log('categories saga');
//     const { categories } = yield* select((state: RootState) => state.app) as any;
//     console.log('request categories saga triggered')
//     const { data } = yield* call(AppApis.categories) as any;
//       console.log('categories response: ');
//       console.log(data);
//       yield* put(AppActions.categories.success({
//         categories: data.data
//       })) as any;

//   } catch (error) {
//     yield* put(AppActions.categories.fail('')) as any;
//   }
// }

export function* watchApp(): SagaIterator {
  // yield* takeLatest(AppActions.categories.request, categories) as any;
}
