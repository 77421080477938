import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  OrderActions,
} from './actions';
import { OrderTypes } from './types';

const initialState: OrderTypes = {
  loadingOrder: false,
  products: [],
  billingAddress: {
    first_name: '',
    last_name: '',
    company: '',
    country: '',
    street: '',
    address: '',
    title: '',
    apartment: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    note: undefined
  },
  shippingAddress: {
    first_name: '',
    last_name: '',
    company: '',
    country: '',
    street: '',
    address: '',
    title: '',
    apartment: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    note: undefined
  },
  order: {
    id: '',
    date: '',
    total_quantity: '0',
    subtotal: '',
    discount: '',
    delivery_fee: '0',
    item_total: '',
    total: '',
    payment_method: '',
    status: '',
    order_from: '',
    customer_id: '',
    cancelled_by_customer: '',
    note: ''
  }
};

export const orderReducer = createReducer(initialState, handleAction => [
  // Order
  handleAction(OrderActions.order.request, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingOrder = true;
    }),
  ),
  handleAction(OrderActions.order.success, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingOrder = false;
      draft.order = payload.order;
      draft.products = payload.products;
      draft.billingAddress = payload.billingAddress;
      draft.shippingAddress = payload.shippingAddress;
    }),
  ),
  handleAction(OrderActions.order.fail, (state ) =>
    produce(state, draft => {
      draft.loadingOrder = false;
    }),
  ),
  
]);
