import { useSelector } from "react-redux";
import { ProductModel } from "../../../server/models/product";
import { RootState } from "../../../store/rootState";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const Product = (props: { product: ProductModel, onClick: (product: ProductModel) => any, onAddToCart: (product: ProductModel) => any, onAddToWishlist: (product: ProductModel) => any }) => {
    const { wishlist, cart } = useSelector((state: RootState) => state.app);
    const location = useLocation();
    const navigate = useNavigate();
    const product = props.product
    // Translation
    const { t, i18n } = useTranslation();

    const inWishlist = () => {
        return (wishlist ?? []).filter((item) => item.id == product.id).length > 0
    }

    const inCart = () => {
        return (cart ?? []).filter((item) => item.product.id == product.id).length > 0
    }
    //{product!.is_available != 1 && <div className="font14">{t('product_is_not_available')}</div>}

    const discount = (product: ProductModel) => {
        return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
      }

    return (
        <li onClick={() => { props.onClick(props.product) }} className="product type-product post-109059 status-publish first instock product_cat-accessories product_cat-cases-and-covers has-post-thumbnail sale purchasable product-type-simple pointer" style={{ textAlign: 'start' }}>
            <div className="product-outer product-item__outer">
                <div className="product-inner product-item__inner">
                    <div className="product-loop-header product-item__header">
                        <span className="loop-product-categories">
                            {
                            [...product.categories.map((category, index) =>
                                <a rel="tag">
                                    {category.name_en}{', '}
                                </a>)
                                ]
                            }
                        </span>
                        <a className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                            <h2 className="woocommerce-loop-product__title">
                                {product.name_en}
                            </h2>
                            <div className="product-thumbnail product-item__thumbnail">
                                <img
                                    width={300}
                                    height={300}
                                    src={product.image}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt=""
                                    loading="lazy"
                                    style={{backgroundColor:'#D9D9D9'}}
                                />
                            </div>
                        </a>
                    </div>
                    {/* /.product-loop-header */}
                    <div className="product-loop-body product-item__body">

                        {/* Categories #3 */}
                        <span className="loop-product-categories">
                            {[...product.categories.map((category, index) =>
                                <a
                                    href=""
                                    rel="tag"
                                >
                                    {category.name_en}{', '}
                                </a>)]
                            }

                        </span>
                        <a
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                        >
                            <h2 className="woocommerce-loop-product__title">
                                {product.name_en}
                            </h2>
                            <div className="product-short-description">
                                <p>
                                    {product.description_en}
                                </p>
                            </div>
                            {/* <div className="product-sku">SKU: {product.barcode}</div> */}
                        </a>
                    </div>
                    {/* /.product-loop-body */}
                    <div className="product-loop-footer product-item__footer">
                        {
                        (discount(product) > 0 && product!.is_available == 1) && <span className="onsale mb-2">
                            -<span className="percentage">{discount(product)}%</span>
                        </span>
                        }
                        {product!.is_available != 1 && <div className="font12 red mb-1">{t('product_is_not_available')}</div>}
                        <div className="price-add-to-cart">
                            { product!.is_available == 1 && <span className="price">
                                <span className="electro-price">
                                    <ins>
                                        <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                    {t('aed')}
                                                </span>
                                                &nbsp;{(+product.sale_price).toFixed(2)}
                                            </bdi>
                                        </span>
                                    </ins>{" "}
                                    { discount(product) > 0 && <del>
                                        <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                    {t('aed')}
                                                </span>
                                                &nbsp;{(+product.regular_price).toFixed(2)}
                                            </bdi>
                                        </span>
                                    </del>}
                                </span>
                            </span>}
                            {/* <div
                                onClick={(e) => {
                                    navigate('/cart')
                                    e.stopPropagation();
                                }}
                                className="add-to-cart-wrap added" data-toggle="tooltip" data-title="Add to cart" data-original-title="" title="" aria-describedby="tooltip485572">
                                {!inCart() && <a href="?add-to-cart=122308" data-quantity="1" className="button product_type_simple add_to_cart_button ajax_add_to_cart added" data-product_id="122308" data-product_sku="HHPT277926641234" rel="nofollow">{t('add_to_cart')}</a>}
                                {inCart() && <a className="added_to_cart wc-forward" title="" data-original-title="View cart">View cart</a>}
                            </div> */}
                            
                            {(!inCart() && (product!.is_available == 1)) && <div
                                onClick={(e) => {
                                    props.onAddToCart(props.product)
                                    e.stopPropagation();
                                }}
                                className="add-to-cart-wrap"
                                data-toggle="tooltip"
                                data-title="Add to cart"
                                data-original-title=""
                                title=""
                            >
                                <a
                                    data-quantity={1}
                                    className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                    data-product_id={109059}
                                    data-product_sku="FM31321"
                                    aria-label="Add “Hiphone Luxury Iphone 13 Pro Max 24K Butterfly Artistic Edition” to your cart"
                                    rel="nofollow"
                                >
                                    {t('add_to_cart')}
                                </a>
                            </div>}
                        </div>
                        {/* /.price-add-to-cart */}
                        <div className="hover-area">
                            <div className="action-buttons">
                                <div
                                    onClick={(e) => {
                                        props.onAddToWishlist(props.product);
                                        e.stopPropagation();
                                    }}
                                    className="yith-wcwl-add-to-wishlist add-to-wishlist-109059  wishlist-fragment on-first-load"
                                    data-fragment-ref={109059}
                                    data-fragment-options='{"base_url":"","in_default_wishlist":false,"is_single":false,"show_exists":false,"product_id":109059,"parent_product_id":109059,"product_type":"simple","show_view":false,"browse_wishlist_text":"Browse wishlist","already_in_wishslist_text":"The product is already in your wishlist!","product_added_text":"Product added!","heading_icon":"fa-heart-o","available_multi_wishlist":false,"disable_wishlist":false,"show_count":false,"ajax_loading":false,"loop_position":"after_add_to_cart","item":"add_to_wishlist"}'
                                >
                                    {/* ADD TO WISHLIST */}
                                    <div className="yith-wcwl-add-button">
                                        {inWishlist() && <span className="feedback"><i className="yith-wcwl-icon fa fa-heart"></i>{"   "}Product added!</span>}
                                        {!inWishlist() && <a
                                            rel="nofollow"
                                            data-product-id={109059}
                                            data-product-type="simple"
                                            data-original-product-id={109059}
                                            className="add_to_wishlist single_add_to_wishlist"
                                            data-title="Add to wishlist"
                                        >
                                            {/* <i className="yith-wcwl-icon fa fa-heart-o" />{" "} */}
                                            <span>{t('add_to_wishlist')}</span>
                                        </a>}
                                    </div>
                                    {/* COUNT TEXT */}
                                </div>
                                <a
                                    className="add-to-compare-link"
                                    data-product_id={109059}
                                >
                                    Compare
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* /.product-loop-footer */}
                </div>
                {/* /.product-inner */}
            </div>
            {/* /.product-outer */}
        </li>
    );
}