import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { MyAccountActions } from './actions';
import axios from 'axios';
import { HomeApis } from '../../server/api/Home';
import { AuthApis } from '../../server/api/Auth';
import { OrdersApis } from '../../server/api/Orders';
import { AddressesApis } from '../../server/api/Addresses';
import { AddressesModel } from '../../server/models/addresses';
import { AppActions } from '../app/actions';


function* orders(): SagaIterator {
  try {
    console.log('orders sSaga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OrdersApis.orders) as any;
    console.log('orders response: ');
    console.log(data);
    yield* put(MyAccountActions.orders.success(data.data)) as any;
    
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(MyAccountActions.orders.fail(errorMessage)) as any;
  }
}

function* addresses(): SagaIterator {
  try {
    console.log('addresses sSaga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AddressesApis.addresses) as any;
    console.log('addresses response: ');
    console.log(data.data);
    yield* put(MyAccountActions.addresses.success(data.data)) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(MyAccountActions.addresses.fail(errorMessage)) as any;
  }
}

function* putBillingAddress({payload} : ActionType<typeof MyAccountActions.putBillingAddress.request>): SagaIterator {
  try {
    console.log('put address Saga');
    yield* put(AppActions.insureToken()) as any;
    const addressesResponse = yield* call(AddressesApis.addresses) as any;
    console.log('addresses response: ')
    console.log(addressesResponse.data)
    const body : AddressesModel = { billing_address: payload, shipping_address: addressesResponse.data.data.shipping_address}
    console.log('put address payload')
    console.log(body)
    const { data } = yield* call(AddressesApis.putAddress, body) as any;
    console.log('put address response: ');
    console.log(data.data);
    yield* put(MyAccountActions.putBillingAddress.success()) as any;
    yield* put(MyAccountActions.moveToAddresses.true()) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(MyAccountActions.putBillingAddress.fail(errorMessage)) as any;
  }
}

function* putShippingAddress({payload} : ActionType<typeof MyAccountActions.putShippingAddress.request>): SagaIterator {
  try {
    console.log('put address Saga');
    yield* put(AppActions.insureToken()) as any;
    const addressesResponse = yield* call(AddressesApis.addresses) as any;
    const body : AddressesModel = { billing_address: addressesResponse.data.data.billing_address, shipping_address: payload}
    const { data } = yield* call(AddressesApis.putAddress, body) as any;
    console.log('put address response: ');
    console.log(data.data);
    yield* put(MyAccountActions.putBillingAddress.success()) as any;
    yield* put(MyAccountActions.moveToAddresses.true()) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(MyAccountActions.putBillingAddress.fail(errorMessage)) as any;
  }
}

export function* watchMyAccount(): SagaIterator {
  yield* takeLatest(MyAccountActions.orders.request, orders) as any;
  yield* takeLatest(MyAccountActions.addresses.request, addresses) as any;
  yield* takeLatest(MyAccountActions.putBillingAddress.request, putBillingAddress) as any;
  yield* takeLatest(MyAccountActions.putShippingAddress.request, putShippingAddress) as any;
}
