import { BEST_SELLERS_URL, BRANDS_URL, FLASH_SELLS_URL, PRODUCTS_URL, SIMPLE_PRODUCT_URL, SLIDER_URL, YOU_MAY_LIKE_URL } from '../../configs';
import { Api } from './Api';

export class HomeApis {
  static slider(): ReturnType<typeof Api.get> {
    return Api.get(SLIDER_URL);
  }

  static flashSales(): ReturnType<typeof Api.get> {
    return Api.get(FLASH_SELLS_URL);
  }

  static bestSellers(): ReturnType<typeof Api.get> {
    return Api.get(BEST_SELLERS_URL);
  }

  static youMayLike(): ReturnType<typeof Api.get> {
    return Api.get(YOU_MAY_LIKE_URL);
  }

  static brands(): ReturnType<typeof Api.get> {
    return Api.get(BRANDS_URL);
  }

  static primaryProduct(payload: {id: string}): ReturnType<typeof Api.get> {
    return Api.get(SIMPLE_PRODUCT_URL, payload);
  }
}