import { useSelector } from "react-redux";
import { Footer } from "../../../components/footer";
import { Header } from "../../../components/header/headers";
import { RootState } from "../../../store/rootState";
import { useAction } from "../../../utils/hooks/action";
import { MyAccountActions } from "../../../modules/my_account/actions";
import { useEffect } from "react";
import { Alert } from "../../../components/alert";
import { ProductModel } from "../../../server/models/product";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { Base } from "../../base/components/base";
import { BasePage } from "../../base/base";
import { useTranslation } from "react-i18next";
import { OrderActions } from "../../my_account/order/module/actions";
import { CheckoutActions } from "../module/actions";
import { Loading } from "../../../components/loading";
import { NotFoundPage } from "../../404";
import { useSearchParams } from "react-router-dom";

export const CheckoutFailedPage = () => {
  // Url params
  const [params, setParams] = useSearchParams();
  // States
  const { checkoutStatus, loadingCheckoutStatus } = useSelector(
    (state: RootState) => state.checkout
  );
  // Actions
  const checkStatusAction = useAction(CheckoutActions.checkoutStatus.request);
  const { t } = useTranslation();

  useEffect(() => {
    console.log('location');
    console.log(window.location.href);
    var href = window.location.href;
    var hashIndex = href.indexOf('#')
    if(hashIndex != -1) {
        var browseHref = href.substring(0, hashIndex)
        let paramString = browseHref.split('?')[1];
        let queryString = new URLSearchParams(paramString);
        for(let pair of (queryString as any).entries()) {
            let key = pair[0];
            let value = pair[1]
            console.log('key: ' + key)
            console.log('value: ' + value)
            if(key == 'payment_id') {
                checkStatusAction(value);
                return;
            }
        }
        checkStatusAction('');
    } else {
        checkStatusAction('');
    }
  }, [window.location.href]);

  if (loadingCheckoutStatus) {
    return <Loading />;
  }

  if (!checkoutStatus) {
    return <NotFoundPage />;
  }

  const ProductItem = (props: { product: ProductModel }) => {
    return (
      <tr className="woocommerce-table__line-item order_item">
        <td className="woocommerce-table__product-name product-name">
          <a href="https://hiphonetelecom.com/product/test-product/">
            {props.product.name_en}
          </a>{" "}
          <strong className="product-quantity">
            ×&nbsp;{+props.product.order_quantity}
          </strong>{" "}
        </td>
        <td className="woocommerce-table__product-total product-total">
          <span className="woocommerce-Price-amount amount">
            <bdi>
              <span className="woocommerce-Price-currencySymbol">
                {t("aed")}
              </span>
              &nbsp;{+props.product.sale_price * +props.product.order_quantity}
            </bdi>
          </span>{" "}
        </td>
      </tr>
    );
  };

  return (
    <BasePage>
      <div className="d-flex flex-column">
        <Header />
        <div className="font40 ps-4 pe-4 pt-4 d-flex justify-content-center">
          {t("payment_failed")}
        </div>
        <div className="font20 p-4 pb-5 d-flex justify-content-center">
          {t("payment_failed_description")}
        </div>
        <div className="container mb-5">
          <div className="row">
            {/* Content */}
            <div className="col-lg-9 col-12" style={{ textAlign: "start" }}>
              <div className="woocommerce-MyAccount-content">
                <div className="woocommerce-notices-wrapper"></div>
                <p>
                  {t("order")} #
                  <mark className="order-number">
                    {checkoutStatus.order.id}
                  </mark>{" "}
                  was placed on{" "}
                  <mark className="order-date">
                    <Moment>{checkoutStatus?.order.date}</Moment>
                  </mark>
                  .
                </p>
                <section className="woocommerce-order-details">
                  <h2 className="woocommerce-order-details__title">
                    {t("order_details")}
                  </h2>
                  <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                    <thead>
                      <tr>
                        <th className="woocommerce-table__product-name product-name">
                          {t("product")}
                        </th>
                        <th className="woocommerce-table__product-table product-total">
                          {t("total")}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {[
                        ...(checkoutStatus!.products ?? []).map(
                          (product, index) => <ProductItem product={product} />
                        ),
                      ]}
                    </tbody>

                    <tfoot>
                      <tr>
                        <th scope="row">{t("subtotal")}:</th>
                        <td>
                          <span className="woocommerce-Price-amount amount">
                            <span className="woocommerce-Price-currencySymbol">
                              {t("aed")}
                            </span>
                            &nbsp;{+checkoutStatus!.order.subtotal}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{t("shipping")}:</th>
                        <td>
                          {checkoutStatus?.order.delivery_fee != "0" && (
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">
                                {t("aed")}
                              </span>
                              <div>
                                &nbsp;{+checkoutStatus!.order.delivery_fee}
                              </div>
                            </span>
                          )}
                          {checkoutStatus?.order.delivery_fee == "0" && (
                            <span className="woocommerce-Price-amount amount">
                              <div className="bold" style={{ color: "green" }}>
                                {t("free")}
                              </div>
                            </span>
                          )}
                          {/* <small className="shipped_via">via Flat rate</small> */}
                        </td>
                      </tr>
                      {checkoutStatus?.order.discount && (
                        <tr>
                          <th scope="row">{t("discount")}:</th>
                          <td style={{ color: "green" }}>
                            {t("aed")} {checkoutStatus?.order.discount}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th scope="row">{t("payment_method")}:</th>
                        <td>
                          {checkoutStatus?.order.payment_method == "cash"
                            ? "Cash on delivery"
                            : checkoutStatus?.order.payment_method.toUpperCase()}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{t("total")}:</th>
                        <td>
                          <span className="woocommerce-Price-amount amount">
                            <span className="woocommerce-Price-currencySymbol">
                              {t("aed")}
                            </span>
                            &nbsp;{checkoutStatus?.order!.total}
                          </span>{" "}
                          <small className="includes_tax">
                            (includes{" "}
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">
                                {t("aed")}
                              </span>
                              &nbsp;0.05
                            </span>{" "}
                            VAT)
                          </small>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </section>

                <section className="woocommerce-customer-details">
                  <section className="woocommerce-columns woocommerce-columns--2 woocommerce-columns--addresses col2-set addresses">
                    <div className="woocommerce-column woocommerce-column--1 woocommerce-column--billing-address col-1">
                      <h2 className="woocommerce-column__title">
                        {t("billing_address")}
                      </h2>
                      <address>
                        {checkoutStatus?.billing_address.first_name +
                          " " +
                          checkoutStatus?.billing_address.last_name}
                        <br />
                        {checkoutStatus?.billing_address.company}
                        <br />
                        {checkoutStatus!.billing_address.country}
                        <br />
                        {checkoutStatus!.billing_address.street}
                        <br />
                        {checkoutStatus!.billing_address.city}
                        <br />
                        {checkoutStatus?.billing_address.country}
                        <p className="woocommerce-customer-details--phone">
                          {checkoutStatus?.billing_address.phone}
                        </p>
                        <p className="woocommerce-customer-details--email">
                          {checkoutStatus?.billing_address.email}
                        </p>
                      </address>
                    </div>

                    <div className="woocommerce-column woocommerce-column--2 woocommerce-column--shipping-address col-2">
                      <h2 className="woocommerce-column__title">
                        {t("shipping_address")}
                      </h2>
                      <address>
                        {checkoutStatus?.shipping_address.first_name +
                          " " +
                          checkoutStatus?.shipping_address.last_name}
                        <br />
                        {checkoutStatus?.shipping_address.company}
                        <br />
                        {checkoutStatus?.shipping_address.country}
                        <br />
                        {checkoutStatus?.shipping_address.street}
                        <br />
                        {checkoutStatus?.shipping_address.city}
                        <br />
                        {checkoutStatus?.shipping_address.country}
                        <p className="woocommerce-customer-details--phone">
                          {checkoutStatus?.shipping_address.phone}
                        </p>
                        <p className="woocommerce-customer-details--email">
                          {checkoutStatus?.shipping_address.email}
                        </p>
                      </address>
                    </div>
                  </section>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </BasePage>
  );
};
