import { useSelector } from "react-redux";
import FlashSalesBackgroundImage from "../../../assets/flashsales_background.jpg";
import { RootState } from "../../../store/rootState";
import { FlashSale } from "./flashsale";
import { Brand } from "./brand";
import { useTranslation } from "react-i18next";
import LeftArrowSvgImage from '../../../assets/left-arrow.svg'
import RightArrowSvgImage from '../../../assets/right-arrow.svg'
import { useLanguage } from "../../../utils/hooks/language";
import { useRef } from "react";

export const Brands = () => {
  const { loadingSlider, loadingFlashSales, slider, brands } = useSelector(
    (state: RootState) => state.home
  );
  const { t, i18n } = useTranslation();
  const [en, ar] = useLanguage();
  const ref = useRef(null);

  const rightScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function(){
      element.scrollLeft += 50;
      scrollAmount += 50;
      if(scrollAmount >= 50){
        window.clearInterval(slideTimer);
      }
    }, 100);
  };

  const leftScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function(){
      element.scrollLeft -= 50;
      scrollAmount += 50;
      if(scrollAmount >= 50){
        window.clearInterval(slideTimer);
      }
    }, 100);
  };


  if ((brands ?? []).length == 0) {
    return <></>;
  }

  return (
    <div className="container brands-wrapper pt-4 pb-3 mb-2">
      <div>
        <h2 style={{ fontWeight: "bold" }}>{t("brands")}</h2>
      </div>
      <div>
      <div className="container">
            <div className="row" style={{ position: "relative" }}>
              <div style={{ flex: 1 }}>
                <div className="brands pt-4 pb-4">
                <ul ref={ref}>
                  {[...(brands ?? []).map((brand, index) => <Brand brand={brand} />)]}
                  </ul>
                </div>
              </div>

              <img
                  className="pointer"
                  src={en ? LeftArrowSvgImage : RightArrowSvgImage}
                  style={{ width: 40, marginLeft: -30, position: "absolute", top: "45%" }}
                  onClick={() => {
                    leftScroll(ref.current, 100, 50, -10);
                  }}
                />
                
                <img
                  className="pointer"
                  src={en ? RightArrowSvgImage : LeftArrowSvgImage}
                  style={{ width: 40, marginRight: -30, position: "absolute", top: "45%", right: '0%' }}
                  onClick={() => {
                    rightScroll(ref.current, 100, 50, 10);
                  }}
                />
              {/* <div
                className="d-flex justify-content-between align-items-center"
                style={{ position: "absolute", top: "45%" }}
              >
                <img
                  className="pointer"
                  src={en ? LeftArrowSvgImage : RightArrowSvgImage}
                  style={{ height: 20, width: 20, marginLeft: -30 }}
                  onClick={() => {
                    handleHorizantalScroll(ref.current, 25, 100, -10);
                  }}
                />
                <img
                  className="pointer"
                  src={en ? RightArrowSvgImage : LeftArrowSvgImage}
                  style={{ height: 20, width: 20, marginRight: -30 }}
                  onClick={() => {
                    handleHorizantalScroll(ref.current, 25, 100, 10);
                  }}
                />
              </div> */}
            </div>
          </div>
      </div>
      {/* <div>
        <div className=' row'>
          <div style={{ flex: 1 }}>
            <div className='flashsells'>
              <ul>
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
                <Brand brand={{
                  id: '1',
                  image: 'https://img.freepik.com/free-icon/mac-os_318-10374.jpg'
                }} />
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
