import { useEffect, useState } from "react";
import { ProductModel } from "../../../server/models/product";
import { CartModel } from "../../../server/models/cart";
import { useAction } from "../../../utils/hooks/action";
import { CartActions } from "../module/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";

export const CartItems = (props: {
  cart: CartModel,
  onQuantity: ((cart: CartModel) => any)
}) => {
  const [quantity, setQuantity] = useState<number>(props.cart.quantity);
  const { loadingRemoveFromCart } = useSelector((state: RootState) => state.cart);
  const requestRemoveFromCartAction = useAction(CartActions.remove.request);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loadingRemoveFromCart) {
        setLoading(false);
    }
}, [loadingRemoveFromCart]);

  return (
    <tr className="woocommerce-cart-form__cart-item cart_item">
      <td className="product-remove">
        { !loadingRemoveFromCart && <a
          href="#"
          className="remove"
          aria-label="Remove this item"
          data-product_id={props.cart.product.id}
          data-product_sku="CCTAPP"
          onClick={() => {
            setLoading(true)
            requestRemoveFromCartAction({product_id: props.cart.product.id});
          }}
        >
          ×
        </a>}
        { loading && <div style={{width: 25, height: 25}} className="loader"></div> }
      </td>
      <td className="product-thumbnail">
        <a>
          <img
            width={300}
            height={300}
            src="{{props.prodImg}}"
            className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
            alt=""
            loading="lazy"
            srcSet={props.cart.product.image}
            sizes="(max-width: 300px) 100vw, 300px"
          />
        </a>
      </td>
      <td className="product-name" data-title="Product">
        <a>
          {props.cart.product.name_en}
        </a>
      </td>
      <td className="product-price" data-title="Price">
        <span className="woocommerce-Price-amount amount">
          <bdi>
            <span className="woocommerce-Price-currencySymbol">
              AED
            </span>
            &nbsp;{props.cart.product.sale_price}
          </bdi>
        </span>
      </td>
      <td className="product-quantity" data-title="Quantity">
        <div className="quantity">
          <label htmlFor="quantity_646873623a067">
            Quantity
          </label>
          <input
            type="number"
            id="quantity_646873623a067"
            className="input-text qty text"
            step={1}
            min={0}
            max=""
            name="cart[dc7357a8f2f100c2ffbe200e49578170][qty]"
            title="Qty"
            value={quantity}
            size={4}
            inputMode="numeric"
            aria-labelledby="Carbon Case Tpu AirPods Pro quantity"
            onChange={(value) => {
              const _quantity = +value.target.value;
              setQuantity(_quantity)
              props.onQuantity({ ...props.cart, quantity: _quantity })
              console.log(`val: ${value.target.value}`)

            }}

          />
        </div>
      </td>
      <td className="product-subtotal" data-title="Subtotal">
        <span className="woocommerce-Price-amount amount">
          <bdi>
            <span className="woocommerce-Price-currencySymbol">
              AED
            </span>
            &nbsp;{quantity * +props.cart.product.sale_price}
          </bdi>
        </span>
      </td>
    </tr>
  );
}