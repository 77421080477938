import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { AppActions } from '../../../modules/app/actions';
import { WishlistActions } from './actions';
import { WishlistApis } from '../../../server/api/Wishlist';
import { RootState } from '../../../store/rootState';


function* wishlist({ }: ActionType<typeof WishlistActions.wishlist.request>): SagaIterator {
  try {
    console.log('wishlist saga called')
    // Fetching wishlistå products
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    console.log(`is logged in? : ${login}`)
    console.log(`user? : ${user}`)
    if(!login || !user) {
      yield* put(WishlistActions.wishlist.success()) as any;
      return;
    }
    
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(WishlistApis.wishlist) as any;
    console.log('wishlist response: ');
    console.log(data);
    yield* put(WishlistActions.wishlist.success()) as any;
    yield* put(AppActions.wishlist.set(data.data)) as any;
  } catch (error) {
    console.log('wishlist saga error')
    console.log(error)
    yield* put(WishlistActions.wishlist.fail('')) as any;
  }
}

function* add({ payload }: ActionType<typeof WishlistActions.add.request>): SagaIterator {
  try {
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    if(!login || !user) {
      console.log('not logged in')
      yield* put(AppActions.wishlist.add(payload)) as any;
      yield* put(WishlistActions.add.success()) as any;
      return;
    }

    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(WishlistApis.add, { product_id: payload.id}) as any;
    console.log('add to wishlist response: ');
    console.log(data);
    yield* put(WishlistActions.add.success()) as any;
    yield* put(AppActions.wishlist.add(payload)) as any;
  } catch (error) {
    console.log(error);
    yield* put(WishlistActions.wishlist.fail('')) as any;
  }
}

function* remove({ payload }: ActionType<typeof WishlistActions.remove.request>): SagaIterator {
  try {
    const { login, user } = yield* select((state: RootState) => state.app) as any;
    if(!login || !user) {
      yield* put(AppActions.wishlist.remove({id: payload.id})) as any;
      yield* put(WishlistActions.remove.success()) as any;
      return;
    }

    // Fetching wishlistå products
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(WishlistApis.remove, { product_id: payload.id }) as any;
    console.log('remove to wishlist response: ');
    console.log(data);
    yield* put(WishlistActions.remove.success()) as any;
    yield* put(AppActions.wishlist.remove({id: payload.id})) as any;
  } catch (error) {
    yield* put(WishlistActions.wishlist.fail('')) as any;
  }
}

export function* watchWishlist(): SagaIterator {
  yield* takeLatest(WishlistActions.wishlist.request, wishlist) as any;
  yield* takeLatest(WishlistActions.add.request, add) as any;
  yield* takeLatest(WishlistActions.remove.request, remove) as any;
}