import { useSelector } from "react-redux";
import { ProductModel } from "../../../server/models/product";
import { RootState } from "../../../store/rootState";
import { ProductsSideMenu } from "./products_side_menu";
import { Product } from "./product";
import { useAction } from "../../../utils/hooks/action";
import { AppActions } from "../../../modules/app/actions";
import { WishlistActions } from "../../wishlist/module/actions";
import { CartActions } from "../../cart/module/actions";
import { ProductsActions } from "../module/actions";
import { useEffect } from "react";
import { ProductsPayload } from "../../../server/payload/products";
import { hexEncode } from "../../../utils/string";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../utils/hooks/language";
import { ProductsBrands } from "./products_brands";

export const ProductsList = () => {
  // Navigation
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [en, ar] = useLanguage();
  
  // States
  const { products, view, total_count } = useSelector((state: RootState) => state.products);
  const { categories, cart } = useSelector((state: RootState) => state.app);

  // Actions
  const addToCartRequestAction = useAction(CartActions.add.request);
  const addToWishlistRequestAction = useAction(WishlistActions.add.request);
  const setView = useAction(ProductsActions.view.set);

  const ProductsListHeader = () => {
    return (
      <header className="d-flex justify-content-between align-items-center">
        <h1 className="products-list-header-title">{searchParams.get('title') ? `${t('search_results_for')} “${searchParams.get('title')}”` : t('shop')}</h1>
        <p className="">{`${t('showing')} ${startVisibleCount(+page())}- ${endVisibleCount(+page())} ${t('of')} ${total_count} ${t('results')}`}</p>
      </header>
    )
  }

  const page = () => {
    return searchParams.get('page') ?? 0
  }

  const sort = () => {
    return searchParams.get('sort') ?? 'latest'
  }

  const pages = () => {
    return Math.ceil((total_count / 40));
  }

  const setPage = (page) => {
    searchParams.set('page', page)
    setSearchParams(searchParams)
  }

  const setSort = (sort) => {
    searchParams.set('sort', sort)
    searchParams.set('page', '0')
    setSearchParams(searchParams)
  }

  const startVisibleCount = (page: number) => ((page) * 40) + 1;
  const endVisibleCount = (page: number) => (((page) * 40) + 1) + 40;

  const ProductsListViewChange = () => {
    return (
      <div className="shop-control-bar d-flex justify-content-between align-items-center ps-3" style={{height:45}}>
        {/* Filters */}
        {/* <div className="handheld-sidebar-toggle">
          <button className="btn sidebar-toggler" type="button">
            <i className="fas fa-sliders-h" />
            <span>Filters</span>
          </button>
        </div> */}
        {/* Select View */}
        <ul className="shop-view-switcher nav nav-tabs" role="tablist">
          <li className="nav-item ms-2 me-2">
            <a
              className={`nav-link ${view == 'grid' ? ' active' : ''}`}
              data-toggle="tab"
              data-archive-class="grid"
              title="Grid View"
              onClick={() => setView("grid")}
            >
              <i className="fa fa-th" />
            </a>
          </li>
          <li className="nav-item ms-2 me-2">
            <a
              className={`nav-link ${view == 'grid-extended' ? ' active' : ''}`}
              data-toggle="tab"
              data-archive-class="grid-extended"
              title="Grid Extended View"
              onClick={() => setView("grid-extended")}
            >
              <i className="fa fa-align-justify" />
            </a>
          </li>
          <li className="nav-item ms-2 me-2">
            <a
              className={`nav-link ${view == 'list-view' ? ' active' : ''}`}
              data-toggle="tab"
              data-archive-class="list-view"
              title="List View"
              onClick={() => setView("list-view")}
            >
              <i className="fa fa-list" />
            </a>
          </li>
          <li className="nav-item ms-2 me-2">
            <a
              className={`nav-link ${view == 'list-view-small' ? ' active' : ''}`}
              data-toggle="tab"
              data-archive-class="list-view-small"
              title="List View Small"
              onClick={() => setView("list-view-small")}
            >
              <i className="fa fa-th-list" />
            </a>
          </li>
        </ul>
        {/* Relevance */}
        <div className="woocommerce-ordering d-none d-lg-block">
          <select
            name="orderby"
            className="orderby"
            aria-label="Shop order"
            value={sort()}
            onChange={(event) => {
              setSort(event.target.value as any)
            }}
          >
            <option value="latest"   selected={sort() == 'latest'}>{t('sort_by_latest')}</option>
            <option value="higher"   selected={sort() == 'lower'}>{t('sort_by_high_to_low')}</option>
            <option value="lower"    selected={sort() == 'higher'}>{t('sort_by_low_to_high')}</option>
            <option value="discount" selected={sort() == 'discount'}>{t('sort_by_discount')}</option>
          </select>
          <input type="hidden" name="paged" defaultValue={1} />
          <input type="hidden" name="s" defaultValue="hiphone telecom" />
          <input type="hidden" name="product_cat" defaultValue={0} />
          <input type="hidden" name="post_type" defaultValue="product" />
        </div>
        {/* Pagination */}
        <div className="electro-advanced-pagination d-flex" style={{margin: 0}}>
            <form className="form-adv-pagination ms-2 me-2">
              <input id="goto-page" min="1" defaultValue={(+page()+1)} max="65" step="1" type="number" className="form-control" onChange={(event) => {
                console.log('pages input= ' + event.target.value)
                if(+event.target.value > 0 && +event.target.value <= +pages()) {
                  setPage(+event.target.value-1);
                }
              }}/>
            </form> 
            <div>{t('of')} </div>
            <div className="ms-2 me-2">{pages()}</div>
            <a 
            onClick={() => {
              if((+page() + 1) < +pages()) {
                setPage(+page() + 1)
              }
            }}
            className="next page-numbers">{en ? '→' : '←'}</a>
        </div>
      </div>
    )
  }

  const Products = () => {
    return (
      <ul
        data-view={view}
        data-toggle="shop-products"
        className="products columns-4 columns__wide--4"
      >
        {[
          ...(products ?? []).map((product, index) =>
            <Product
              product={product}
              onClick={function (product: ProductModel) {
                navigate(`/product/${product.id}`);
              }}
              onAddToCart={function (product: ProductModel) {
                console.log('on add to cart clicked');
                console.log(product);
                addToCartRequestAction({ quantity: 1, product: product });
              }}
              onAddToWishlist={function (product: ProductModel) {
                //addToWishlistAction({ product_id: product.id })
                addToWishlistRequestAction(product);
              }} />
          )]
        }
      </ul>
    )
  }

  function fetchProducts(page: number) {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams, { replace: true });
  }

  const Pagination = () => {
    console.log(`#page: ${page()}`);
    console.log(`#pages: ${pages()}`);
    return <>
      <div className="shop-control-bar-bottom">
        <p className="woocommerce-result-count">
        {`${t('showing')} ${startVisibleCount(+page())}- ${endVisibleCount(+page())} ${t('of')} ${total_count} ${t('results')}`}</p>
        { (+pages() > 1) && <nav className="woocommerce-pagination">
          <ul className="page-numbers">
            {+page() == 0 && <>
              <li className="page-item-selected">{(+page() + 1)}</li>
              <li onClick={() => fetchProducts(+page() + 1)} className="page-item">{(+page() + 1) + 1}</li>
              <li onClick={() => fetchProducts(+page() + 2)} className="page-item">{(+page() + 1) + 2}</li>
            </>}
            {(+page() > 0 && +page() < (+pages() - 1)) && <>
              <li onClick={() => fetchProducts(+page() - 1)} className="page-item">{(+page() + 1) - 1}</li>
              <li className="page-item-selected">{(+page() + 1)}</li>
              <li onClick={() => fetchProducts(+page() + 1)} className="page-item">{(+page() + 1) + 1}</li></>}
            {(+page() == (+pages() - 1)) && <>
              <li onClick={() => fetchProducts(+page() - 2)} className="page-item">{(+page() + 1) - 2}</li>
              <li onClick={() => fetchProducts(+page() - 1)} className="page-item">{(+page() + 1) - 1}</li>
              <li className="page-item-selected">{(+page() + 1)}</li></>}
          </ul>
        </nav> }
      </div></>
  }


  return (
    <div id="content" className="site-content" tabIndex={-1}>
      <div className="container">
        <div>
          <div className='row'>
            {/* #primary */}
            <div className='col-12 col-lg-3 d-none d-lg-block'>
              <ProductsSideMenu showAttributes={true} />
            </div>
            <div className='col-12 col-lg-9'>
              <div id="primary" className="w-100">
                <main id="main" >
                  <div className="woocommerce-notices-wrapper" />
                  <ProductsListHeader />
                  <ProductsListViewChange />
                  <Products />
                  <Pagination />
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
