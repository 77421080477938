import { Api } from './Api';
import { ADD_TO_CART_URL, CALCULATE_URL, CART_URL, CHECKOUT_URL, PAYMENT_LINK_URL, REMOVE_FROM_CART_URL, REPAIR_URL, UPDATE_CART_URL } from '../../configs';
import { AddToCartPayload } from '../payload/add_to_cart';
import { CalculatePayload } from '../payload/calculate';
import { CheckoutPayload } from '../payload/checkout';
import { RepairModel } from '../models/repair';


export class RepairApis {
  static repair(payload: RepairModel): ReturnType<typeof Api.post> {
    return Api.post(REPAIR_URL, payload);
  }
}