import { t } from 'i18next';
import Modal from 'react-modal';
import { InputField } from '../../../components/input_field';
import { TitleWithDivider } from '../../../components/title_with_divider';
import { Button } from '../../../components/button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootState';
import { useAction } from '../../../utils/hooks/action';
import { CheckoutActions } from '../module/actions';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        zIndex: '1',
        transform: 'translate(-50%, -50%)',
        borderRadius: '12px',
        overflowY: "scroll",
        maxHeight: '500px'
    },
};

export const LoginModal = () => {
    const { message, loginModal, loginModalUsername, loginModalPassword, loadingLogin, errors } = useSelector((state: RootState) => state.checkout);
    const loginModalAction = useAction(CheckoutActions.loginModal);
    const setUsernameAction = useAction(CheckoutActions.setLoginModalUsername);
    const setPasswordAction = useAction(CheckoutActions.setLoginModalPassword);
    const checkoutAction = useAction(CheckoutActions.checkout.request);

    function checkout() {
        console.log('checkout called');
        console.log(loginModalUsername.length);
        if(loginModalUsername.length > 0 && loginModalPassword.length > 0) {
            console.log('checkout called');
            checkoutAction({username: loginModalUsername, password: loginModalPassword});
        }
    }

    return (
        <Modal
            isOpen={loginModal}
            onRequestClose={() => {
                loginModalAction(false);
            }}
            style={modalStyles}
            contentLabel="My dialog"
        >
            <div className='' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: 350 }}>
                {/* Title */}
                <TitleWithDivider title={`${t('You are not logged in')}`} />
                {message && <div style={{color: 'red'}}>{message}</div>}
                {/* Fields */}
                <form className='mt-4 d-flex justify-content-start flex-column align-items-start w-100'>
                    <div className='font14 mb-3' style={{ textAlign: 'start' }}>{t('Please login to your account or register in order to continue your purchase')}</div>
                    <InputField
                        disabled={loadingLogin}
                        title={`${t('username_or_email_address')}`}
                        required={true}
                        value={loginModalUsername}
                        onChange={(value: string) => {
                            setUsernameAction(value)
                        }} />
                    <InputField
                        disabled={loadingLogin}
                        title={`${t('password')}`}
                        required={true}
                        value={loginModalPassword}
                        onChange={(value: string) => {
                            setPasswordAction(value)
                        }} />
                </form>

                {errors.length > 0 && <div className="woocommerce-form-coupon-toggle">
                                <div className="woocommerce-info" style={{ display: 'flex', flexDirection: 'column' }}>
                                  {[...errors.map((error, index) => <div>{error}</div>
                                  )]}
                                </div>
                              </div>}

                <Button
                    disabled={loadingLogin}
                    title={`${t('place_order')}`}
                    onClick={() => {
                        checkout();
                    }} />
            </div>
        </Modal>
    )
}