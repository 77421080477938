import { useSelector } from "react-redux";
import { Footer } from "../../../components/footer"
import { Header } from "../../../components/header/headers"
import { RootState } from "../../../store/rootState";
import { MyAccountMenu } from "../components/menu"
import { useAction } from "../../../utils/hooks/action";
import { MyAccountActions } from "../../../modules/my_account/actions";
import { useEffect } from "react";
import { Alert } from "../../../components/alert";
import { Order } from "./components/order";
import { OrdersActions } from "./module/actions";
import { BasePage } from "../../base/base";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/loading";

export const OrdersPage = () => {
    // States
    const { orders, loadingOrders, loadingPaymentLink, error } = useSelector((state: RootState) => state.orders);
    // Actions
    const ordersAction = useAction(OrdersActions.orders.request);
    const { t } = useTranslation();
    

    useEffect(() => {
        // Fetch orders
        ordersAction();
    }, [])

    if (loadingOrders) {
        return <Loading/>
    }

    if(loadingPaymentLink) {
        return <Loading message={"Redirecting"}/>
    }

    return (
        <BasePage>
            <div className="d-flex flex-column">
                <Header />
                <div className="font40 p-4 pb-5 d-flex justify-content-center">{t('orders')}</div>
                <div className="container mb-5">
                    { error && <Alert message={error}/>}
                    <div className="row">
                        {/* Menu */}
                        <div className="col-lg-3 col-12">
                            <MyAccountMenu />
                        </div>
                        {/* Content */}
                        <div className="col-lg-9 col-12">
                            {/* Orders lsit empty */}
                            {orders.length == 0 && <Alert message={t('no_order_has_been_made_yet')} />}
                            {/* Orders list */}
                            {orders.length > 0 && <table className="orders-table w-100">
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'start'}}><div className="nobr">{t('order')}</div></th>
                                        <th style={{textAlign: 'start'}}><div className="nobr">{t('date')}</div></th>
                                        <th style={{textAlign: 'start'}}><div className="nobr">{t('status')}</div></th>
                                        <th style={{textAlign: 'start'}}><div className="nobr">{t('type')}</div></th>
                                        <th style={{textAlign: 'start'}}><div className="nobr">{t('total')}</div></th>
                                        <th style={{textAlign: 'start'}}><div className="nobr">{t('actions')}</div></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {[...orders.map((order, index) => <Order order={order} />)]}
                                </tbody>
                            </table>}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </BasePage>
    )
}