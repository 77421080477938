import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  OrdersActions,
} from './actions';
import { OrdersTypes } from './types';

const initialState: OrdersTypes = {
  orders: [],
  paymentLink: undefined,
  redirect: false,
  loadingOrders: false,
  loadingPaymentLink: false,
  loadingCancel: false,
};

export const ordersReducer = createReducer(initialState, handleAction => [
  // Orders
  handleAction(OrdersActions.orders.request, (state ) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loadingOrders = true;
    }),
  ),
  handleAction(OrdersActions.orders.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingOrders = false;
      draft.orders = (payload == null) ? [] : payload
    }),
  ),

  handleAction(OrdersActions.orders.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingOrders = false;
    }),
  ),

  // Payment Link
  handleAction(OrdersActions.paymentLink.request, (state, { payload }: any) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loadingPaymentLink = true;
      draft.paymentLink = undefined;
      draft.error = undefined
    }),
  ),

  handleAction(OrdersActions.paymentLink.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingPaymentLink = false;
      if(payload) {
        draft.paymentLink = payload
        draft.redirect = true;
        draft.error = undefined
      }
    }),
  ),

  handleAction(OrdersActions.paymentLink.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.paymentLink = undefined;
      draft.loadingPaymentLink = false;
      draft.error = payload
      draft.redirect = false;
    }),
  ),

  // Redirect
  handleAction(OrdersActions.redirect, (state, { payload }: any) =>
    produce(state, draft => {
      draft.redirect = payload
    }),
  ),

  // Cancel
  handleAction(OrdersActions.cancel.request, (state, { payload }: any) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loadingCancel = true;
    }),
  ),
  handleAction(OrdersActions.cancel.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingPaymentLink = false;
      if(payload) {
        draft.loadingCancel = payload
      }
    }),
  ),
  handleAction(OrdersActions.cancel.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingCancel = false;
    }),
  ),
]);
