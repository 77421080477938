import { all } from 'redux-saga/effects';
import { watchApp } from '../modules/app/sagas';
import { watchMyAccount } from '../modules/my_account/sagas';
import { watchProducts } from '../pages/products/module/sagas';
import { watchProduct } from '../pages/product/module/sagas';
import { watchWishlist } from '../pages/wishlist/module/sagas';
import { watchOrders } from '../pages/my_account/orders/module/sagas';
import { watchCart } from '../pages/cart/module/sagas';
import { watchCheckout } from '../pages/checkout/module/sagas';
import { watchAuth } from '../pages/auth/module/sagas';
import { watchHome } from '../pages/home/module/sagas';
import { watchOrder } from '../pages/my_account/order/module/sagas';
import { watchHeader } from '../modules/header/sagas';
import { watchRepair } from '../pages/repair_services/module/sagas';

export default function* rootSaga() {
  yield all([
    watchApp(),
    watchAuth(), 
    watchHome(),
    watchMyAccount(),
    watchProducts(),
    watchProduct(),
    watchWishlist(),
    watchCart(),
    watchCheckout(),
    watchOrders(),
    watchOrder(),
    watchHeader(),
    watchRepair(),
  ]);
}
