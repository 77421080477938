import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { AuthActions } from './actions';
import { AuthApis } from '../../../server/api/Auth';
import { AppActions } from '../../../modules/app/actions';
import { Api } from '../../../server/api';
import { CartActions } from '../../cart/module/actions';
import { WishlistActions } from '../../wishlist/module/actions';

function* login({payload} : ActionType<typeof AuthActions.login.request>): SagaIterator {
  try {
    console.log('login saga');
    const { data } = yield* call(AuthApis.login, payload) as any;
    // console.log('login response:');
    // console.log(data);
    // Cache user data
    yield* put(AppActions.setUser(data.data)) as any;
    //localStorage.setItem('token', data.data.token);
    // console.log('token: ');
    // console.log(data.data.token);
    // Set token axios
    Api.setAuthToken(data.data.token)
    // Indicate as LoggedIn
    yield* put(AppActions.login()) as any;
    yield* put(AuthActions.login.success()) as any;
    // Request cart
    yield* put(CartActions.cart.request()) as any;
    // Request wishlist
    yield* put(WishlistActions.wishlist.request()) as any;
    //const { data } = yield* call(HomeActions.slider.success, "payload") as any;
    // yield* put(resetErrors());
    
  } catch (error) {
    console.log('login error')
    console.log(error)
    try {
      const errorMessage = (error as any).response.data.message;
      console.log('error in login')
      console.log((error as any).response.data.message)
      yield* put(AuthActions.login.fail(errorMessage)) as any;
    } 
    catch {
      yield* put(AuthActions.login.fail('Unable to login, Please try again later')) as any;
    }
    //yield* put(putPhoneNumber.fail(error));
    // yield* put(setErrors(error.response));
  }
}

function* register({ payload }: ActionType<typeof AuthActions.register.request>): SagaIterator {
  try {
    // console.log('register saga');
    // console.log(`register payload: ${payload}`);
    const { data } = yield* call(AuthApis.register, payload) as any;
    // console.log('register response:');
    // console.log(data);

    // Cache user
    // Cache user data
    yield* put(AppActions.setUser(data.data)) as any;
    //localStorage.setItem('token', data.data.token);
    // console.log('token: ');
    // console.log(data.data.token);
    // Set token axios
    Api.setAuthToken(data.data.token)
    // Indicate as LoggedIn
    yield* put(AppActions.login()) as any;
    yield* put(AuthActions.login.success()) as any;
    yield* put(AuthActions.register.success('')) as any;
    // Request cart
    yield* put(CartActions.cart.request()) as any;
    // Request wishlist
    yield* put(WishlistActions.wishlist.request()) as any;
    //const { data } = yield* call(HomeActions.slider.success, "payload") as any;
    // yield* put(resetErrors());    
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(AuthActions.register.fail(errorMessage)) as any;
    console.log('error in register')
    console.log((error as any).response.data.message)
    //yield* put(putPhoneNumber.fail(error));
    // yield* put(setErrors(error.response));
  }
}


export function* watchAuth(): SagaIterator {
  yield* takeLatest(AuthActions.login.request, login) as any;
  yield* takeLatest(AuthActions.register.request, register) as any;
}
