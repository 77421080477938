import { useSelector } from "react-redux";
import { useAction } from "../../../utils/hooks/action";
import { RootState } from "../../../store/rootState";
import { CheckoutActions } from "../module/actions";
import { useTranslation } from "react-i18next";

export const BillingAddress = () => {
  const { billingAddress: billing_address } = useSelector(
    (state: RootState) => state.checkout
  );
  const setBilling = useAction(CheckoutActions.billingAddress.set);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="col-1">
        <div className="woocommerce-billing-fields">
          <h3>{t("billing_details")}</h3>

          <div
            className="woocommerce-billing-fields__field-wrapper"
            style={{ textAlign: "start" }}
          >
            <p
              className="form-row form-row-first validate-required"
              id="billing_first_name_field"
              data-priority={10}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_first_name" className="">
                {t("first_name")}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  key={"billing_first_name"}
                  type="text"
                  className="input-text "
                  name="billing_first_name"
                  id="billing_first_name"
                  placeholder=""
                  defaultValue=""
                  autoComplete="given-name"
                  value={billing_address.first_name}
                  onChange={(e) => {
                    setBilling({
                      ...billing_address,
                      first_name: e.target.value,
                    });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row form-row-last validate-required"
              id="billing_last_name_field"
              data-priority={20}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_last_name" className="">
                {t("last_name")}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="text"
                  className="input-text "
                  name="billing_last_name"
                  id="billing_last_name"
                  placeholder=""
                  defaultValue=""
                  autoComplete="family-name"
                  value={billing_address.last_name}
                  onChange={(e) => {
                    setBilling({
                      ...billing_address,
                      last_name: e.target.value,
                    });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row form-row-wide"
              id="billing_company_field"
              data-priority={30}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_company" className="">
                {t("company_name")}&nbsp;
                <span className="optional">({t('optional')})</span>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="text"
                  className="input-text "
                  name="billing_company"
                  id="billing_company"
                  placeholder=""
                  defaultValue=""
                  autoComplete="organization"
                  value={billing_address.company}
                  onChange={(e) => {
                    setBilling({ ...billing_address, company: e.target.value });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row form-row-wide address-field update_totals_on_change validate-required"
              id="billing_country_field"
              data-priority={40}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_country" className="">
                {t("Country / Region")}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <strong>{t('United Arab Emirates')}</strong>
                <input
                  type="hidden"
                  name="billing_country"
                  id="billing_country"
                  defaultValue="AE"
                  autoComplete="country"
                  className="country_to_state"
                />
              </span>
            </p>
            <p
              className="form-row address-field validate-required form-row-wide"
              id="billing_address_1_field"
              data-priority={50}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_address_1" className="">
                {t("street_address")}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="text"
                  className="input-text "
                  name="billing_address_1"
                  id="billing_address_1"
                  placeholder={`${t('Apartment, suite, unit, etc')}`}
                  defaultValue=""
                  autoComplete="address-line1"
                  data-placeholder={`${t('Apartment, suite, unit, etc')}`}
                  value={billing_address.apartment}
                  onChange={(e) => {
                    setBilling({
                      ...billing_address,
                      apartment: e.target.value,
                    });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row address-field form-row-wide"
              id="billing_address_2_field"
              data-priority={60}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_address_2" className="screen-reader-text">
              {t("Apartment, suite, unit, etc.")}&nbsp;
                <span className="optional">({t('optional')})</span>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="text"
                  className="input-text "
                  name="billing_address_2"
                  id="billing_address_2"
                  placeholder={`${t('House number and street name')}`}
                  defaultValue=""
                  autoComplete="address-line2"
                  data-placeholder={`${t('House number and street name')}`}
                  value={billing_address.street}
                  onChange={(e) => {
                    setBilling({ ...billing_address, street: e.target.value });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row address-field validate-required form-row-wide"
              id="billing_city_field"
              data-priority={70}
              data-o_class="form-row form-row-wide address-field validate-required"
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_city" className="">
                {t('Town / City')}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="text"
                  className="input-text "
                  name="billing_city"
                  id="billing_city"
                  placeholder=""
                  defaultValue=""
                  autoComplete="address-level2"
                  value={billing_address.city}
                  onChange={(e) => {
                    setBilling({ ...billing_address, city: e.target.value });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row address-field validate-state form-row-wide"
              id="billing_state_field"
              data-priority={80}
              data-o_class="form-row form-row-wide address-field validate-state"
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_state" className="">
                {t('State / County')}&nbsp;
                <span className="optional">({t('optional')})</span>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="text"
                  className="input-text "
                  defaultValue=""
                  placeholder=""
                  name="billing_state"
                  id="billing_state"
                  autoComplete="address-level1"
                  data-input-classes=""
                  value={billing_address.state}
                  onChange={(e) => {
                    setBilling({ ...billing_address, state: e.target.value });
                  }}
                />
              </span>
            </p>

            <p
              className="form-row form-row-wide validate-required validate-phone"
              id="billing_phone_field"
              data-priority={100}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_phone" className="">
                {t('phone')}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="tel"
                  className="input-text "
                  name="billing_phone"
                  id="billing_phone"
                  placeholder=""
                  defaultValue=""
                  autoComplete="tel"
                  value={billing_address.phone}
                  onChange={(e) => {
                    setBilling({ ...billing_address, phone: e.target.value });
                  }}
                />
              </span>
            </p>
            <p
              className="form-row form-row-wide validate-required validate-email woocommerce-validated"
              id="billing_email_field"
              data-priority={110}
              style={{textAlign: 'start'}}
            >
              <label htmlFor="billing_email" className="">
                {t('email_address')}&nbsp;
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <span className="woocommerce-input-wrapper">
                <input
                  type="email"
                  className="input-text "
                  name="billing_email"
                  id="billing_email"
                  placeholder=""
                  defaultValue=""
                  autoComplete="email username"
                  value={billing_address.email}
                  onChange={(e) => {
                    setBilling({ ...billing_address, email: e.target.value });
                  }}
                />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className="woocommerce-additional-fields">
          <div className="woocommerce-additional-fields__field-wrapper">
            <p
              className="form-row notes"
              id="order_comments_field"
              data-priority=""
              style={{textAlign: 'start'}}
            >
              <label htmlFor="order_comments" className="">
                {t('Order notes')}&nbsp;
                <span className="optional">({t('optional')})</span>
              </label>
              <span className="woocommerce-input-wrapper">
                <textarea
                  name="order_comments"
                  className="input-text "
                  id="order_comments"
                  placeholder={`${t('Notes about your order')}`}
                  rows={2}
                  cols={5}
                  value={billing_address.note}
                  onChange={(e) => {
                    setBilling({ ...billing_address, note: e.target.value });
                  }}
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
