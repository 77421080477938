import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { CANCEL_ORDER_URL, ORDERS_URL, ORDER_URL } from '../../configs';
import { OrderPayload } from '../payload/order';
import { CancelOrderPayload } from '../payload/cancel_order';


export class OrdersApis {
  static orders(): ReturnType<typeof Api.get> {
    return Api.get(ORDERS_URL);
  }
  static order(payload: OrderPayload): ReturnType<typeof Api.get> {
    return Api.get(ORDER_URL, payload);
  }
  static cancel(payload: CancelOrderPayload): ReturnType<typeof Api.get> {
    return Api.post(CANCEL_ORDER_URL, payload);
  }
}