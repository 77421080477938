import { createAction } from 'deox';
import { OrderModel } from '../../../../server/models/order';
import { CancelOrderPayload } from '../../../../server/payload/cancel_order';

export class OrdersActions {
  static orders = {
    request: createAction('orders/orders_request'),
    success: createAction('orders/orders_success', resolve => (payload: OrderModel[]) =>
      resolve(payload),
    ),
    fail: createAction('orders/orders_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static paymentLink = {
    request: createAction('orders/payment_link_request', resolve => (payload: string) =>
      resolve(payload),
    ),
    success: createAction('orders/payment_link_success', resolve => (payload: String) =>
      resolve(payload),
    ),
    fail: createAction('orders/payment_link_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static cancel = {
    request: createAction('orders/cancel_request', resolve => (payload: CancelOrderPayload) =>
      resolve(payload),
    ),
    success: createAction('orders/cancel_success'),
    fail: createAction('orders/cancel_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static redirect = createAction('orders/redirect', resolve => (payload: boolean) => resolve(payload));
}
