import { useSelector } from "react-redux";
import { useAction } from "../../utils/hooks/action";
import { HeaderActions } from "../../modules/header/actions";
import { RootState } from "../../store/rootState";
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

export const SearchMenuLogo = () => {
    const { products, search, sidenav } = useSelector((state: RootState) => state.header);
    const openSidenavAction = useAction(HeaderActions.sidenav.open);
    const closeSidenavAction = useAction(HeaderActions.sidenav.close);
    const setSearchProductsAction = useAction(HeaderActions.products.success);
    const navigate = useNavigate();
    
    return <div className="header-logo-area">
              <div className="header-site-branding">
                <a
                onClick={() => {
                    navigate('/')
                }}
                  className="header-logo-link pointer"
                >
                  <img
                    src="https://hiphonetelecom.com/wp-content/uploads/2020/06/logo.png"
                    alt="HiPhone Telecom"
                    className="img-header-logo"
                    width={300}
                    height={49}
                  />
                </a>
              </div>
              <div className="off-canvas-navigation-wrapper ">
                <div className="off-canvas-navbar-toggle-buttons clearfix">
                  <button
                    className="navbar-toggler navbar-toggle-hamburger "
                    type="button"
                    onClick={() => {
                      if(sidenav) {
                        closeSidenavAction();
                        setSearchProductsAction([]);
                      } else {
                        openSidenavAction();
                      }
                    }}
                  >
                    { !sidenav && <i className="ec ec-menu" />}
                    { sidenav && <i className="ec ec-close-remove" />}

                  </button>
                </div>
              </div>
            </div>
}