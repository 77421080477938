import { getType } from 'deox';
import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' 

// import { resetStore } from 'modules/app/actions';
// import { authReducer } from 'modules/auth/reducer';
// import { appReducer } from 'modules/app/reducer';
// import { userReducer } from 'modules/user/reducer';
// import { seekerReducer } from 'modules/seeker/reducer';
// import { posterReducer } from 'modules/poster/reducer';

import { RootState } from './rootState';
import { appReducer } from '../modules/app/reducer';
import { myAccountReducer } from '../modules/my_account/reducer';
import { productsReducer } from '../pages/products/module/reducer';
import { productReducer } from '../pages/product/module/reducer';
import { wishlistReducer } from '../pages/wishlist/module/reducer';
import { ordersReducer } from '../pages/my_account/orders/module/reducer';
import { cartReducer } from '../pages/cart/module/reducer';
import { checkoutReducer } from '../pages/checkout/module/reducer';
import { homeReducer } from '../pages/home/module/reducer';
import { authReducer } from '../pages/auth/module/reducer';
import { orderReducer } from '../pages/my_account/order/module/reducer';
import { headerReducer } from '../modules/header/reducer';
import { repairReducer } from '../pages/repair_services/module/reducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['auth','home','myAccount','products','wishlist','cart','checkout','orders','order','header', 'repair'],
  whitelist: ['app']
};

const projectReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  home: homeReducer,
  myAccount: myAccountReducer,
  products: productsReducer,
  product: productReducer,
  wishlist: wishlistReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  orders: ordersReducer,
  order: orderReducer,
  header: headerReducer,
  repair: repairReducer
});

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === 'resetStore') {
    state = undefined;
  }
  return projectReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
