import { createAction } from 'deox';
import { ProductModel } from '../../../server/models/product';


export class WishlistActions {
  static wishlist = {
    request: createAction('wishlist/wishlist_request'),
    success: createAction('wishlist/wishlist_success'),
    fail: createAction('wishlist/wishlist_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static add = {
    request: createAction('wishlist/add_request', resolve => (payload: ProductModel) =>
      resolve(payload),
    ),
    success: createAction('wishlist/add_success'),
    fail: createAction('wishlist/add_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static remove = {
    request: createAction('wishlist/remove_request', resolve => (payload: ProductModel) =>
      resolve(payload),
    ), 
    success: createAction('wishlist/remove_success'),
    fail: createAction('wishlist/remove_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
