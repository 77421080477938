import { Action } from 'deox';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreator, bindActionCreators } from 'redux';
import { useStore } from "react-redux";
import _castArray from "lodash.castarray";


export const useAction = <T extends ActionCreator<Action<string>>>(action: T): T => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(action, dispatch), [dispatch]);
};

export const usePrevious = <T>(value: T): T | void => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

/*
* It's important to also use a action reducer
* which keeps track of the latest action for
* this to work.
*/

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
// export function useReduxAction(effect, type, deps = []) {
//   const currentValue = useRef(null);
//   const store = useStore();

//   const handleChange = () => {
//     const state = store.getState();
//     const action = (state as any).action;
//     let previousValue = currentValue.current;
//     currentValue.current = action.type;

//     if (
//       previousValue !== action.type &&
//       _castArray(type).includes(action.type)
//     ) {
//       effect(action);
//     }
//   };

//   useEffect(() => {
//     const unsubscribe = store.subscribe(handleChange);
//     return () => unsubscribe();
//   }, deps);
// }