import { useEffect, useState } from "react";
import { ProductModel } from "../../../server/models/product";
import { RootState } from "../../../store/rootState";
import { useSelector } from "react-redux";
import { useAction } from "../../../utils/hooks/action";
import { WishlistActions } from "../module/actions";
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

export const WishlistItem = (props: {
    product: ProductModel;
    addedDate: string
}) => {
    const { loadingRemoveFromWishlist } = useSelector((state: RootState) => state.wishlist);
    const [loading, setLoading] = useState(false);
    const removeFromWishlistAction = useAction(WishlistActions.remove.request);
    const navigate = useNavigate();

    useEffect(() => {
        if (!loadingRemoveFromWishlist) {
            setLoading(false);
        }
    }, [loadingRemoveFromWishlist]);

    return (
        <tr id="yith-wcwl-row-101741" data-row-id={101741}>
            <td className="product-remove">
                <div>
                    {!loading && <a
                        className="remove remove_from_wishlist pointer"
                        title="Remove this product"
                        onClick={() => {
                            setLoading(true)
                            removeFromWishlistAction(props.product);
                        }}>
                        ×
                    </a>}
                    {loading && <div style={{ width: 20, height: 20 }} className="loader"></div>}
                </div>
            </td>
            <td className="product-thumbnail pointer">
                <a
                    onClick={() => {
                        navigate(`/product/${props.product.id}`);
                    }}
                >
                    <img
                        width={300}
                        height={300}
                        src={props.product.image}
                        className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                        alt=""
                        loading="lazy"


                    />{" "}
                </a>
            </td>
            <td className="product-name pointer">
                <a
                    onClick={() => {
                        navigate(`/product/${props.product.id}`);
                    }}>
                    {props.product.name_en}{" "}
                </a>
            </td>
            <td className="product-price">
                <span className="electro-price">
                    <ins>
                        <span className="woocommerce-Price-amount amount">
                            <bdi>
                                <span className="woocommerce-Price-currencySymbol">
                                    AED
                                </span>
                                &nbsp;{props.product.sale_price}
                            </bdi>
                        </span>
                    </ins>{" "}

                </span>
            </td>
            <td className="product-add-to-cart">
                {/* Date added */}
                {/* <span className="dateadded">
                    Added on: {props.addedDate}
                </span> */}
                {/* Add to cart button */}

                {/* Change wishlist */}
                {/* Remove from wishlist */}
            </td>
        </tr>
    )
}