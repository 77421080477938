import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  MyAccountActions,
} from './actions';
import { MyAccountTypes } from './types';
import { log } from 'console';

const initialState: MyAccountTypes = {
  loading: false,
  orders: [],
  addresses: {
    shipping_address: undefined,
    billing_address: undefined,
  },
  moveToAddresses: false
};

export const myAccountReducer = createReducer(initialState, handleAction => [
  handleAction(MyAccountActions.orders.request, (state ) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loading = true;
    }),
  ),
  handleAction(MyAccountActions.orders.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
      draft.orders = (payload == null) ? [] : payload
    }),
  ),
  handleAction(MyAccountActions.orders.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  handleAction(MyAccountActions.addresses.request, (state ) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loading = true;
    }),
  ),
  handleAction(MyAccountActions.addresses.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
      if(payload) {
        draft.addresses = payload
      }
    }),
  ),
  handleAction(MyAccountActions.addresses.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  handleAction(MyAccountActions.putBillingAddress.request, (state ) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(MyAccountActions.putBillingAddress.success, (state) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),
  handleAction(MyAccountActions.putBillingAddress.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  handleAction(MyAccountActions.putShippingAddress.request, (state ) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(MyAccountActions.putShippingAddress.success, (state) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),
  handleAction(MyAccountActions.putShippingAddress.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  handleAction(MyAccountActions.moveToAddresses.true, (state, { payload }: any) =>
    produce(state, draft => {
      draft.moveToAddresses = true;
    }),
  ),
  handleAction(MyAccountActions.moveToAddresses.false, (state, { payload }: any) =>
    produce(state, draft => {
      draft.moveToAddresses = false;
    }),
  ),
]);
