import { HomeCategory } from "./home_category";
import { hexEncode } from "../../../utils/string";
import { ProductsPayload } from "../../../server/payload/products";
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { useLanguage } from "../../../utils/hooks/language";
import { useTranslation } from "react-i18next";

import BeatsImage from '../../../assets/categories/home_category_beats.png'
import CctvImage from '../../../assets/categories/home_category_cctv.png'
import IphoneImage from '../../../assets/categories/home_category_apple.png'
import IsafeImage from '../../../assets/categories/home_category_isafe.png'
import JblImage from '../../../assets/categories/home_category_jbl.png'
import LuxuryImage from '../../../assets/categories/home_category_luxury.png'
import MaestroImage from '../../../assets/categories/home_category_maestro.png'
import SamsungImage from '../../../assets/categories/home_category_samsung.png'

export const HomeCategories = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [en, ar] = useLanguage();
    const { t, i18n } = useTranslation();

    return (
        <div className='container mt-5'>
            <div className={`row`}>
                <HomeCategory name={t('apple')} image={IphoneImage} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': name,
                        })}`
                    })
                }} index={2}/>
                <HomeCategory name={t('samsung')} image={SamsungImage} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': name,
                        })}`
                    })
                }} index={3}/>

                {/* Item */}
                <HomeCategory name={t('isafe')} image={IsafeImage}
                onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': "iSafe",
                        })}`
                    });
                } } index={0} />
                <HomeCategory name={t('maestro')} image={MaestroImage} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': 'Maestro',
                        })}`
                    })
                }} index={1}/>
            </div>

            <div className="row">
                <HomeCategory name={t('hiphone_luxury')} image={LuxuryImage} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'attrs': 'Hiphone Luxury',
                        })}`
                    })
                }} index={4}/>
                <HomeCategory name={t('jbl')} image={JblImage} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': 'JBL',
                        })}`
                    })
                }} index={5}/>
                {/* Item */}
                <HomeCategory
                    id="29"
                    name={t('beats')} image={BeatsImage} onClick={(id: any, name: any) => {
                        navigate({
                            pathname: `/products`, search: `?${createSearchParams({
                                id: id
                            })}`
                        })
                    }} index={6}/>
                <HomeCategory name={t('cctv')} image={CctvImage} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'id': '29',
                        })}`
                    })
                }} index={7}/>
            </div>


            {/* <div className={`row`}>
                <HomeCategory name={t('maestro')} images={[
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/9988777703-05.jpg?w=800&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/03/9988773046-3.jpg?w=1000&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/9988775120-05-2.jpg?w=800&ssl=1',
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2022/03/9988772795-1-1-scaled.jpg?resize=1536%2C1536&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': 'Maestro',
                        })}`
                    })
                }} />
                <HomeCategory
                    id="37"
                    name={t('bundle_offer')} images={[
                        'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2023/04/Combo2.jpg?w=1000&ssl=1',
                        'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2023/04/Combo1.1-1.jpg?w=1000&ssl=1',
                        'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2023/04/Combo3.jpg?w=1000&ssl=1',
                        'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/07/bund.jpg?w=1000&ssl=1'
                    ]} onClick={(id: any, name: any) => {
                        navigate({
                            pathname: `/products`, search: `?${createSearchParams({
                                id: id
                            })}`
                        })
                    }} />
                <HomeCategory name={t('aed_10_30_items')} images={[
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/06/9988772639-2-scaled.jpg?resize=1536%2C1536&ssl=1',
                    'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/9988779013-05.jpg?w=800&ssl=1',
                    'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2022/07/6920680842087-3.jpg?w=1000&ssl=1',
                    'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2022/07/6920680862801-2.jpg?w=1000&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'min_price': '10',
                            'max_price': '30',
                        })}`
                    })
                }} />
                <HomeCategory name={t('iphone_14_and_accessories')} images={[
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2022/09/194253416586.jpg?resize=1536%2C1536&ssl=1',
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2022/09/iphone-14-Series-1.jpg?w=720&ssl=1',
                    'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2022/09/Deep-Purple-1-2.jpg?w=1000&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/09/194253416975.jpg?resize=1536%2C1536&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': 'iPhone 14',
                        })}`
                    })
                }} />
            </div> */}

            {/* <div className={`row`}>
                <HomeCategory name={t('apple')} images={[
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2021/10/194252781418-1.jpg?w=1000&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/05/FSDFSDF.jpg?w=1000&ssl=1',
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2022/03/194252458556-3.jpg?resize=1536%2C1536&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2022/03/194252779552-1.jpg?w=572&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': name,
                        })}`
                    })
                }} />
                <HomeCategory name={t('samsung')} images={[
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/8806086922432-01-1.jpg?w=800&ssl=1',
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/8806088353395-01.jpg?w=800&ssl=1',
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2022/07/sam2022-07-16-.jpg?w=962&ssl=1',
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2022/07/samsung_45w_charger_.jpg?w=960&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': name,
                        })}`
                    })
                }} />
                <HomeCategory name={t('jbl')} images={[
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/6925281939877-02.jpg?w=800&ssl=1',
                    'https://i1.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/6925281954580-02.jpg?w=800&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/6925281923203-02.jpg?w=800&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': 'JBL',
                        })}`
                    })
                }} />
                <HomeCategory name={t('beats')} images={[
                    'https://i0.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/190198701527-01.jpg?w=800&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/190199246553-01.jpg?w=800&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/888462602594-02.jpg?w=800&ssl=1',
                    'https://i2.wp.com/hiphonetelecom.com/wp-content/uploads/2020/08/190198447043-01.jpg?w=800&ssl=1'
                ]} onClick={(id: any, name: any) => {
                    navigate({
                        pathname: `/products`, search: `?${createSearchParams({
                            'name': name,
                        })}`
                    })
                }} />
            </div> */}
        </div>
    );
}