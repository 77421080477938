import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { OrderActions } from './actions';
import { OrdersApis } from '../../../../server/api/Orders';
import { AppActions } from '../../../../modules/app/actions';

function* order({ payload }: ActionType<typeof OrderActions.order.request>): SagaIterator {
  try {
    console.log('order sSaga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OrdersApis.order, {id: payload}) as any;
    console.log('order response:');
    console.log(data);
    yield* put(OrderActions.order.success({ order: data.data.order, products: data.data.items, billingAddress: data.data.billing_address, shippingAddress: data.data.shipping_address})) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(OrderActions.order.fail(errorMessage)) as any;
  }
}

export function* watchOrder(): SagaIterator {
  yield* takeLatest(OrderActions.order.request, order) as any;
}