import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ProductsNavigation = () => {
    // Search params
    let [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <nav className="container d-flex w-100 mb-4">
            <a onClick={() => {
                navigate({
                    pathname: `/`
                })
            }} className="pointer" style={{color: '#59626A'}}>{t('home')}</a>
            <span className="delimiter ms-4 me-4">
                <i className="fa fa-angle-right" />
            </span>

            <a onClick={() => {
                navigate({
                    pathname: `/products`, search: ``
                })
            }} className="pointer" style={{color: '#59626A'}}>{t('shop')}</a>
            {
                searchParams.get('title') && <span className="delimiter ms-4 me-4">
                    <i className="fa fa-angle-right" />
                </span>
            }
            {searchParams.get('title') && <a>{searchParams.get('title')}</a>}

        </nav>

    );
}