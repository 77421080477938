import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { HeaderActions } from '../../../modules/header/actions';
import { RootState } from '../../../store/rootState';
import { useAction } from '../../../utils/hooks/action';
import { useLanguage } from '../../../utils/hooks/language';
import { SidenavItem } from './sidenav-item';
import { useTranslation } from 'react-i18next';
import { SidenavSelectionItem } from './sidenav-selection-item';
import { SidenavSubItem } from './sidenav-sub-item';

export var Sidenav = () => {
    const navigate = useNavigate();
    const { sidenav } = useSelector((state: RootState) => state.header);
    const openSidenavAction = useAction(HeaderActions.sidenav.open);
    const closeSidenavAction = useAction(HeaderActions.sidenav.close);
    const [showBrands, setShowBrands] = useState(false);
    const [en, ar] = useLanguage();
    const { t, i18n } = useTranslation();
    

    var SidenavBrands = () => {
        return <div>
            <SidenavSubItem title={t('maestro')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'Maestro',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('isafe')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'iSAFE',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('Apple')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'apple',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('Samsung')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'samsung',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('Harrouds')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'harrouds',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('Joyroom')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'joyroom',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('SanDisk')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'sandisk',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('JBL')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'jbl',
                    })}`
                })
            }}/>

            <SidenavSubItem title={t('Others')} onClick={() => {
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'attrs': 'others',
                    })}`
                })
            }}/>
        </div>
    }

    return (
        <div className={`sidenav-${en ? 'ltr' : 'rtl'}-${sidenav ? 'opened' : 'closed'}`}>

            <li className='d-flex sidenav-item ps-4'>
                <div className='pointer' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 12 }}><img height={12} width={18} src="https://hiphonetelecom.com/wp-content/uploads/flags/flag_final.png" alt="ar" title="العربية" /></div>
                    &nbsp;&nbsp;<span >العربية</span>
                    &nbsp;&nbsp;<span >(Arabic)</span>
                </div>
            </li>

            <SidenavItem title={t('about_us')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/about`
                })
            }}/>

            <SidenavItem title={t('aed_10_30_items')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'min_price': '0',
                        'max_price': '10'
                    })}`
                })
            }}/>

            <SidenavItem title={t('sale')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'min_price': '0'
                    })}`
                })
            }}/>

            <SidenavItem title={t('branches')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/branches`
                })
            }}/>

            <SidenavItem title={t('repair')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/repair`
                })
            }}/>

            <SidenavSelectionItem title={t('top_brands')}>
                <SidenavBrands />
            </SidenavSelectionItem>

            <SidenavItem title={t('audio_and_bluetooth')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '25',
                    })}`
                })
            }}/>

            <SidenavItem title={t('battery')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '35',
                    })}`
                })
            }}/>

            <SidenavItem title={t('bundle_offer')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '37'
                    })}`
                })
            }}/>

            <SidenavItem title={t('cases_and_covers')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '28'
                    })}`
                })
            }}/>

            <SidenavItem title={t('crazy_deals')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '42'
                    })}`
                })
            }}/>

            <SidenavItem title={t('powerbank')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '46'
                    })}`
                })
            }}/>

            <SidenavItem title={t('screen_and_camera_protection')} onClick={() => {
                closeSidenavAction()
                navigate({
                    pathname: `/products`, search: `?${createSearchParams({
                        'id': '31'
                    })}`
                })
            }}/>
        </div>
    )
}