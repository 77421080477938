import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  ProductActions,
} from './actions';
import { ProductTypes } from './types';
import { AttributeModel } from '../../../server/models/attribute';

const initialState: ProductTypes = {
  loading: false,
  product: undefined
};

export const productReducer = createReducer(initialState, handleAction => [
  handleAction(ProductActions.product.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(ProductActions.product.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.product = payload.product;
    }),
  ),
  handleAction(ProductActions.product.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),
]);
