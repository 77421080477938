import { createAction } from 'deox';
import { OrderModel } from '../../server/models/order';
import { AddressesModel } from '../../server/models/addresses';
import { AddressModel } from '../../server/models/address';


export class MyAccountActions {
  static orders = {
    request: createAction('my_account/orders_request'),
    success: createAction('my_account/orders_success', resolve => (payload: OrderModel[]) =>
      resolve(payload),
    ),
    fail: createAction('my_account/orders_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
  static addresses = {
    request: createAction('my_account/addresses_request'),
    success: createAction('my_account/addresses_success', resolve => (payload: AddressesModel) =>
      resolve(payload),
    ),
    fail: createAction('my_account/addresses_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static putBillingAddress = {
    request: createAction('my_account/put_billing_address_request', resolve => (payload: AddressModel) =>
      resolve(payload),
    ),
    success: createAction('my_account/put_billing_success'),
    fail: createAction('my_account/put_billing_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static putShippingAddress = {
    request: createAction('my_account/put_shipping_address_request', resolve => (payload: AddressModel) =>
      resolve(payload),
    ),
    success: createAction('my_account/put_shipping_address_success'),
    fail: createAction('my_account/put_shipping_address_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static moveToAddresses = {
    true: createAction('my_account/move_to_address_true'),
    false: createAction('my_account/move_to_address_false'),
  }

  static paymentLink = {
    request: createAction('my_account/payment_link_request'),
    success: createAction('my_account/payment_link_success', resolve => (payload: AddressesModel) =>
      resolve(payload),
    ),
    fail: createAction('my_account/payment_link_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
