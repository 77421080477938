import React, { CSSProperties, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from '../../components/header/headers';
import { Footer } from '../../components/footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootState';
import { useAction } from '../../utils/hooks/action';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { TitleWithDivider } from '../../components/title_with_divider';
import { InputField } from '../../components/input_field';
import { Button } from '../../components/button';
import CheckmarkSvgImage from '../../assets/checkmark.svg';
import { Alert } from '../../components/alert';
import { AuthActions } from './module/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { BasePage } from '../base/base';
import { useTranslation } from 'react-i18next';


export const AuthPage = () => {
  // Navigation
  const location = useLocation();
  const navigate = useNavigate();
  // Actions
  const loginAction = useAction(AuthActions.login.request);
  const registerAction = useAction(AuthActions.register.request);
  // States to listen on
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const { login } = useSelector((state: RootState) => state.app);

  // User credentials
  const [loginUsername, setLoginUsername] = useState<string>('');
  const [loginPassword, setLoginPassword] = useState<string>('');
  const [registerEmail, setRegisterEmail] = useState<string>('');
  const [registerPassword, setRegisterPassword] = useState<string>('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (login) {
      navigate("/", { replace: true });
    }
  }, [login])

  const NavigationPath = () => {
    return (
      <div className='container-lg' style={{ display: 'flex', fontSize: 14 }}>
        <div style={{ color: '#5A6168', fontWeight: 500 }}>Home</div>
        <div style={{ color: '#000', fontWeight: 'bold', paddingLeft: 15, paddingRight: 15 }}><MdOutlineKeyboardArrowRight size={18} /></div>
        <div style={{ color: '#5A6168', fontWeight: 500 }}>My account</div>
      </div>
    )
  }


  return (
    <BasePage>
      <Header />
      <NavigationPath />
      <div className='mb-5' />

      {(error) && <div className='container-lg mb-4'><Alert message={error} /></div>}

      <div className='container'>
      <div className='row mb-5'>
        <div className='col-lg-5 col-12 mb-5' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          {/* Title */}
          <TitleWithDivider title={`${t('login')}`} />
          {/* Fields */}
          <form className='mt-4 d-flex justify-content-start flex-column align-items-start w-100'>
            <div className='font14 mb-3' style={{ textAlign: 'start' }}>{t('welcome_back')}</div>
            <InputField
              disabled={loading}
              title={`${t('username_or_email_address')}`}
              required={true}
              onChange={(value: string) => {
                setLoginUsername(value)
              }} />
            <InputField
              isPassword
              disabled={loading}
              title={`${t('password')}`}
              required={true}
              onChange={(value: string) => {
                setLoginPassword(value)
              }} />
          </form>

          <Button
            disabled={loading}
            title={`${t('login')}`}
            onClick={() => {
              console.log('login clicked ayy!')
              loginAction({ username: loginUsername, password: loginPassword })
            }} />
        </div>

        {/* Middle Divider */}
        <div className='col-lg-2 col-12' style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='d-none d-lg-block'>
          <div style={{ position: 'relative' }}>
            <div style={{ width: 1, height: 600, backgroundColor: '#DDDDDD' }} />
            <div className='font12' style={{ padding: 15, left: -25, position: 'absolute', top: '50%', borderRadius: '50%', border: '1px solid #DDDDDD', backgroundColor: '#FFF' }}>{t('or')}</div>
          </div>
          </div>
        </div>

        {/* Register */}
        <div className='col-lg-5 col-12' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          {/* Title */}
          <TitleWithDivider title={`${t('register')}`} />
          {/* Fields */}
          <form className='mt-4 d-flex justify-content-start flex-column align-items-start w-100'>
            <div className='font14 mb-3' style={{ textAlign: 'start' }}>{t('create_account_today')}</div>
            <InputField
              disabled={loading}
              title={`${t('email_address')}`}
              required={true}
              onChange={(value: string) => {
                setRegisterEmail(value);
              }} />
            <InputField
              disabled={loading}
              title={`${t('create_account_today')}`}
              required={true}
              onChange={(value: string) => {
                setRegisterPassword(value);
              }} />
          </form>

          <Button
            disabled={loading}
            title={`${t('register')}`}
            onClick={() => {
              registerAction({ email: registerEmail, password: registerPassword })
            }} />

          <div className='mt-5 font18 mb-4'>{t('sign_up_today_and_you_will_be_able_to')}</div>
          <div className='d-flex mb-2'>
            <img className='me-3' height={20} width={20} src={CheckmarkSvgImage} />
            <div className='font14'>{`${t('speed_your_way_through_checkout')}`}</div>
          </div>
          <div className='d-flex mb-2'>
            <img className='me-3' height={20} width={20} src={CheckmarkSvgImage} />
            <div className='font14'>{`${t('track_your_orders_easily')}`}</div>
          </div>
          <div className='d-flex mb-2'>
            <img className='me-3' height={20} width={20} src={CheckmarkSvgImage} />
            <div className='font14'>{`${t('keep_a_record_of_all_your_purchases')}`}</div>
          </div>
        </div>
      </div>
      </div>

      <Footer />
    </BasePage>)
}