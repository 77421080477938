import { CategoryModel } from "../../../server/models/category";
import { useNavigate, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import { useLanguage } from "../../../utils/hooks/language";

export const ProductsSideMenuCategory = (props: { category: CategoryModel }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [en, ar] = useLanguage();
    const location = useLocation();

    const navigateToProducts = (id: string | undefined, title: string | undefined) => {
        if(location.pathname.includes('/product/')) {
            navigate({
                pathname: `/products`, search: `?${createSearchParams({
                    'id': `${id?.toString()}`,
                    'title': `${title?.toString()}`,
                })}`
            });
        }
        else {
            searchParams.set("page", '0');

            if (title) {
                searchParams.set("title", title);
            }
            else {
                var _title = searchParams.get('title')
                if (_title) {
                    searchParams.set("title", _title);
                }
            }

            if (id) {
                searchParams.delete('name')
                searchParams.delete('attrs')
                searchParams.set("id", id);
            }
            else {
                var _id = searchParams.get('id')
                if (_id) {
                    searchParams.set("id", _id);
                }
            }

            setSearchParams(searchParams, { replace: true });
        }
    }

    const SubCategory = (props: { category: CategoryModel }) => {
        return (
            <a className="pointer" onClick={() => {
                navigateToProducts(props.category.id, props.category.name_en);
            }}>
                <span className="no-child" />
                {en ? props.category.name_en : props.category.name}
            </a>
        )
    }

    return (
        <div style={{backgroundColor: searchParams.get('id') == props.category.id ? '#F8B2B6' : 'transparent'}} onClick={() => {
            navigateToProducts(props.category.id, props.category.name_en);
        }} className="pointer">
            <div className="products-side-menu-category-item">
            <div className={`fa fa-angle-${en ? 'right' : 'left'} ms-2 me-2`} style={{visibility: ((props.category.sub_categories ?? []).length > 0) ? 'visible' : 'hidden'}}/>
                <div className="bold underline">{en ? props.category.name_en : props.category.name}</div>
            </div>
            <div className="children" style={{ display: "none" }}>
                <div className="cat-item cat-item-3914">
                    {[...(props.category.sub_categories ?? []).map((subcategory, index) =>
                        <SubCategory category={subcategory} />
                    )]
                    }
                </div>
            </div>
        </div>
    )
}