import { Api } from './Api';
import { ADD_TO_CART_URL, CALCULATE_URL, CART_URL, REMOVE_FROM_CART_URL, UPDATE_CART_URL } from '../../configs';
import { AddToCartPayload } from '../payload/add_to_cart';
import { CalculatePayload } from '../payload/calculate';


export class CartApis {
  static cart(): ReturnType<typeof Api.get> {
    return Api.get(CART_URL);
  }

  static add(paylod : AddToCartPayload): ReturnType<typeof Api.post> {
    return Api.post(ADD_TO_CART_URL, paylod);
  }

  static remove(payload: { item_id: string}): ReturnType<typeof Api.post> {
    return Api.post(REMOVE_FROM_CART_URL, payload);
  }

  static update(payload: { cart: { item_id: string, quantity: number }[]}): ReturnType<typeof Api.post> {
    return Api.post(UPDATE_CART_URL, payload);
  }

  static calculate(payload: CalculatePayload): ReturnType<typeof Api.post> {
    return Api.post(CALCULATE_URL, payload);
  }
}