import { Topbar } from '../../components/topbar'
import { SearchMenu } from '../../components/header/search_menu'
import { Footer } from '../../components/footer'

import { AboutComponent } from '../../components/about'
import { useTranslation } from 'react-i18next'
import { BasePage } from '../base/base'
import { Header } from '../../components/header/headers'

export const TermsAndConditionsPage = () => {
  const { t } = useTranslation();

  return <>
    <BasePage>
      <div>
        <Header />
        <div className='mt-3' />
        <div id="content" className="site-content" tabIndex={-1}>
          <div className="container">
            <nav className="woocommerce-breadcrumb">
              <a href="https://hiphonetelecom.com">{t("terms and conditions")}</a>
              <span className="delimiter">
                <i className="fa fa-angle-right" />
              </span>
              {t("terms and conditions")}
            </nav>
            <div className="site-content-inner">
              <div id="primary" className="w-100">
                <main id="main" className="site-main">
                  <article
                    id="post-65713"
                    className="post-65713 page type-page status-publish hentry"
                  >
                    <header className="entry-header">
                      <h1 className="entry-title">{t("terms and conditions")}</h1>
                    </header>
                    {/* .entry-header */}
                    <p className="container">
                      1. To benefit from warranty services, an original invoice is required to verify the serial number and validity of the warranty, or a true copy of it.
                      <br />
                      2. Warranty covers poor manufacturing, not misuse.
                      <br />
                      3. The terms and conditions for extending the warranty are covered by the same conditions of the manufacturer’s warranty, whether for a year or two, depending on the coverage that has been purchased.
                      <br />
                      4. Products that do not contain a tariff number or a symbol such as UPC number, SN # or IMEI are not covered by the long-term one-two-year warranty.
                      <br />
                      5. The guarantee applies within the borders of the United Arab Emirates only, unless otherwise stated.
                      <br />
                      6. Accessories, batteries, consumables and changes in the colour of the phone cover are not covered by the warranty.
                      <br />
                      7. The main responsibilities of the warranty service remain with the brand owner / main supplier, and the site is the middleman only.
                      <br />
                      8. Product warranty is applied according to the manufacturer’s conditions, starting from the date of purchase recorded on the sales invoice.
                      <br />
                      9. The warranty repair period is fourteen (14) days to twenty-one (21) working days for the products covered by the warranty, from the date the product is received from the customer until it is dispatched.
                      <br />
                      10. The product must be returned in the original packaging, with accessories and instruction booklet, and in a condition that can be sold again. Opened, sealed, damaged or partially used products will not be returned.
                      <br />
                      11. The company’s warranty policy is to protect your product from any defects in materials, design or workmanship that may occur after purchase, with the exception of Apple products (accessories warranty: one year). Other accessories are subject to a six (6) month warranty starting from the date the product was purchased.
                      <br />
                      12. Not all products are covered by the warranty. Always check the product to see if they are covered by the warranty.
                      <br />
                      13. The customer is given a purchase voucher in the amount of the value of the product or the maintenance service, and it is to be used on the website within three months from the date of the voucher or to return the amount value of the product according to the customer’s desire.
                      <br />
                      14. If the product cannot be repaired but is still under the manufacturer’s warranty, we will provide you with a replacement product (from the same seller), and if a replacement is not available, your money will be fully refunded.
                      <br />
                      15. In the event that the product is returned or repaired, the site is not responsible for transferring data to another device or losing information that was on the device at the time of repairing or retrieval.
                      <br />
                      16. Mobile phones have no return and exchange option
                    </p>
                    {/* .entry-content */}
                  </article>
                </main>
                {/* #main */}
              </div>
              {/* #primary */}
            </div>
          </div>
          {/* .col-full */}
        </div>

        <Footer />
      </div>
    </BasePage>
  </>

}