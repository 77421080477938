import { createAction } from 'deox';
import { AddressModel } from '../../../server/models/address';
import { AddressesModel } from '../../../server/models/addresses';
import { CalculateModel } from '../../../server/models/calculate';
import { ProductModel } from '../../../server/models/product';
import { CalculatePayload } from '../../../server/payload/calculate';
import { RepairModel } from '../../../server/models/repair';


export class RepairActions {
  static repair = {
    set: createAction('repair/repair_set', resolve => (payload: RepairModel) =>
      resolve(payload),
    ),
    request: createAction('repair/repair_request'),
    success: createAction('repair/repair_success'),
    fail: createAction('repair/repair_failure', resolve => (payload?: string) =>
      resolve(payload),
    ),
  };

  static loading = {
    set: createAction('repair/loading', resolve => (payload: boolean) =>
      resolve(payload),
    )
  };
}
