import { useSelector } from "react-redux";
import { HeaderActions } from "../../modules/header/actions";
import { useAction } from "../../utils/hooks/action";
import { CartOnTop } from "../search-menu/cart_on_top"
import { RootState } from "../../store/rootState";
import { ProductModel } from "../../server/models/product";
import { useEffect, useState } from "react";
import { SearchMenuLogo } from "./search_menu_logo";
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useLanguage } from "../../utils/hooks/language";
import { useTranslation } from "react-i18next";

export const SearchMenuNew = (props: { stick: boolean }) => {
  // States
  const { products, search, searchType } = useSelector((state: RootState) => state.header);
  // Actions
  const setSearchAction = useAction(HeaderActions.search);
  const clearProducts = useAction(HeaderActions.products.clear);
  const setSearchTypeAction = useAction(HeaderActions.setSearchType);
  const [sticky, setSticky] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [en, ar] = useLanguage();
  const { t, i18n } = useTranslation();

  // const controlNavbar = () => {
  //   if (typeof window !== 'undefined') {
  //     if (window.scrollY > 245) { // if scroll down hide the navbar
  //       setSticky(true);
  //     } else { // if scroll up show the navbar
  //       setSticky(false);
  //     }
  //   }
  // };

  useEffect(() => {
    setSearchAction('')
    clearProducts()
  }, [location])

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     return () => {
  //       window.removeEventListener('scroll', controlNavbar);
  //     };
  //   }
  // }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setSearchAction(event.target.value)
    } else {
      setSearchAction('')
    }
  };

  const ProductSearchItem = (props: { product: ProductModel }) => {
    return (
      <a
        onClick={() => {
          navigate({
            pathname: `/product/${props.product.id}`
          })
        }}
        id="productOnTop"
        className="media live-search-media tt-suggestion tt-selectable product-search-item"
        style={{ backgroundColor: 'white', zIndex: 101 }}
      >
        <img
          src={props.product.image}
          className="media-left media-object flip pull-left"
          height={60}
          width={60}
        />
        <div className="media-body">
          <p>
            {props.product.name_en}
          </p>
        </div>
      </a>
    )
  }

  if (!props.stick && sticky) {
    return <div style={{ height: 75 }}></div>
  }

  if (props.stick && !sticky) {
    return <></>
  }

  return (
    <div id="productsOnTop" className={`disable-selection sticky-wrapper d-none d-lg-block ${(sticky && props.stick) ? ' sticky' : ''}`} style={{ backgroundColor: '#D9D9D9', zIndex: 1010, }}>
      <header id="d-flex" className="site-header header-v3 stick-this" style={{ height: props.stick ? 60 : 75, margin: 0, padding: 0 }}>
        <div className="container">
          <div className="d-flex align-items-center" style={{marginBottom: props.stick ? 0 : 'auto'}}>
            <SearchMenuLogo />
            <form
              className="navbar-search"
              autoComplete="off"
              style={{direction: 'ltr'}}
            >
              <label className="sr-only screen-reader-text" htmlFor="search">
                Search for:
              </label>
              <div className="input-group" >
                <div className="input-search-field" style={{zIndex: 1}}>
                  <span
                    className="twitter-typeahead"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <input
                      type="text"
                      id="search"
                      className="form-control search-field product-search-field tt-input"
                      dir={en ? 'ltr' : 'rtl'}
                      defaultValue=""
                      placeholder={`${t("search_for_products")}`}
                      autoComplete="off"
                      spellCheck="false"
                      aria-activedescendant=""
                      aria-owns="search_listbox"
                      role="combobox"
                      aria-readonly="true"
                      aria-autocomplete="list"
                      value={search}
                      style={{ position: "relative", verticalAlign: "top", height: 41 }}
                      onChange={handleSearch}
                    />
                    <span
                      role="status"
                      aria-live="polite"
                      style={{
                        position: "absolute",
                        padding: 0,
                        border: 0,
                        height: 1,
                        width: 1,
                        marginBottom: "-1px",
                        marginRight: "-1px",
                        overflow: "hidden",
                        clip: "rect(0px, 0px, 0px, 0px)",
                        whiteSpace: "nowrap",
                      }}
                    />
                    <pre
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        visibility: "hidden",
                        whiteSpace: "pre",
                        fontFamily:
                          '"Open Sans", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
                        fontSize: "14.994px",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: 400,
                        wordSpacing: 0,
                        letterSpacing: 0,
                        textIndent: 0,
                        textTransform: "none"
                      }}
                    />
                    <div
                      role="listbox"
                      className="tt-menu"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        display: ((products ?? []).length > 0) ? "block" : 'none'
                      }}
                    >
                      <div role="presentation" className="tt-dataset tt-dataset-search" style={{ backgroundColor: 'white' }}>
                        {[...(products ?? []).map((product, index) =>
                          <ProductSearchItem product={product} />
                        )]
                        }
                      </div>
                    </div>
                  </span>
                </div>
                <div className="input-group-addon search-categories" style={{ height: 41 }}>
                  <select
                    name="product_cat"
                    id="electro_header_search_categories_dropdown"
                    className="postform resizeselect"
                    style={{ width: 100 }}
                    onChange={(event) => {
                      setSearchTypeAction((event as any).target.value)
                    }}
                  >
                    <option value={'name'} selected={searchType == 'name'}>
                      {t('name')}
                    </option>

                    <option value={'barcode'} selected={searchType == 'barcode'}>
                    {t('barcode')}
                    </option>
                  </select>
                </div>
                <div className="input-group-btn" style={{ height: 41 }}>
                  <input
                    type="hidden"
                    id="search-param"
                    name="post_type"
                    defaultValue="product"
                  />
                  <button onClick={() => {
                    navigate({
                      pathname: `/products`, search: `?${createSearchParams({
                          'title': `${search}`,
                          'name': `${search}`,
                      })}`
                  });
                  }} style={{ height: 41, zIndex: 0 }} className="btn d-flex justify-content-center align-items-center">
                    <i className="ec ec-search" />
                  </button>
                </div>
              </div>
            </form>
            <div className="header-icons" style={{height: props.stick ? 60 : 75}}>
              <div
                className="header-icon"
                data-toggle="tooltip"
                data-placement="bottom"
                data-title="Compare"
                data-original-title=""
                title=""
              >
                <a>
                  <i className="ec ec-compare" />
                </a>
              </div>

              <div
                className="header-icon"
                data-toggle="tooltip"
                data-placement="bottom"
                data-title="Wishlist"
                data-original-title=""
                title=""
                onClick={() => {
                  navigate('/wishlist');
                }}
              >
                <a>
                  <i className="ec ec-favorites" />
                </a>
              </div>

              <CartOnTop />
            </div>
            {/* /.header-icons */}
          </div>
        </div>
      </header>
    </div>
  )
}